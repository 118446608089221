import {TaskData} from "@/views/happs/core/lib/CoreData";
import Vue from 'vue';

export default {
    watch: {
        /**
         * @param {TaskData[]} val
         */
        tasks(val) {
            let latest = {};

            for (let task of val) {
                let contractId = task.core.contractId;
                let entry = latest[contractId];
                if (!entry) {
                    entry = {
                        id: task.id,
                        core: task.core
                    }
                    latest[contractId] = entry;
                } else if (entry.id < task.id) {
                    entry.id = task.id;
                }
            }

            for (let {id, core} of Object.values(latest)) {
                if (!core.latestTask || id > core.latestTask) {
                    Vue.set(core.state, 'latestTask', id);
                }
            }
        }
    }
}
