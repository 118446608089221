<template>
    <div class="core-task-detail">
        <TaskInfo :core="core" :network="core.network" :task="task" :show-contract-name="showContractName"/>

        <div v-if="task.content.description" class="task-section">
            <i class="material-icons section-icon">notes</i>
            <h6>Description</h6>
            <template v-if="isEncrypted && !decryptedDescription">
                <div class="task-title-encrypted">Encrypted</div>
            </template>
            <Markdown v-else :content="decryptedDescription || task.content.description"/>

            <div v-for="(addition, index) in additions" class="task-addition">
                <div class="addition-number">
                    Add. {{ index + 1 }}
                </div>
                <template v-if="addition.encrypted">
                    <div class="addition-encrypted">Encrypted</div>
                </template>
                <Markdown v-else :content="addition.text"/>
            </div>

            <template v-if="pendingAdditions">
                <div v-for="addition in pendingAdditions" class="task-addition pending-addition">
                    <div class="addition-number">
                        <ProgressCircle small/>
                    </div>
                    <Markdown :content="addition.text"/>
                </div>
            </template>

            <div v-if="additionsAllowed" class="add-addition">
                <MarkdownEditor min-height="30" :content.sync="additionText"
                                :toolbar="['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                            'link', 'image', 'preview', 'guide']"
                />
                <small class="formulate-input-help">You can add more details to the task.</small>
                <template v-if="showAdditionsSubmit">
                    <FormulateInput
                        type="number"
                        label="Increase Reward"
                        help="You can also increase the reward for this task."
                        v-model="additionReward"
                    />
                    <DumbappPopover
                        label="Submit Addition"
                        :instance="core.codes.addition"
                        :values="additionQuery"
                        @submitted="additionSubmitted"
                    />
                </template>
            </div>
        </div>

        <div v-if="task.perPeriod > 0" class="task-repeating">
            <div class="task-section">
                <i class="material-icons section-icon">people</i>
                <h6>Current Period</h6>
                <p v-if="task.perPeriod > task.completionCount">
                    <button
                        @click="$refs.comments.addScrum()"
                        class="btn btn-sm btn-primary"
                    >Add Scrum
                    </button>
                </p>
                <p v-else>
                    No more scrums can be completed during this period.
                </p>
                <template v-if="repeatingEfforts.length > 0">
                    <Effort v-for="effort in repeatingEfforts"
                            @complete="$refs.comments.complete(effort)"
                            @approve="$refs.comments.approve(effort)"
                            @contest="$refs.comments.contest(effort)"
                            @comment="$refs.comments.jumpToComment(effort)"
                            :key="effort.id"
                            :core="core"
                            :task="task"
                            :effort="effort"
                            :network="core.network"/>
                </template>
                <div v-else>
                    No efforts added yet.
                </div>
            </div>
            <div class="task-section">
                <i class="material-icons section-icon">history_toggle_off</i>
                <h6>Previous Period</h6>
                <template v-if="previousRepeatingEfforts.length > 0">
                    <Effort v-for="effort in repeatingEfforts"
                            @complete="$refs.comments.complete(effort)"
                            @approve="$refs.comments.approve(effort)"
                            @contest="$refs.comments.contest(effort)"
                            @comment="$refs.comments.jumpToComment(effort)"
                            :key="effort.id"
                            :core="core"
                            :task="task"
                            :effort="effort"
                            :network="core.network"/>
                </template>
                <div v-else>
                    No efforts in the previous period.
                </div>
            </div>
        </div>
        <div v-else class="task-section">
            <i class="material-icons section-icon">{{ effortsIcon }}</i>
            <h6>Efforts</h6>

            <template v-if="pendingEfforts">
                <div v-for="effort in pendingEfforts" class="pending-effort">
                    <div class="pending-effort-heading">
                        Pending effort created by you
                        <ProgressCircle small/>
                    </div>
                </div>
            </template>
            <div v-if="task.efforts.length === 0 && !pendingEfforts">
                No efforts to complete this task have been made. To start work on this task, create a new effort.
            </div>
            <div v-for="effort in task.efforts" class="effort-wrap">
                <Effort
                    :key="effort.id"
                    :task="task"
                    :effort="effort"
                    :core="core"
                    :network="core.network"
                    @complete="$refs.comments.complete(effort)"
                    @approve="$refs.comments.approve(effort)"
                    @contest="$refs.comments.contest(effort)"
                    @comment="$refs.comments.jumpToComment(effort)"
                />
            </div>
            <DumbappPopover
                v-if="showCreateEffort"
                :instance="core.codes.createEffort"
                :values="effortQuery"
                :prefilled="effortPrefilled"
                @submitted="effortSubmitted"
                class="create-effort-button"
                label="Create Effort"/>
        </div>

        <TaskComments :core="core"
                      :network="core.network"
                      :task="task"
                      :task-detail="taskDetail"
                      :last-read="lastRead"
                      :show-comment="showComment"
                      :show-approve="showApprove"
                      :encryption-key="encryptionKey"
                      ref="comments"/>
    </div>
</template>

<script>
import Address from "@/components/Address";
import DumbappPopover from "@/components/DumbappPopover";
import Info from "@/components/Info";
import Markdown from "@/components/Markdown";
import ProgressCircle from "@/components/ProgressCircle";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import Effort from "@/views/happs/core/Effort";
import {CoreData, TaskData, TaskDetailData} from "@/views/happs/core/lib/CoreData";
import {loadTaskDetail} from "@/views/happs/core/lib/corelib";
import CryptMixin from "@/views/happs/core/lib/CryptMixin";
import TaskComments from "@/views/happs/core/TaskComments";
import TaskInfo from "@/views/happs/core/TaskInfo";
import TaskRepeating from "@/views/happs/core/TaskRepeating";
import WatchingMixin from "@/views/happs/core/WatchingMixin";
import { resolver } from "@blockwell/eth-types";
import BigNumber from "bignumber.js";
import {mapActions, mapGetters, mapState} from "vuex";
import * as R from 'rambdax';

export default {
    name: "TaskDetail",
    components: {
        Info,
        MarkdownEditor,
        TaskComments,
        TaskRepeating,
        TaskInfo,
        Address, Effort, DumbappPopover, ProgressCircle, Markdown
    },
    mixins: [WatchingMixin, CryptMixin],
    props: {
        task: TaskData,
        showComment: Number,
        showApprove: Boolean,
        showContractName: Boolean
    },
    data() {
        return {
            lastRead: null,
            additionText: "",
            additionReward: this.task.reward.div(`1e${this.task.core.core.decimals}`).toString(10),
            taskDetail: new TaskDetailData()
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('dumbapp', ['tracking']),
        ...mapGetters('user', ['api']),
        core() {
            return this.task.core;
        },
        effortsIcon() {
            if (this.efforts?.length > 1) {
                return 'groups'
            }
            return 'person';
        },
        effortQuery() {
            return {
                taskId: this.task?.id?.toString()
            }
        },
        effortPrefilled() {
            if (this.account) {
                return {assignee: this.account};
            }
            return {};
        },
        isEncrypted() {
            return this.task?.content?.encryption;
        },
        effortTrackingKey() {
            return ['core-effort', this.core.network, this.core.address, this.task.id].join('-');
        },
        additionTrackingKey() {
            return ['core-addition', this.core.network, this.core.address, this.task.id].join('-');
        },
        pendingEfforts() {
            let tracks = this.tracking[this.effortTrackingKey];
            /**
             *
             * @type {EffortData[]}
             */
            let efforts = this.task.efforts || [];

            if (tracks && tracks.length > 0) {
                let filtered = tracks.filter(it => {
                    if (it.status === "pending") {
                        return true;
                    }

                    let event = it.submission.steps[0].events?.find(it => it.event === "EffortCreated");
                    if (event) {
                        let effortId = parseInt(resolver(event.params).string("effortId"));
                        return !efforts.find(it => it.id === effortId);
                    }
                });
                if (filtered.length > 0) {
                    return filtered;
                }
            }
            return null;
        },
        pendingAdditions() {
            let tracks = this.tracking[this.additionTrackingKey];
            let additions = this.task.additions;

            if (!additions) {
                return null;
            }

            if (tracks && tracks.length > 0) {
                let filtered = tracks.filter(it => {
                    if (it.status === "pending") {
                        return true;
                    }

                    let event = it.submission.steps[0].events?.find(it => it.event === "Addition");
                    if (event) {
                        let additionId = parseInt(resolver(event.params).string("additionId"));
                        return additions.length <= additionId;
                    }
                });
                if (filtered.length > 0) {
                    return filtered;
                }
            }
            return null;
        },
        effortsFailed() {
            if (this.efforts?.length > 0) {
                return R.allTrue(...this.efforts.map(it => it.status === 'failed'))
            }
            return false;
        },
        showCreateEffort() {
            return this.task.status === "open" || this.task.status === "repeating";
        },
        additionsAllowed() {
            return this.task.isCreator && this.task.effortCount === 0;
        },
        showAdditionsSubmit() {
            return this.additionsAllowed && this.additionText;
        },
        repeatingEfforts() {
            let core = this.core;

            if (this.task.efforts && this.task.perPeriod > 0) {
                return this.task.efforts.filter(it => {
                    return it.creationPeriod === core.core.currentPeriod;
                });
            }
            return [];
        },
        previousRepeatingEfforts() {
            let core = this.core;

            if (this.task.efforts && this.task.perPeriod > 0) {
                return this.task.efforts.filter(it => {
                    return it.creationPeriod === core.core.currentPeriod - 1;
                });
            }
            return [];
        }
    },
    asyncComputed: {
        encryptionKey() {
            let encryption = this.task?.content?.encryption;
            if (encryption === "aes-2") {
                return this.api.getEncryptionKey(this.core.contractId, {
                    version: "2",
                    address: this.task.creator,
                    timestamp: this.task.content.timestamp
                });
            } else if (this.core.encryptionKey) {
                return this.core.encryptionKey;
            }

            return null;
        },
        decryptedDescription() {
            if (this.task.content.description && this.isEncrypted && this.encryptionKey) {
                return this.crypt.decrypt(this.task.content.description, {password: this.encryptionKey});
            }
            return null;
        },
        async additionQuery() {
            let reward = '0';
            let newReward = new BigNumber(this.additionReward).times(`1e${this.core.core.decimals}`);
            if (newReward.gt(this.task.reward)) {
                reward = this.additionReward;
            }

            let text = {
                text: this.additionText
            }

            if (this.encryptionKey) {
                text = {
                    encryption: "aes-2",
                    text: await Promise.resolve(this.crypt.encrypt(text.text, {password: this.encryptionKey}))
                }
            }

            return {
                taskId: this.task.id.toString(),
                text: JSON.stringify(text),
                reward
            }
        },
        async additions() {
            if (!this.task?.additions?.length) {
                return [];
            }

            return R.mapFastAsync(async addition => {
                if (addition.text && addition.encryption) {
                    if (this.core.encryptionKey) {
                        return {
                            text: await this.crypt.decrypt(addition.text, {password: this.core.encryptionKey})
                        };
                    } else {
                        return {
                            encrypted: true
                        }
                    }
                } else {
                    return addition;
                }
            }, this.task.additions);
        }
    },
    watch: {
        'task.commentCount'(val, old) {
            this.updateTaskDetail();
        },
        'task.additionCount'(val, old) {
            this.updateTaskDetail();
        },
        'task.commentRequestReward'(val, old) {
            this.updateTaskDetail();
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        effortSubmitted(submission) {
            this.trackSubmission({
                key: this.effortTrackingKey,
                data: {
                    id: submission.id,
                    assignee: submission.data.getInput("assignee")
                }
            });

            if (!this.isWatching) {
                this.toggleWatch();
            }
        },
        additionSubmitted(submission) {
            this.trackSubmission({
                key: this.additionTrackingKey,
                data: {
                    id: submission.id,
                    text: this.additionText
                }
            });

            this.additionText = '';
        },
        updateTaskDetail() {
            let task = this.task;
            let detail = this.taskDetail;

            if (task.commentCount !== detail.comments?.length
                || task.additionCount !== detail.additions?.length
                || (!detail.commentRequest && task.commentRequestReward !== "0")
            ) {
                loadTaskDetail(this.core, this.task)
                    .then(update => {
                        this.taskDetail = update;

                        if (update.comments.length !== this.task.commentCount) {
                            console.log('comment length mismatch');
                            // This can happen if the event log hasn't updated yet, so try again in a few seconds
                            setTimeout(this.updateTaskDetail, 3000);
                        }
                    })
                    .catch(console.error);
            }
        }
    },
    created() {
        this.updateTaskDetail();
        let state = this.core.state;
        if (this.isWatching) {
            this.lastRead = state.comments?.[this.task.id]?.read;
        }
    },
    beforeDestroy() {
        if (this.isWatching) {
            let state = this.core.state;
            if (!state.comments) {
                this.$set(state, 'comments', {});
            }
            if (!state.comments[this.task.id]) {
                this.$set(state.comments, this.task.id, {});
            }
            this.$set(state.comments[this.task.id], 'read', this.task.commentCount);
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.core-task-detail {
    width: 640px;
    background: #fff;


    .task-section {
        padding-left: 35px;
        position: relative;
        margin-bottom: 38px;
        background: #fff;

        h6 {
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 18px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .section-icon {
            color: rgba($dark, .7);
            font-size: 24px;
            line-height: 24px;
            width: 30px;
            text-align: center;
            display: block;
            position: absolute;
            left: -2px;
        }
    }

    .markdown-body {
        overflow-wrap: break-word;
    }

    @media screen and (max-width: 720px) {
        width: auto;
    }

    .pending-effort {
        margin-bottom: 25px;

        .pending-effort-heading {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .progress-circular {
                margin-left: 10px;
            }

            .wallet-address {
                background-color: #fff;
                border-color: #fff;
            }
        }

        .pending-effort-collaborators {
            display: flex;
            align-items: center;

            .wallet-address {
                margin-left: 5px;
            }
        }
    }

    .effort-wrap {
        margin-top: 20px;

        & > h6 {
            margin-bottom: 5px;
        }
    }

    .create-effort-button {
        margin-top: 10px;
    }

    .task-addition {
        position: relative;
    }

    .addition-number {
        color: $secondary;
        position: absolute;
        font-size: 0.7em;
        left: -40px;
        width: 30px;
        text-align: center;
    }

    .add-addition {
        margin-bottom: 15px;

        .editor-toolbar {

            background: #efefef;
            font-size: 0.8em;
            padding: 0;

            &::before, &::after {
                content: none;
            }
        }
    }

    .task-repeating {
        margin-bottom: 25px;
    }
}

</style>
