<template>
    <div class="core-task planning-task" @click="$emit('click')" :class="{'new-planning-task': isNew}">
        <div class="planning-reward formulate-input" data-classification="text">
            <div v-if="pending" class="saving">
                <div>Saving...</div>
                <ProgressCircle tiny />
            </div>
            <label>Reward</label>
            <input type="number" class="reward-input" :value="task.reward" @blur="blur" @click.stop>
        </div>
        <div class="task-title">{{task.content.title}}</div>
    </div>
</template>

<script>
import ProgressCircle from "@/components/ProgressCircle";
import {PlanningTaskData} from "@/views/happs/core/lib/PlanningData";

export default {
    name: "PlanningTask",
    components: {ProgressCircle},
    props: {
        task: PlanningTaskData,
        pending: Object
    },
    data() {
        return {};
    },
    computed: {
        isNew() {
            return this.task.id > Date.now() - 3000;
        }
    },
    methods: {
        blur(event) {
            this.$emit('reward', this.task.id, event.target.value);
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

@keyframes task-pulse {
    0% {
        border-color: transparent;
    }
    10% {
        border-color: $primary;
        background-color: rgba($primary, .3);
    }
    100% {
        border-color: transparent;
    }
}

.planning-task {
    padding-top: 8px;
}

.new-planning-task {
    animation: task-pulse 3s;
}

.planning-reward {
    flex: 0 0 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    .reward-input {
        padding: 2px 4px;
        line-height: 1.3;
        height: auto;
        flex: 0 1 auto;
        width: 80px;
        border: 1px solid transparent;
    }

    ::v-deep( label ) {
        font-weight: normal;
        font-size: 14px;
        color: $secondary;
        margin: 0 10px 0 0;
    }
}

.saving {
    flex: 1 0 auto;
    display: flex;
    color: $secondary;
}

</style>
