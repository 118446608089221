import Vue from 'vue';

export default {
    computed: {
        isWatching() {
            return this.core.state?.comments?.[this.task.id]?.watch;
        },
    },
    methods: {
        toggleWatch() {
            let state = this.core.state;

            if (this.isWatching) {
                this.$set(state.comments[this.task.id], 'watch', false);
            } else {
                if (!state.comments) {
                    this.$set(state, 'comments', {});
                }
                if (!state.comments[this.task.id]) {
                    this.$set(state.comments, this.task.id, {});
                }
                let lastRead = {};
                if (this.task.efforts && this.task.efforts.length > 0) {
                    for (let effort of this.task.efforts) {
                        let last = state.comments[this.task.id][effort.id];
                        if (last === undefined) {
                            if (state.comments[this.task.id][0] !== undefined) {
                                last = 0;
                            } else {
                                last = effort.commentCount;
                            }
                        }
                        lastRead[effort.id] = last;
                        this.$set(state.comments[this.task.id], effort.id, effort.commentCount);
                    }
                } else {
                    this.$set(state.comments[this.task.id], 0, 0);
                }
                this.$set(state.comments[this.task.id], 'watch', true);
            }
        }
    }
}
