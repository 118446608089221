export class CoreData {

    /**
     * @type {string | null}
     */
    address

    /**
     * @type {string | null}
     */
    contractId

    /**
     * @type {CoreContractData}
     */
    core

    /**
     * @type {TasksExtensionData}
     */
    ext

    /**
     * @type {number | null}
     */
    network

    /**
     * @type {TaskData[]}
     */
    tasks = []

    /**
     * @type {Object.<string, Dumbapp>}
     */
    codes

    /**
     * @type {string | null}
     */
    account

    /**
     * @type {string | null}
     */
    encryptionKey

    /**
     * @type {Object}
     */
    state = {}

    /**
     * @type {number | null}
     */
    latestTask

    /**
     * @type {number[]}
     */
    viewedTasks = []

    /**
     * @type {Moment}
     */
    periodEnds

    /**
     * @type {boolean}
     */
    endingSoon

    /**
     * @type {number}
     */
    endsInHours

    constructor(core = null, ext = null, address = null, network = null, contractId = null) {
        this.address = address;
        this.contractId = contractId;
        this.core = core;
        this.ext = ext;
        this.network = network;
    }
}
