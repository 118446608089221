<template>
    <div class="core-task-detail create-task">
        <div class="task-section">
            <i class="material-icons section-icon">note</i>
            <FormulateForm
                @validation="valid = $event"
            >
                <div class="task-title">
                    <FormulateInput
                        label="Title"
                        type="text"
                        name="title"
                        validation="required"
                        v-model="title"
                        @blur="blur"
                    />
                </div>

                <label>Payment Token</label>
                <template v-if="tokenData">
                    <div>
                    <a href="javascript: void 0;" @click="token = null">change token</a>
                    </div>
                    <Asset
                        class="token-asset"
                        v-if="tokenData"
                        :asset="tokenData"
                    />
                </template>
                <template v-else>
                    <p>Select a token to pay the task reward with.</p>
                    <ContractLoader
                        :load-click="tokenLoaded"
                        features="erc20"
                        :network="core.network"
                        hide-recents
                        no-label
                    />
                </template>
                <div class="task-reward">
                    <FormulateInput
                        label="Reward"
                        step="any"
                        type="number"
                        @blur="blur"
                        error-behavior="blur"
                        :help="`Amount of tokens to reward for completing this task. Max reward: ${maxReward || '0'}`"
                        name="reward"
                        v-model="reward"
                        :validation="[
                            ['required'],
                            ['not', '0'],
                            ['min', 0],
                            ['max', maxReward]
                        ]
                        "
                    />
                </div>
            </FormulateForm>
        </div>

        <div class="task-section">
            <i class="material-icons section-icon">notes</i>
            <h6>Description</h6>
            <MarkdownEditor :content.sync="description" min-height="100"
                            @blur="blur"
                            :toolbar="['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                            'link', 'image', 'preview', 'guide']"
            />
        </div>

        <div class="task-section" ref="buttons">
            <div v-if="notEnoughTokens" class="error">
                You must have at least
                <TokenAmount :value="core.ext.minimumCreateBalance" :decimals="core.core.decimals"
                             :symbol="core.core.symbol"/>
                to create a task, you have
                <TokenAmount :value="core.core.balance" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                .
            </div>
            <div class="create-buttons">
                <DumbappPopover
                    label="Create Task"
                    :instance="dumbapp"
                    :disabled="disable"
                    :values="query"
                    @submitted="taskSubmitted"
                />

                <v-popover v-if="task" :container="$refs.buttons" @show="showDelete">
                    <button class="btn btn-sm btn-link">Delete Draft</button>
                    <template #popover>
                        <div class="popover-body">
                            <p>The draft will be deleted permanently.</p>
                            <button class="btn btn-sm btn-danger" v-close-popover
                                    @click="$emit('delete', task.id)">Delete
                            </button>
                        </div>
                    </template>
                </v-popover>
            </div>
        </div>
    </div>
</template>

<script>
import Asset from "@/components/Asset";
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import {getTokenData} from "@/lib/eth/Token";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import ContractLoader from "@/views/happs/components/ContractLoader";
import Effort from "@/views/happs/core/Effort";
import CreateMixin from "@/views/happs/core/freelance/CreateMixin";
import BigNumber from "bignumber.js";
import {mapActions, mapGetters, mapState} from "vuex";

const crypt = new AesCrypt();

export default {
    name: "FreelanceCreateTask",
    components: {Asset, ContractLoader, MarkdownEditor, Effort, DumbappPopover, ProgressCircle, Markdown, TokenAmount},
    mixins: [CreateMixin],
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('user', ['api']),
        query() {
            let query = {title: this.title, description: this.description};

            if (this.core.encryptionKey && this.encryptionKey) {
                query.encryption = "aes-2";
                query.title = crypt.encrypt(this.title, {password: this.core.encryptionKey});
                query.timestamp = this.timestamp;
                if (this.description) {
                    query.description = crypt.encrypt(this.description, {password: this.encryptionKey});
                }
            }

            return {
                text: JSON.stringify(query),
                reward: this.reward
            }
        },
        maxReward() {
            if (this.tokenData?.balance) {
                // Adjust for the 10% additional fee
                return new BigNumber(this.tokenData.balance)
                    .div('1.1')
                    .sd(4, BigNumber.ROUND_FLOOR)
                    .toString(10);
            }
            return null;
        },
        fee() {

        }
    },
    asyncComputed: {
        tokenData() {
            if (this.token) {
                return getTokenData(this.core.network, this.token, this.account)
                    .then(res => {
                        let token = res[0];
                        token.balance = new BigNumber(token.balance).div(`1e${token.decimals}`);
                        return token;
                    });
            }
            return null;
        },
        dumbapp() {
            if (this.token) {
                return this.api.getFreelanceCreateDumbapp(this.core.contractId, this.token);
            }
            return null;
        },
        encryptionKey() {
            return this.api.getEncryptionKey(this.core.contractId, {
                version: "2",
                address: this.account,
                timestamp: this.timestamp
            });
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        ...mapActions('drafts', ['saveTaskDraft', 'clearTaskDraft']),
        taskSubmitted(submission) {
            this.$emit('submitted', submission, this.task);

            let reward = submission.data.args[1].args[1];

            this.trackSubmission({
                key: ['core-task', this.core.network, this.core.address].join('-'),
                data: {
                    id: submission.id,
                    reward,
                    title: this.title,
                    symbol: this.tokenData.symbol,
                    planningId: this.task?.id
                }
            });

            this.clearTaskDraft(this.core.contractId);
        },
        tokenLoaded(data) {
            this.token = data.address;
            this.blur();
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.create-task {
    ::v-deep( .editor-toolbar ) {

        background: #efefef;
        font-size: 0.8em;
        padding: 0;

        &::before, &::after {
            content: none;
        }
    }

    ::v-deep() {
        .wallet-asset .wallet-asset-inner {
            border-bottom: none;
        }
    }
}

.markdown-help {
    font-size: 14px;
    color: $secondary;
    margin-top: 3px;
}

.task-reward {
    ::v-deep( .formulate-input-element ) {
        max-width: 300px;
    }
}

.create-buttons {
    display: flex;
    align-items: flex-end;

    .delete-draft {
        margin-left: 5px;
    }
}

.change-token-wrap {
    text-align: right;
}

</style>
