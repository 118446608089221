<template>
    <div class="task-section task-info">
        <i class="material-icons section-icon">note</i>
        <template v-if="isEncrypted && !decrypted">
            <h5 class="task-title-encrypted">Encrypted</h5>
            <div v-if="core.core.frozen"
                 class="error">You are frozen and not allowed to decrypt content.
            </div>
            <div v-else
                 class="error">You must have {{ core.core.symbol }} tokens to view encrypted content.
            </div>
            <div v-else class="error">
                Encrypted content requires signing in.
                <button class="btn btn-primary btn-sm" @click="login">Sign In</button>
            </div>
        </template>
        <h5 v-else class="task-title">
            {{ decrypted || task.content.title }}
        </h5>
        <h5 v-if="showContractName" class="contract-name">
            {{core.core.name}}
        </h5>
        <div class="task-id">
            Task #<span>{{ task.id }} </span> created by
            <Address :address="task.creator" :network="network"/>
        </div>
        <div class="task-reward">
            Reward
            <template v-if="core.freelance()">
                <TokenAmount
                    :value="task.reward"
                    :decimals="tokenData.decimals"
                />
                <a :href="tokenLink" target="_blank">{{tokenData.name}} ({{tokenData.symbol}})</a>
            </template>
            <TokenAmount
                v-else
                :value="task.reward"
                :symbol="tokenData.symbol"
                :decimals="tokenData.decimals"
            />
        </div>
        <div class="watch-task" :class="{watching: isWatching}">
            <button class="btn btn-sm" @click="toggleWatch"
                    :class="isWatching ? 'btn-primary' : 'btn-outline-primary'">
                <i class="material-icons">visibility</i>
                {{ watchingText }}
            </button>
        </div>
    </div>
</template>
<script>
import {getNetwork} from "@/assets/lib/networks";
import Address from "@/components/Address"
import TokenAmount from "@/components/TokenAmount"
import StandardBookBrowser from "@/views/happs/book/StandardBookBrowser";
import TaskTokenMixin from "@/views/happs/core/freelance/TaskTokenMixin";
import {CoreData, TaskData} from "@/views/happs/core/lib/CoreData";
import CryptMixin from "@/views/happs/core/lib/CryptMixin";
import WatchingMixin from "@/views/happs/core/WatchingMixin";
import {mapState} from "vuex";

export default {
    name: 'TaskInfo',
    components: {StandardBookBrowser, Address, TokenAmount},
    mixins: [WatchingMixin, CryptMixin, TaskTokenMixin],
    props: {
        core: CoreData,
        network: Number,
        task: TaskData,
        showContractName: Boolean
    },
    computed: {
        ...mapState('user', ['account']),
        isEncrypted() {
            return this.task?.content?.encryption;
        },
        watchingText() {
            if (this.isWatching) {
                return "Watching Task";
            }
            return "Watch This Task";
        },
        tokenLink() {
            let net = getNetwork(this.core.network);

            return net.explorerToken(this.task.rewardToken);
        }
    },
    asyncComputed: {
        decrypted() {
            if (this.isEncrypted && this.core.encryptionKey) {
                return this.crypt.decrypt(this.task.content.title, {password: this.core.encryptionKey});
            }
            return null;
        }
    },
    methods: {

        login() {
            this.$bus.emit('login_request', {
                message: ""
            });
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.task-info {
    .task-title, .task-title-encrypted {
        margin: 0;
    }

    .task-title-encrypted {
        font-style: italic;
        color: $secondary;
    }

    .contract-name {
        font-weight: normal;
        margin: 0;
        font-size: 20px;
        color: $secondary;
    }

    .task-id {
        font-size: 14px;
        color: $secondary;
        display: flex;
        align-items: center;

        span {
            color: $dark;
            margin-right: 4px;
        }

        .wallet-address {
            background-color: #fff;
            border-color: #fff;
        }
    }

    .task-title-encrypted {
        font-style: italic;
        color: $secondary;
    }

    .watch-task {
        margin-top: 10px;

        .btn-outline-primary:not(:disabled):not(.disabled):active {
            color: $primary;
            background-color: #fff;
        }

        .btn-outline-primary:hover {
            color: $primary;
            background-color: rgba($primary, .1);
        }

        .material-icons {
            margin-right: 5px;
            color: $primary;
        }

        &.watching {
            .material-icons {
                color: #fff;
            }
        }
    }
}
</style>
