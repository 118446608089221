<template>
    <div class="effort-comment" :class="classes" :id="'comment-' + comment.taskId + '-' + comment.id">
        <div class="effort-comment-header">
            <AddressBlockie
                :address="comment.commenter"/>
            <i v-if="isEncrypted" class="material-icons encrypted-icon">lock</i>
            <AddressArgument :value="comment.commenter" ellipsize/>
            <span class="comment-timestamp">{{ time }}</span>
            <span v-if="comment.collaborator !== undefined" class="commenter-status is-collaborator">collaborator</span>
            <span v-else-if="comment.taskCreator" class="commenter-status is-task-creator">task creator</span>
            <span v-else-if="comment.effortCreator" class="commenter-status is-effort-creator">effort creator</span>
            <span v-if="comment.approver !== undefined" class="commenter-status is-approver">approver</span>
            <span v-if="comment.contester !== undefined" class="commenter-status is-contester">contesters</span>
            <span v-if="unread" class="new-comment">NEW</span>
        </div>
        <div class="comment-text">
            <div v-if="comment.type === 'completion'" class="completed-notice" :class="{error: effortFailed}">
                Effort #{{ comment.effortId }}
                <span v-if="effortFailed"> Failed</span>
            </div>
            <div v-if="isEncrypted && !decrypted" class="encrypted-comment comment-content">
                Encrypted
            </div>
            <Markdown v-else :content="decrypted || comment.content.text" class="comment-content"/>
            <CommentActions v-if="comment.type === 'completion' && !effortFailed"
                            :open-approve="showApprove"
                            :comment="comment"
                            :effort="effort"
                            :core="core"
                            :task="task"
            />
        </div>
        <div v-if="comment.type === 'response' && rewarded" class="formulate-input-help">
            This account was rewarded for one of their comments.
        </div>
        <div v-else-if="canBeRewarded && canReward" class="reward-comment">
            <DumbappPopover
                :instance="core.codes.rewardComment"
                button-class="btn-link"
                :values="rewardQuery"
                label="Reward Comment"
            />
        </div>
    </div>
</template>

<script>
import Address from "@/components/Address";
import AddressBlockie from "@/components/AddressBlockie";
import Clipboard from "@/components/Clipboard";
import AddressArgument from "@/components/dumbapp/argument/AddressArgument";
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import CommentActions from "@/views/happs/core/CommentActions";
import {CommentData, CoreData, TaskData} from "@/views/happs/core/lib/CoreData";
import CryptMixin from "@/views/happs/core/lib/CryptMixin";

export default {
    name: "Comment",
    components: { AddressArgument, DumbappPopover, CommentActions, Address, Markdown, Clipboard, AddressBlockie},
    mixins: [CryptMixin],
    props: {
        comment: CommentData,
        network: [String, Number],
        core: CoreData,
        unread: Boolean,
        task: TaskData,
        canReward: Boolean,
        collaborators: Array,
        showApprove: Boolean,
        encryptionKey: String
    },
    computed: {
        /**
         *
         * @return {EffortData}
         */
        effort() {
            if (this.comment.type === 'completion') {
                return this.task.efforts[this.comment.effortId];
            }
            return {};
        },
        isEncrypted() {
            return this.comment?.content?.encryption;
        },
        effortPending() {
            return this.effort.status === "completed";
        },
        effortApproved() {
            return this.effort.status === "approved";
        },
        effortInvalid() {
            return this.effort.status === "invalid";
        },
        effortFailed() {
            return this.effort.status === "failed";
        },
        classes() {
            let list = ['type-' + this.comment.type];

            if (this.effortPending) {
                list.push('pending');
            }
            if (this.effortApproved) {
                list.push('approved');
            }
            if (this.effortInvalid) {
                list.push('invalid');
            }
            if (this.effortFailed) {
                list.push('failed');
            }

            return list;
        },
        time() {
            if (this.comment.timestamp.clone().add(24, 'hours').isAfter()) {
                return this.comment.timestamp.fromNow();
            }
            return this.comment.timestamp.format('lll');
        },
        rewardQuery() {
            return {
                taskId: this.task.id.toString(),
                account: this.comment.commenter
            }
        },
        rewarded() {
            return this.task.responseRewarded.includes(this.comment.commenter.toLowerCase());
        },
        canBeRewarded() {
            let commenter = this.comment.commenter.toLowerCase();
            return this.task.commentRequestReward !== "0"
                && this.comment.type === "response"
                && !this.rewarded
                && !this.collaborators.includes(commenter)
                && this.task.responders.includes(commenter);
        }
    },
    asyncComputed: {
        decrypted() {
            if (this.isEncrypted && this.encryptionKey) {
                return this.crypt.decrypt(this.comment.content.text, {password: this.encryptionKey});
            }
            return null;
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.effort-comment {
    margin-bottom: 20px;

    .effort-comment-header {
        display: flex;
        font-size: 0.88em;
        opacity: 0.8;

        .clipboard-text {
            color: $dark;
        }

        .address-blockie {
            position: absolute;
            left: 0;
            margin-top: 5px;
        }

        @media screen and (max-width: 400px) {
            font-size: 0.7em;
        }
    }

    .comment-text {
        background: rgba($primary, 0.05);
        border-radius: 2px;
        margin-top: 2px;
        padding: 12px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &.type-completion {
        &.pending {
            .comment-text {
                background: rgba(#efae00, 0.1);
                border: 1px solid rgba(#efae00, 0.6);
            }
        }

        &.approved {
            .comment-text {
                background: rgba($success, 0.1);
                border: 1px solid rgba($success, 0.6);
            }
        }

        &.invalid {
            .comment-text {
                background: rgba($danger, 0.1);
                border: 1px solid rgba($danger, 0.6);
            }
        }

        &.failed {
            .comment-text {
                background: rgba($secondary, 0.1);
                border: 1px solid rgba($secondary, .6);
            }
        }
    }

    .comment-timestamp {
        margin: 0 8px;
        @media screen and (max-width: 400px) {
            margin: 0 4px;
        }
    }

    .commenter-status {
        font-size: 12px;
        border: 1px solid rgba($primary, .5);
        color: $primary;
        display: inline-block;
        padding: 1px 8px 2px;
        border-radius: 10px;
        margin-left: 5px;
        margin-bottom: 2px;
    }

    .is-task-creator {
        border-color: rgba($purple, .6);
        color: $purple;
    }

    .is-approver {
        border-color: rgba($success, .6);
        color: $success;
    }

    .is-contester {
        border-color: rgba($success, .6);
        color: $success;
    }

    .encrypted-comment {
        font-style: italic;
        color: $secondary;
    }

    .encrypted-icon {
        position: absolute;
        left: 20px;
        margin-top: 38px;
        font-size: 14px;
        color: #666;
    }

    .new-comment {
        font-size: 12px;
        background: $danger;
        color: #fff;
        display: inline-block;
        padding: 1px 8px 2px;
        border-radius: 10px;
        margin-left: 5px;
        margin-bottom: 2px;
    }

    .completed-notice {
        font-size: 0.8em;
        color: $secondary;
        margin-bottom: 8px;

        &.error {
            color: $danger;
        }
    }
}
</style>
