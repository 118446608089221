import Dapp from "@/assets/lib/Dapp";
import {Batcher} from "@/lib/eth/Batcher";
import { Chain } from "@blockwell/chain-client";
import {CoreData} from "./CoreData";
import BigNumber from "bignumber.js";
import abi from "@/assets/data/CoreToken.abi.json";
import tasksAbi from "@/assets/data/TasksExtension.v34.abi.json";
import bookAbi from "@/assets/data/BaseBook.abi.json";
import * as R from 'rambdax';

/**
 *
 * @param {Happ} happ
 * @return {Promise<CoreData>}
 */
export async function loadCore(happ) {
    return loadStatic(happ);
}

export async function loadCoreForExtension(network, address) {
    let net = Dapp.getNetwork(network);
    let contract = new net.web3.eth.Contract(tasksAbi, address);

    return contract.methods.token().call();
}

async function loadStatic(happ) {
    let batcher = new Batcher(happ.web3);

    batcher.setContract(abi, happ.address)
        .add('name')
        .addBig('burnPercentage')
        .addInt('decimals')
        .add('symbol')
        .addBig('nextPeriodReward')
        .add('paused')
        .addInt('periodLength')
        .addBig('periodMinimum')
        .add('extensions', 'tasks', [0]);

    let core = await batcher.execute();

    batcher = new Batcher(happ.web3);

    batcher.setContract(tasksAbi, core.tasks)
        .addInt('approvalFactor')
        .addBig('contestPercentage')
        .addInt('contestPeriods')
        .addInt('failureFactor')
        .addBig('invalidPenaltyPercentage')
        .addBig('minimumCreateBalance')
        .addInt('taskPeriodLimit')
        .addInt('taskCount')
        .addInt('taskRewardFactor');

    let ext = await batcher.execute();


    return new CoreData(
        core,
        ext,
        happ.address,
        happ.network.networkId,
        happ.contractId);
}

/**
 *
 * @param happ
 * @param {CoreData} data
 * @param account
 * @return {Promise<{core: {totalSupply: BigNumber, periodStart: number, currentPeriod: number}, tasks: TaskData[]}>}
 */
export async function loadDynamic(happ, data, account) {
    let token = new happ.web3.eth.Contract(abi, happ.address);
    let contract = new happ.web3.eth.Contract(tasksAbi, data.core.tasks);

    let count = data.ext.taskCount;

    if (count === undefined || count === null) {
        count = await contract.methods.taskCount().call();
    }
    let batch = new happ.web3.BatchRequest();

    batch.add(contract.methods.taskCount().call.request());
    batch.add(token.methods.currentPeriod().call.request());
    batch.add(token.methods.periodStart().call.request());
    batch.add(token.methods.totalSupply().call.request());
    if (account) {
        batch.add(token.methods.balanceOf(account).call.request());
        batch.add(token.methods.getRewards(account).call.request());
        batch.add(token.methods.getPenalty(account).call.request());
    }

    let ids = [];
    /**
     *
     * @type {TaskData[]}
     */
    let tasks = [];
    let i = 0;
    for (; i < count; i++) {
        batch.add(contract.methods.getTaskInts(i).call.request());
        batch.add(contract.methods.getTaskCreator(i).call.request());
        batch.add(contract.methods.getTaskText(i).call.request());
        batch.add(contract.methods.effortCount(i).call.request());
        ids.push(i);
    }
    let res = (await batch.execute()).response;

    let n = 0;
    let newCount = parseInt(res[n++]);

    if (count !== newCount) {
        let batch = new happ.web3.BatchRequest();
        for (; i < newCount; i++) {
            batch.add(contract.methods.getTaskInts(i).call.request());
            batch.add(contract.methods.getTaskCreator(i).call.request());
            batch.add(contract.methods.getTaskText(i).call.request());
            batch.add(contract.methods.effortCount(i).call.request());
            ids.push(i);
        }
        res = res.concat((await batch.execute()).response);
    }

    let core = {
        currentPeriod: parseInt(res[n++]),
        periodStart: parseInt(res[n++]),
        totalSupply: new BigNumber(res[n++])
    };

    if (account) {
        core.balance = new BigNumber(res[n++]);
        core.rewards = new BigNumber(res[n++]);
        core.penalty = new BigNumber(res[n++]);
    } else {
        core.balance = new BigNumber(0);
    }

    for (let id of ids) {
        let ints = res[n++];
        let creator = res[n++];
        let text = res[n++];
        let effortCount = parseInt(res[n++]);

        let content;

        if (text.startsWith('{')) {
            try {
                content = JSON.parse(text);
            } catch (err) {
                console.log('Failed parsing task JSON', text.slice(0, 10), '...');
            }
        }
        if (!content) {
            content = {
                title: text
            }
        }

        let task = {
            id,
            contractId: happ.contractId,
            creator,
            text,
            content,
            effortCount,
            commentCount: 0,
            creationPeriod: parseInt(ints[0]),
            reward: new BigNumber(ints[1]),
            perPeriod: parseInt(ints[2]),
            completionCount: parseInt(ints[3]),
            status: getStatus(ints[4]),
            efforts: []
        };

        tasks.push(task);
    }

    batch = new happ.web3.BatchRequest();

    for (let task of tasks) {
        for (let i = 0; i < task.effortCount; i++) {
            batch.add(contract.methods.getEffortInts(task.id, i).call.request());
            batch.add(contract.methods.getEffortCollaborators(task.id, i).call.request());
        }
    }

    n = 0;
    res = (await batch.execute()).response;

    for (let task of tasks) {
        for (let i = 0; i < task.effortCount; i++) {
            let ints = res[n++];
            let collaborators = res[n++];
            let effort = {
                id: i,
                creationPeriod: parseInt(ints[0]),
                approvals: new BigNumber(ints[1]),
                contests: new BigNumber(ints[2]),
                approvalPeriod: parseInt(ints[3]),
                commentCount: parseInt(ints[4]),
                comments: null,
                status: getStatus(ints[5]),
                collaborators
            }

            task.commentCount += effort.commentCount;

            if (account) {
                if (collaborators.find(it => it.toLowerCase() === account.toLowerCase())) {
                    task.yourTask = true;
                }
            }

            task.efforts.push(effort);
        }

        task.completeStatus = taskStatus(task, data, core.currentPeriod);
    }

    return {
        core,
        tasks
    };
}

function getStatus(val) {
    let status;

    switch (parseInt(val)) {
        case 0:
            status = "open";
            break;
        case 1:
            status = "repeating";
            break;
        case 2:
            status = "completed";
            break;
        case 3:
            status = "approved";
            break;
        case 4:
            status = "failed";
            break;
        case 5:
            status = "invalid";
            break;
        case 6:
            status = "rejected";
            break;
    }

    return status;
}

export const taskStatuses = {
    open: "Open",
    repeating: "Repeating",
    progress: "In Progress",
    pending: "Pending Approval",
    completed: "Completed",
    invalid: "Invalid",
    archived: "Archived"
}

/**
 *
 * @param {TaskData} task
 * @param {CoreData} data
 * @param {number} currentPeriod
 * @return {string}
 */
function taskStatus(task, data, currentPeriod) {
    let effort;
    switch (task.status) {
        case "open":
            if (task.effortCount > 0) {
                let compl = task.efforts.find(it => it.status === "completed");
                if (compl) {
                    return "pending";
                }

                let open = task.efforts.find(it => it.status === "open");
                if (open) {
                    return "progress";
                }
            }
            return "open";
        case "completed":
            effort = task.efforts.find(it => it.status === "approved");
            if (effort && currentPeriod - data.ext.contestPeriods > effort.approvalPeriod) {
                return "archived";
            }
            return task.status;
        case "invalid":
            effort = task.efforts.find(it => it.status === "invalid");

            if (effort && currentPeriod - data.ext.contestPeriods > effort.approvalPeriod) {
                return "archived";
            }
            return task.status;
        default:
            return task.status;
    }
}

export const effortStatuses = {
    open: "In Progress",
    completed: "Pending Approval",
    approved: "Completed",
    failed: "Failed",
    invalid: "Invalid"
}

/**
 *
 * @param {Happ} happ
 * @param {CoreData} data
 * @param {TaskData} task
 * @return {Promise<EffortData[]>}
 */
export async function loadEfforts(happ, data, task) {
    if (!task.effortCount) {
        return [];
    }
    let contract = new happ.web3.eth.Contract(tasksAbi, data.core.tasks);

    let commentsPromise = contract.getPastEvents('Commented', {
        fromBlock: 0,
        toBlock: 'latest',
        filter: {
            taskId: task.id.toString()
        }
    });

    let batch = new happ.web3.BatchRequest();

    for (let it of task.efforts) {
        batch.add(contract.methods.getEffortCreator(task.id, it.id).call.request());
        batch.add(contract.methods.getEffortCollaborators(task.id, it.id).call.request());
        batch.add(contract.methods.getEffortApprovers(task.id, it.id).call.request());
        batch.add(contract.methods.getEffortContesters(task.id, it.id).call.request());
    }
    let res = (await batch.execute()).response;

    let n = 0;
    let efforts = [];
    for (let it of task.efforts) {
        let effort = Object.assign({}, it, {
            creator: res[n++],
            collaborators: res[n++],
            approvers: res[n++],
            contesters: res[n++]
        });

        efforts.push(effort);
    }

    let events = await commentsPromise;

    for (let event of events) {
        let vals = event.returnValues;
        let effortId = parseInt(vals.effortId);
        let effort = efforts[effortId];

        if (effort) {
            if (!effort.comments) {
                effort.comments = [];
            }
            let content;

            if (vals.comment.startsWith('{')) {
                try {
                    content = JSON.parse(vals.comment);
                } catch (err) {
                    console.log('Failed parsing task JSON', vals.comment.slice(0, 10), '...');
                }
            }
            if (!content) {
                content = {
                    text: vals.comment
                }
            }

            let comment = {
                id: vals.commentId,
                commenter: vals.commenter,
                comment: vals.comment,
                content,
                effortId,
                taskId: parseInt(vals.taskId)
            };

            if (effort.collaborators.includes(vals.commenter)) {
                comment.collaborator = true;
            }
            if (task.creator === vals.commenter) {
                comment.taskCreator = true;
            }
            if (effort.creator === vals.commenter) {
                comment.effortCreator = true;
            }

            effort.comments.push(comment);
        }
    }

    efforts.reverse();

    return efforts;
}

/**
 *
 * @param {CoreData} data
 * @return {Promise<object>}
 */
export async function loadFundraising(data) {
    let json = await Chain.readString(5, '0xaEEeBd6ca46BEf69689F043681fEe5F9961D199A', bookAbi, 'get', [
        'Core',
        'Target'
    ], 120000);

    return JSON.parse(json);
}
