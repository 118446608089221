<template>
    <div class="effort-comment comment-request">
        <div class="comment-request-header">
            Comment Request for
            <TokenAmount :value="task.commentRequestReward" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
        </div>
        <div class="requested-by">
            Requested by <Address :network="core.network" :address="task.requester"/>
        </div>
        <div class="comment-text">
            <div v-if="isEncrypted && !decrypted" class="encrypted-comment comment-content">
                Encrypted
            </div>
            <Markdown v-else :content="decrypted || task.commentRequest.text" class="comment-content"/>
        </div>
        <div v-if="canBeRewarded" class="formulate-input-help">
            Respond below with a comment, and you may get rewarded.
        </div>
    </div>
</template>

<script>
import Address from "@/components/Address";
import AddressBlockie from "@/components/AddressBlockie";
import Clipboard from "@/components/Clipboard";
import Markdown from "@/components/Markdown";
import TokenAmount from "@/components/TokenAmount";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import CommentActions from "@/views/happs/core/CommentActions";
import {CommentData, CoreData, TaskData} from "@/views/happs/core/lib/CoreData";
import CryptMixin from "@/views/happs/core/lib/CryptMixin";

export default {
    name: "CommentRequest",
    components: {Address, TokenAmount, Markdown},
    mixins: [CryptMixin],
    props: {
        core: CoreData,
        task: TaskData
    },
    computed: {
        isEncrypted() {
            return this.task?.commentRequest?.encryption;
        },
        canBeRewarded() {
            return !this.task.isCollaborator && !this.task.isRequester && !this.task.hasResponseRewarded;
        }
    },
    asyncComputed: {
        decrypted() {
            if (this.isEncrypted && this.core.encryptionKey) {
                return this.crypt.decrypt(this.task.commentRequest.text, {password: this.core.encryptionKey});
            }
            return null;
        },
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.comment-request-header {
    font-size: 18px;
    ::v-deep( .token-amount ) {
        font-weight: 600;
    }
}

.comment-text {
    border: 1px solid rgba($primary, .3);
}

.requested-by {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    ::v-deep( .wallet-address ) {
        background-color: #fff;
        border-color: #fff;
    }
}
</style>
