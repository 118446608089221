<template>
    <div class="planning-mode-wrap" ref="wrapper">

        <div class="planning-columns">
            <PlanningColumn @task="newTask" :tasks="columnTasks.uncategorized" @reward="rewardChanged"
                            :pending="pendingTasks" ref="defaultColumn"/>

            <PlanningColumn v-for="col in columnTasks.columns"
                            ref="columns"
                            :tasks="col.tasks"
                            :title="col.title"
                            :pending="pendingTasks"
                            :key="col.title"
                            @task="newTask($event, col.title)"
                            @reward="rewardChanged"
                            @title="titleChange"
                            @delete="deleteColumn"
            />

            <div class="core-column">
                <p>You can create additional columns to organize.</p>
                <button class="btn btn-sm btn-outline-primary" @click="createColumn">Create Column</button>
            </div>
        </div>

        <Help dismiss-key="core-planningModeDrafts" :show="showHelp" :reference="helpRef">
            <p>
                Create drafts of tasks by typing in titles. Enter or new line will create a task and clear the
                input.
            </p>
            <p>
                Drafts are saved automatically, and you can submit them as tasks later.
            </p>
        </Help>
        <Modal v-if="core" :show.sync="showOpenTask">
            <FreelanceCreateTask
                v-if="core.freelance()"
                :core="core"
                :task="openTaskData"
                @submitted="showOpenTask = false"
                @change="taskChanged"
                @delete="deleteTask"
            />
            <CreateTask
                v-else
                :core="core"
                :task="openTaskData"
                @change="taskChanged"
                @submitted="showOpenTask = false"
                @delete="deleteTask"
            />
        </Modal>
    </div>
</template>

<script>
import Help from "@/components/Help";
import Modal from "@/components/Modal";
import CreateTask from "@/views/happs/core/CreateTask";
import FreelanceCreateTask from "@/views/happs/core/freelance/FreelanceCreateTask";
import {CoreData} from "@/views/happs/core/lib/CoreData";
import {PlanningTaskData} from "@/views/happs/core/lib/PlanningData";
import PlanningColumn from "@/views/happs/core/planning/PlanningColumn";
import * as R from 'rambdax';
import equal from 'fast-deep-equal';
import {mapActions, mapGetters} from "vuex";
import handyScroll from 'handy-scroll';
import "handy-scroll/dist/handy-scroll.css";

export default {
    name: "PlanningMode",
    components: {FreelanceCreateTask, Help, CreateTask, Modal, PlanningColumn},
    props: {
        core: CoreData,
        value: {
            type: Object,
            default() {
                return {
                    columns: [],
                    tasks: []
                }
            }
        },
        openTask: Number,
        path: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            planning: this.value,
            showHelp: false,
            helpRef: null
        };
    },
    computed: {
        ...mapGetters('dumbapp', ['tracking']),
        trackingKey() {
            return ['core-task', this.core.network, this.core.address].join('-');
        },
        columns() {
            return this.planning.columns;
        },
        columnTasks() {
            let uncategorized = [];
            let columns = [];

            for (let col of this.columns) {
                columns.push({
                    title: col,
                    tasks: []
                });
            }

            for (let task of R.sort((a, b) => b.id - a.id, this.planning.tasks)) {
                let col;
                if (task.column) {
                    col = columns.find(it => it.title === task.column);
                }
                if (col) {
                    col.tasks.push(task);
                } else {
                    uncategorized.push(task);
                }
            }

            return {
                uncategorized,
                columns
            };
        },
        showOpenTask: {
            get() {
                return !!this.openTaskData;
            },
            set(val) {
                if (!val) {
                    this.$router.push({
                        query: this.$route.query,
                        hash: `#plan`
                    })
                }
            }
        },
        openTaskData() {
            if (this.path[0] === 'plan') {
                let id = parseInt(this.path[1]);

                if (!isNaN(id)) {
                    return this.planning.tasks.find(it => it.id === id);
                }
            }
            return null;
        },
        pendingTasks() {
            let tracks = this.tracking[this.trackingKey];

            let data = {};
            if (tracks && tracks.length > 0) {
                let filtered = tracks.filter(it => it.planningId);

                for (let it of filtered) {
                    data[it.planningId] = it;
                }
            }
            return data;
        }
    },
    watch: {
        value(val) {
            this.planning = val;
        },
        planning: {
            deep: true,
            handler(val) {
                this.$emit('input', val);
            }
        },
        pendingTasks: {
            deep: true,
            immediate: true,
            handler(val) {
                for (let track of Object.values(val)) {
                    let index = this.planning.tasks.findIndex(it => it.id === track.planningId);

                    if (index === -1) {
                        this.removeTracking({key: this.trackingKey, id: track.id});
                    } else if (track.status === "completed") {
                        this.planning.tasks.splice(index, 1);
                        this.removeTracking({key: this.trackingKey, id: track.id});
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions('dumbapp', ['removeTracking']),
        newTask(task) {
            this.planning.tasks.push(task);
        },
        rewardChanged(id, reward) {
            let task = this.planning.tasks.find(it => it.id === id);
            this.$set(task, 'reward', reward);
        },
        taskChanged(id, update) {
            let task = this.planning.tasks.find(it => it.id === id);
            if (task) {
                for (let [key, val] of Object.entries(update)) {
                    if (!equal(val, task[key])) {
                        this.$set(task, key, val);
                    }
                }
            }
        },
        deleteTask(id) {
            let index = this.planning.tasks.findIndex(it => it.id === id);
            if (index > -1) {
                this.planning.tasks.splice(index, 1);
                this.showOpenTask = false;
            }
        },
        createColumn() {
            let n = this.planning.columns.length + 2;
            let title = "Column " + n;

            while (this.planning.columns.includes(title)) {
                ++n;
                title = "Column " + n;
            }

            this.planning.columns.push(title);
            this.$nextTick(() => {
                let column = this.$refs.columns[this.$refs.columns.length - 1];
                column.focusTitle();
                handyScroll.update(this.$refs.wrapper);
            });
        },
        titleChange(val, old) {
            let index = this.planning.columns.indexOf(val);
            let columnIndex = this.planning.columns.indexOf(old);
            let column = this.$refs.columns[columnIndex];
            if (index > -1) {
                column.setTitleError("Column names must be unique.");
            } else {
                column.setTitleError(null);
                for (let task of this.planning.tasks) {
                    if (task.column === old) {
                        task.column = val;
                    }
                }
                this.planning.columns.splice(columnIndex, 1, val);
            }
        },
        deleteColumn(title) {
            let index = this.planning.columns.indexOf(title);

            if (index > -1) {
                this.planning.columns.splice(index, 1);
            }
        }
    },
    mounted() {
        this.helpRef = $('.title-editor-textarea', this.$refs.defaultColumn.$el)[0];
        this.showHelp = true;
        handyScroll.mount(this.$refs.wrapper);
    },
    beforeDestroy() {
        handyScroll.destroy(this.$refs.wrapper);
    }
}
</script>

<style scoped lang="scss">
.planning-mode-wrap {
    max-width: 100%;
    overflow-x: auto;
}

.planning-columns {
    display: inline-flex;
    align-items: stretch;
}
</style>
