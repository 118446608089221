import {CoreData} from "@/views/happs/core/lib/CoreData";
import {PlanningTaskData} from "@/views/happs/core/lib/PlanningData";
import {debounce} from "@/lib/vutil";
import {mapState} from "vuex";

export default {
    props: {
        core: CoreData,
        task: PlanningTaskData
    },
    data() {
        return {
            title: this.task?.content?.title || "",
            reward: this.task?.reward || "",
            description: this.task?.content?.description || "",
            token: this.task?.rewardToken || "",
            valid: {},
            timestamp: Math.floor(Date.now() / 1000).toString()
        };
    },
    computed: {
        ...mapState('user', ['account']),
        disable() {
            return this.valid.hasErrors || !this.reward || !this.title;
        },
        notEnoughTokens() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (!core.core?.balance) {
                return false;
            }

            return core.core.balance.lt(core.ext.minimumCreateBalance);
        }
    },
    watch: {
        description: {
            handler: debounce(function () {
                this.blur();
            }, 3000)
        }
    },
    methods: {
        showDelete() {
            if (!this.description) {
                this.$emit('delete', this.task.id);
            }
        },
        blur() {
            this.$emit('change', this.task?.id, {
                reward: this.reward,
                rewardToken: this.token,
                content: {
                    title: this.title,
                    description: this.description
                }
            });
            this.saveTaskDraft({
                contractId: this.core.contractId,
                title: this.title,
                description: this.description,
                reward: this.reward,
                rewardToken: this.token
            });
        }
    },
    created() {
        let draft = this.$store.state.drafts.drafts[`${this.core.contractId}-create-task`];

        console.log('found draft', draft);
        if (draft && !this.title && !this.reward && !this.description) {
            this.title = draft.title;
            this.reward = draft.reward;
            this.description = draft.description;
            this.token = draft.rewardToken;
        }
    }
}
