<template>
    <div class="effort-participants">
        <div class="effort-collaborators">
            <div class="collaborators-label">Collaborators</div>
            <Address v-for="it in effort.collaborators"
                     :key="it"
                     :address="it"
                     :network="network"/>
        </div>
        <div v-if="showApprovers" class="effort-approvers effort-collaborators">
            <div class="collaborators-label">Approved by</div>
            <Address v-for="it in effort.approvers"
                     :key="it"
                     :address="it"
                     :network="network"/>
        </div>
        <div v-if="effort.contests.gt(0)" class="effort-contesters effort-collaborators">
            <div class="collaborators-label">Contested by</div>
            <Address v-for="it in effort.contesters"
                     :key="it"
                     :address="it"
                     :network="network"/>
        </div>
    </div>
</template>

<script>
import Address from "@/components/Address";

export default {
    name: "EffortParticipants",
    components: {Address},
    props: {
        effort: Object,
        network: [String, Number]
    },
    computed: {
        showApprovers() {
            return this.effort?.approvers?.length > 0;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.effort-collaborators {
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;

    ::v-deep( .wallet-address ) {
        margin-left: 5px;
        margin-bottom: 5px;
    }
}

.collaborators-label {
    padding-top: 1px;
}

.effort-approvers ::v-deep( .wallet-address ) {
    background: rgba($success, 0.1);
    border-color: rgba($success, 0.3);
}

.effort-contesters ::v-deep( .wallet-address ) {
    background: rgba($danger, 0.1);
    border-color: rgba($danger, 0.3);
}
</style>
