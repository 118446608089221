<template>
    <div class="task-repeating">
        <div class="task-section">
            <i class="material-icons section-icon">people</i>
            <h6>Current Period</h6>
            <DumbappModal
                v-if="task.perPeriod > task.completionCount"
                label="Add Scrum"
                :instance="core.codes.scrumEffort"
                :values="{taskId: task.id.toString()}"
            />
            <p v-else>
                No more scrums can be completed during this period.
            </p>
            <template v-if="repeatingEfforts.length > 0">
                <Effort v-for="effort in repeatingEfforts"
                        v-on="$listeners"
                        :key="effort.id"
                        :core="core"
                        :task="task"
                        :effort="effort"
                        :network="network"/>
            </template>
            <div v-else>
                No efforts added yet.
            </div>
        </div>
        <div class="task-section">
            <i class="material-icons section-icon">history_toggle_off</i>
            <h6>Previous Period</h6>
            <template v-if="previousRepeatingEfforts.length > 0">
                <RepeatingEffort v-for="effort in previousRepeatingEfforts"
                                 :key="effort.id"
                                 :core="core"
                                 :task="task"
                                 :effort="effort"
                                 :network="network"/>
            </template>
            <div v-else>
                No efforts in the previous period.
            </div>
        </div>
    </div>
</template>
<script>
import DumbappModal from "@/components/DumbappModal"
import ProgressCircle from "@/components/ProgressCircle"
import Effort from "@/views/happs/core/Effort";
import {CoreData, TaskData} from "@/views/happs/core/lib/CoreData";
import RepeatingEffort from "@/views/happs/core/RepeatingEffort"

export default {
    name: 'TaskRepeating',
    components: {Effort, DumbappModal, ProgressCircle, RepeatingEffort},
    props: {
        core: CoreData,
        network: Number,
        task: TaskData
    },
    computed: {
        repeatingEfforts() {
            let core = this.core;

            if (this.task.efforts && this.task.perPeriod > 0) {
                return this.task.efforts.filter(it => {
                    return it.creationPeriod === core.core.currentPeriod;
                });
            }
            return [];
        },
        previousRepeatingEfforts() {
            let core = this.core;

            if (this.task.efforts && this.task.perPeriod > 0) {
                return this.task.efforts.filter(it => {
                    return it.creationPeriod === core.core.currentPeriod - 1;
                });
            }
            return [];
        }
    }
}
</script>
