<template>
    <div class="planning-column core-column">
        <EditableText
            ref="title"
            v-if="title"
            element="h3"
            class="core-column-title"
            :value="title"
            @blur="titleBlur"
        />
        <h3 v-else class="core-column-title">Uncategorized</h3>
        <div v-if="titleError" class="error">{{ titleError }}</div>
        <div class="core-task pending-task">
            <div class="task-id">New Task Title</div>
            <div class="title-editor">
                <textarea
                    ref="newTitle"
                    class="title-editor-textarea"
                    v-model="newTitle"
                    @input="input"
                    @blur="blur"
                    @keydown="keydown"
                    rows="1"></textarea>
            </div>
            <button v-if="newTitle" class="btn btn-sm btn-primary" @click="createTask">Save</button>
        </div>
        <PlanningTask
            v-for="task in tasks"
            :task="task"
            :pending="pending[task.id]"
            :key="task.id"
            @click="openTask(task.id)"
            @reward="$listeners.reward"/>

        <div v-if="title" class="delete-column">
            <v-popover :container="$el">
                <button class="btn btn-sm btn-link">Delete Column</button>
                <template #popover>
                    <div class="popover-body">
                        <p>The tasks will be moved to Uncategorized.</p>
                        <button class="btn btn-sm btn-danger" v-close-popover @click="$emit('delete', title)">Delete</button>
                    </div>
                </template>
            </v-popover>
        </div>
    </div>
</template>

<script>
import EditableText from "@/components/creator/EditableText";
import {PlanningTaskData} from "@/views/happs/core/lib/PlanningData";
import PlanningTask from "@/views/happs/core/planning/PlanningTask";
import * as R from 'rambdax';

export default {
    name: "PlanningColumn",
    components: {EditableText, PlanningTask},
    props: {
        title: String,
        tasks: Array,
        pending: Object
    },
    data() {
        return {
            newTitle: "",
            titleError: null
        };
    },
    watch: {
        newTitle() {
            this.$nextTick(() => {
                let el = this.$refs.newTitle;
                el.style = "height: auto;";
                if (el.scrollHeight > 0) {
                    el.style = `height: ${el.scrollHeight + 5}px`;
                }
            });
        }
    },
    methods: {
        input(event) {
            let val = event.target.value;
            if (val.includes("\n")) {
                this.newTitle = val.replace("\n", "");
            }
        },
        blur(event) {

        },
        keydown(event) {
            if (event.key === "Enter") {
                this.createTask();
            }
        },
        createTask() {
            if (this.newTitle) {
                let task = new PlanningTaskData(Date.now(), {title: this.newTitle});
                if (this.title) {
                    task.column = this.title;
                }
                this.$emit('task', task);
                this.newTitle = "";
                this.$refs.newTitle.focus();
            }
        },
        openTask(id) {
            this.$router.push({
                query: this.$route.query,
                hash: `#plan/${id}`
            })
        },
        focusTitle() {
            if (this.$refs.title) {
                this.$refs.title.focus();
                this.$nextTick(() => {
                    document.execCommand('selectAll');
                })
            }
        },
        titleBlur(title) {
            if (title !== this.title) {
                this.$emit('title', title, this.title);
            }
        },
        setTitleError(error) {
            this.titleError = error;
        }
    }
}
</script>


<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.title-editor {
    flex: 0 0 100%;
}

.title-editor-textarea {
    padding: 4px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 1px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    resize: none;
    line-height: 24px;
}

.pending-task {
    background: none;
    padding: 5px 10px;
}

.core-column-title {
    border: 1px solid transparent;
}

</style>
