<template>
    <div class="effort-actions">
        <button
            v-if="showComplete"
            @click="$emit('complete')"
            class="btn btn-sm btn-link">Mark As Completed</button>
        <DumbappPopover
            v-if="canAddCollaborators"
            label="Add Collaborator"
            :instance="core.codes.addCollaborator"
            :values="effortQuery"
            button-class="btn-link"
        />
        <button
            v-if="showApprove"
            @click="$emit('approve', effort.id)"
            class="btn btn-sm btn-link">Approve</button>
        <button
            v-if="showContest"
            @click="$emit('contest', effort.id)"
            class="btn btn-sm btn-link">Contest</button>
        <button
            v-if="showGoto"
            @click="$emit('comment', effort.id)"
            class="btn btn-sm btn-link">Go To Comment</button>
    </div>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import TokenAmount from "@/components/TokenAmount";
import {CoreData, EffortData, TaskData} from "@/views/happs/core/lib/CoreData";
export default {
    name: "EffortActions",
    components: {TokenAmount, DumbappPopover},
    props: {
        task: TaskData,
        effort: EffortData,
        core: CoreData
    },
    data() {
        return {};
    },
    computed: {
        showGoto() {
            return this.effort.status !== 'open' && !this.showComplete && !this.showApprove && !this.showContest;
        },
        effortQuery() {
            return {
                taskId: this.task?.id?.toString(),
                effortId: this.effort?.id?.toString()
            }
        },
        isPrimaryCollaborator() {
            if (this.core.account) {
                return this.effort?.collaborators?.[0]?.toLowerCase() === this.core.account.toLowerCase();
            }
            return false;
        },
        canBeCompleted() {
            return this.effort.status === 'open';
        },
        showComplete() {
            return this.effort.isCollaborator && this.canBeCompleted;
        },
        canAddCollaborators() {
            return this.effort.status === 'open' && this.isPrimaryCollaborator;
        },
        canBeApproved() {
            let core = this.core;
            if (this.effort.status === 'completed') {
                return true;
            }
            if (this.effort.status === 'approved') {
                return core.core.currentPeriod - core.ext.contestPeriods <= this.effort.approvalPeriod
                    && this.effort.contesters.length > 0;
            }
            return false;
        },
        showApprove() {
            if (this.effort.isContester || this.effort.isApprover || this.effort.isCollaborator || this.task.isCreator) {
                return false;
            }

            return this.canBeApproved;
        },
        canBeContested() {
            let core = this.core;
            return this.effort.status === 'approved'
                && core.core.currentPeriod - core.ext.contestPeriods <= this.effort.approvalPeriod
        },
        showContest() {
            if (this.effort.isContester || this.effort.isApprover) {
                return false;
            }
            return this.canBeContested;
        },
        tokensForApproval() {
            let core = this.core;

            return this.task.reward.times(core.ext.approvalFactor).minus(this.effort.approvals);
        },
        notEnoughToApprove() {
            let core = this.core;
            if (!core.core.balance) {
                return false;
            }

            return core.core.balance.lt(this.tokensForApproval);
        },
        tokensForContesting() {
            let core = this.core;

            return this.effort.approvals.times(core.ext.contestPercentage).div('1e18').minus(this.effort.contests);
        },
        notEnoughToContest() {
            let core = this.core;
            if (!core.core.balance) {
                return false;
            }

            return core.core.balance.lt(this.tokensForContesting);
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.effort-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.action-notice {
    color: $secondary;
    flex: 0 0 100%;

    > ::v-deep(div) {
        margin-bottom: 5px;
    }

    ::v-deep( strong ) {
        color: $danger;
    }
}

.approvals {
    ::v-deep( .label ) {
        display: inline-block;
        width: 120px;
    }
}
</style>
