export class CoreData {
    constructor(type, core = null, ext = null, address = null, network = null, contractId = null) {
        this.codes = {};
        this.encryptionKey = undefined;
        this.state = {
            planning: {
                columns: [],
                tasks: [],
            },
        };
        this.latestTask = null;
        this.viewedTasks = [];
        this.endingSoon = false;
        this.type = type;
        this.address = address;
        this.contractId = contractId;
        this.core = core;
        this.ext = ext;
        this.network = network;
    }
    freelance() {
        return this.type === "CoreFreelanceExtension";
    }
}
export class CoreContractData {
}
export class TasksExtensionData {
}
export class FreelanceData {
}
export class TaskData {
}
export class TaskContent {
}
export class EffortData {
}
export class CommentContent {
}
export class CommentData {
}
export class TaskDetailData {
    constructor() {
        this.additions = [];
        this.commentRequest = null;
        this.comments = [];
    }
}
export class CoreUser {
}
export class CoreAndTasks {
    constructor(core, tasks) {
        this.core = core;
        this.tasks = tasks;
    }
}
