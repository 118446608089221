<template>
    <div class="core-notifications">
        <h3>Notifications</h3>
        <div>
            Notifications are sent for tasks you're watching
            <i class="material-icons watching">visibility</i>
        </div>

        <div>
            <div class="mute">
                <template v-if="mutedUntil">
                    Notifications are muted until {{ mutedUntil }}.
                    <a href="javascript: void 0;" class="unmute" @click="unmute">Unmute</a>
                </template>
                <div v-else class="mute-options-wrap">
                    <div>You can mute notifications for</div>
                    <div>
                        <a href="javascript: void 0;" class="mute-for" @click="mute">8 hours</a>
                        <a href="javascript: void 0;" class="mute-for" @click="mute">24 hours</a>
                        <a href="javascript: void 0;" class="mute-for" @click="mute">3 days</a>
                    </div>
                </div>
            </div>
            <v-popover>
                <button class="btn btn-sm btn-link notification-settings">
                    <i class="material-icons">settings</i>
                    Settings
                </button>
                <template #popover>
                    <div class="notification-options">
                        <div>
                            <div data-classification="box" data-type="checkbox" class="formulate-input">
                                <div class="formulate-input-wrapper">
                                    <div data-type="checkbox"
                                         class="formulate-input-element formulate-input-element--checkbox">
                                        <input type="checkbox" :id="$id('email-notifications')"
                                               v-model="emailNotifications">
                                        <label :for="$id('email-notifications')"
                                               class="formulate-input-element-decorator"></label>
                                    </div>
                                    <label :for="$id('email-notifications')"
                                           class="formulate-input-label formulate-input-label--after">Email notifications
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="notificationSupport">
                            <div data-classification="box" data-type="checkbox" class="formulate-input">
                                <div class="formulate-input-wrapper">
                                    <div data-type="checkbox"
                                         class="formulate-input-element formulate-input-element--checkbox">
                                        <input type="checkbox" :id="$id('push-notifications')"
                                               :checked="pushNotifications"
                                               @change="pushChanged">
                                        <label :for="$id('push-notifications')"
                                               class="formulate-input-element-decorator"></label>
                                    </div>
                                    <label :for="$id('push-notifications')"
                                           class="formulate-input-label formulate-input-label--after">Push notifications
                                    </label>
                                </div>
                            </div>
                            <div v-if="pushLoading" class="push-loading">
                                <ProgressCircle medium/>
                            </div>
                        </div>
                    </div>
                </template>
            </v-popover>
        </div>
    </div>
</template>

<script>
import ProgressCircle from "@/components/ProgressCircle";
import * as service from "@/service/service";
import { parseTime } from "@blockwell/dumbapp";
import {CoreData} from "./lib/CoreData";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
    name: "CoreNotifications",
    components: {ProgressCircle},
    props: {
        core: CoreData
    },
    data() {
        return {
            pushNotifications: false,
            pushLoading: false
        };
    },
    computed: {
        ...mapGetters('user', ['api']),
        emailNotifications: {
            get() {
                console.log('email notifications', this.core.state?.notifications?.email);
                return !(this.core.state?.notifications?.email === false);
            },
            set(val) {
                if (!this.core.state.notifications) {
                    this.$set(this.core.state, 'notifications', {});
                }

                this.$set(this.core.state.notifications, 'email', val);
            }
        },
        notificationSupport() {
            return "Notification" in window;
        },
        mutedUntil() {
            if (this.core.state?.notifications?.muted) {
                let muted = moment.unix(parseInt(this.core.state.notifications.muted));

                if (muted.isAfter()) {
                    return muted.format('lll');
                }
            }
            return null;
        }
    },
    watch: {
        'core.state.notifications.push': {
            immediate: true,
            handler(val) {
                if ("Notification" in window) {
                    service.isPushEnabled().then(enabled => {
                        console.log('push enabled', enabled);
                        console.log('push configured', val);
                        this.pushNotifications = enabled && !!val;
                    }).catch(console.error);
                }
            }
        }
    },
    methods: {
        pushChanged(event) {
            this.setPush(event.target.checked);
        },
        setPush(val) {
            (async () => {
                this.pushLoading = true;
                if (val) {
                    try {
                        await service.enablePush(this.api);
                        if (!this.core.state.notifications) {
                            this.$set(this.core.state, 'notifications', {});
                        }

                        this.$set(this.core.state.notifications, 'push', true);
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    try {
                        await service.disablePush(this.api);
                        if (!this.core.state.notifications) {
                            this.$set(this.core.state, 'notifications', {});
                        }

                        this.$set(this.core.state.notifications, 'push', false);
                    } catch (err) {
                        console.error(err);
                    }
                }
                this.pushLoading = false;
            })().catch(console.error);
        },
        clicked() {
            if (this.notificationSupport && Notification.permission === 'default') {
                Notification.requestPermission()
                    .then(permission => {
                        if (permission === "granted") {
                            this.setPush(true);
                        }
                    });
            }
        },
        mute(event) {
            let time = parseTime(event.target.innerText);
            let until = moment().add(time, 'seconds');
            if (!this.core.state.notifications) {
                this.$set(this.core.state, 'notifications', {});
            }

            this.$set(this.core.state.notifications, 'muted', until.unix());
        },
        unmute() {
            this.$delete(this.core.state.notifications, 'muted');
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.core-notifications {
    margin-top: 20px;
    text-align: center;

    ::v-deep( h3 ) {
        font-size: 16px;
    }

    .watching {
        color: rgba($primary, .7);
    }
}

.notification-settings {
    padding: 4px 12px 4px 12px;
    min-width: auto;

    ::v-deep( i ) {
        color: $primary;
    }
}

.notification-options {
    padding: 15px 15px 5px;
}

.mute {
    margin: 15px 0 3px;
}

.mute-for, .unmute {
    display: inline-block;
    padding: 2px 8px;
    border: 1px solid $primary;
    border-radius: 1px;
    margin-left: 8px;
    text-decoration: none;

    &:active {
        background-color: $primary;
        color: #fff;
    }
}

.push-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .7);
}

.mute-options-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & > div {
        margin-bottom: 5px;
    }
}
</style>
