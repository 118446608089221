<template>
    <layout-heading size="full" no-messages>
        <portal to="title">
            <img src="../../../../assets/images/freelance-market-place.svg" alt="Freelance Market Place" id="freelance-logo">
        </portal>

        <template #heading-cta>
            <h5>Open Tasks for all Freelance contracts you have access to</h5>
        </template>

        <div v-if="loading" class="loading">
            <ProgressCircle/>
        </div>
        <div v-else-if="cores.length === 0" class="no-cores">
            <h2>No Boards Found</h2>
            <p>
                You currently don't have access to any Freelance boards.
                <a href="http://t.me/Blockwell" target="_blank">Contact Us</a> to get started.
            </p>
        </div>
        <template v-else>
            <div class="viewmode-wrap">
                <label>View Mode</label>
                <div class="btn-group btn-group-toggle btn-group-sm">
                    <button class="btn btn-sm"
                            :class="viewMode === 'grid' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="viewMode = 'grid'">
                        Grid
                    </button>
                    <button class="btn btn-sm"
                            :class="viewMode === 'combined' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="viewMode = 'combined'">
                        Combined
                    </button>
                </div>
            </div>

            <div v-if="viewMode === 'grid'" class="freelance-contracts">
                <FreelanceContract
                    v-for="it in cores"
                    :core="it.core"
                    :tasks="it.tasks"
                    :key="it.core.contractId"
                    @task-click="taskClick"
                />
            </div>
            <CoreTasks v-else
                       :tasks="tasks"
                       show-contract-name
                       @task-click="taskClick"/>
        </template>

        <Modal :show="!!openTask" @closed="taskClosed">
            <TaskDetail
                v-if="openTask"
                :task="openTask"/>
        </Modal>
    </layout-heading>
</template>

<script>
import Modal from "@/components/Modal";
import ProgressCircle from "@/components/ProgressCircle";
import CoreTasks from "@/views/happs/core/CoreTasks";
import FreelanceContract from "@/views/happs/core/freelance/FreelanceContract";
import {loadFreelanceCores, loadFreelanceTasks} from "@/views/happs/core/freelance/freelancelib";
import LatestTasksMixin from "@/views/happs/core/LatestTasksMixin";
import {CoreAndTasks, CoreData, TaskData} from "@/views/happs/core/lib/CoreData";
import TaskDetail from "@/views/happs/core/TaskDetail";
import equal from "fast-deep-equal";
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import {clone} from 'rambdax';
import * as interval from "@/lib/interval";

export default {
    components: {ProgressCircle, CoreTasks, TaskDetail, Modal, FreelanceContract},
    mixins: [LatestTasksMixin],
    props: {
        view: String,
        contract: String,
        taskId: String
    },
    data() {
        return {
            loading: true,
            cores: [],
            previousStates: [],
            interval: null,
            viewMode: "grid"
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('user', ['api']),
        openTask() {
            if (this.view === 'task' && this.contract && this.taskId) {
                let core = this.cores.find(it => it.core.address.toLowerCase() === this.contract.toLowerCase());
                if (core) {
                    let id = parseInt(this.taskId);

                    if (!isNaN(id)) {
                        return core.tasks.find(it => it.id === id);
                    }
                }
            }
            return null;
        },
        tasks() {
            let tasks = this.cores.flatMap(it => it.tasks);
            tasks.sort((a, b) => {
                return a.timestamp.diff(b.timestamp);
            });
            return tasks;
        }
    },
    watch: {
        cores: {
            deep: true,
            handler(val) {
                for (let i = 0; i < val.length; i++) {
                    let state = val[i].core.state;
                    if (!equal(this.previousStates[i], state) && !equal(state, {
                        planning: {
                            columns: [],
                            tasks: []
                        }
                    })) {
                        this.api.saveContractState(val[i].core.contractId, state);
                        this.previousStates[i] = Object.freeze(clone(state));
                    }
                }
            }
        }
    },
    methods: {
        loadData() {
            return loadFreelanceCores(this.account)
                .then(async cores => {
                    let [dynamics, data] = await Promise.all([
                        loadFreelanceTasks(cores, this.account),
                        this.api.getCoreData(cores.map(it => it.contractId))
                    ]);
                    let previousStates = [];
                    let list = [];
                    for (let i = 0; i < cores.length; i++) {
                        let core = cores[i];
                        let dyn = dynamics[i];
                        let codes = data[i].codes;
                        let state = data[i].state || {
                            planning: {
                                columns: [],
                                tasks: []
                            }
                        };
                        let encryptionKey = data[i].encryptionKey;

                        core.colorIndex = i + 1;
                        core.account = this.account;
                        Object.assign(core.core, dyn.core);
                        Object.assign(core.ext, dyn.ext);
                        core.codes = codes;
                        core.state = state;
                        previousStates.push(Object.freeze(clone(state)));

                        let periodEnds = moment.unix(core.core.periodStart + core.core.periodLength);
                        core.periodEnds = periodEnds;
                        core.endingSoon = periodEnds.clone().subtract(48, 'hours').isBefore();
                        core.endsInHours = periodEnds.diff(moment(), 'hours');
                        core.latestTask = state.latestTask;
                        core.encryptionKey = encryptionKey;
                        list.push(new CoreAndTasks(core, dyn.tasks));
                    }

                    this.previousStates = previousStates;
                    this.cores = list;

                    this.interval = interval.every(() => {
                        this.updateData();
                    }, 15000);
                })
                .finally(() => this.loading = false)
                .catch(console.error);
        },
        updateData() {
            loadFreelanceTasks(this.cores.map(it => it.core), this.account)
                .then((dynamics) => {
                    for (let i = 0; i < this.cores.length; i++) {
                        let it = this.cores[i];
                        let dyn = dynamics[i];

                        Object.assign(it.core.core, dyn.core);
                        Object.assign(it.core.ext, dyn.ext);
                        it.tasks = dyn.tasks;

                        let periodEnds = moment.unix(it.core.core.periodStart + it.core.core.periodLength);
                        it.core.periodEnds = periodEnds;
                        it.core.endingSoon = periodEnds.clone().subtract(48, 'hours').isBefore();
                        it.core.endsInHours = periodEnds.diff(moment(), 'hours');
                    }
                })
                .catch(console.error);
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        },
        taskClosed() {
            this.$router.push('/freelance');
        },
        taskClick(task) {
            this.$router.push(`/freelance/task/${task.core.address}/${task.id}`);
            if (task.id > task.core.latestTask && !task.core.viewedTasks.includes(task.id)) {
                task.core.viewedTasks.push(task.id);
            }
        }
    },
    created() {
        this.loadData();
    },
    beforeDestroy() {
        this.clear();
    }
}
</script>

<style>
.mobile-nav-button #freelance-logo {
    height: 32px;
}

.heading-title #freelance-logo {
    height: 80px;
}
</style>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.loading {
    padding: 50px 0;
}

.no-cores {
    text-align: center;
    margin-top: 50px;
}

.freelance-contracts {
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 15px;
    row-gap: 15px;

    ::v-deep( .freelance-contract ) {
        flex: 1 0 45%;
    }
}

.viewmode-wrap {
    text-align: center;
    margin-top: 10px;

    > ::v-deep(label ){
        margin-right: 10px;
    }
}

</style>
