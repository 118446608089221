import bookAbi from "@/assets/data/BaseBook.abi.json";
import abi from "@/assets/data/CoreToken.abi.json";
import freelanceAbi from "@/assets/data/FreelanceExtension.abi.json";
import Dapp from "@/assets/lib/Dapp";
import { Batcher } from "@/lib/eth/Batcher";
import {
    CoreContractData,
    CoreData,
    EffortData,
    FreelanceData,
    TaskData,
} from "@/views/happs/core/lib/CoreData";
import {
    batchEfforts,
    batchTasks,
    batchTasksDifference,
    processCoreResponses,
} from "@/views/happs/core/lib/corelib";
import { Chain } from "@blockwell/chain-client";
import { getChain } from "@blockwell/chains";
import equal from "fast-deep-equal";
import * as R from "rambdax";
import { anyTrue } from "rambdax";

/**
 *
 * @param {string} account
 * @return {Promise<CoreData[]>}
 */
export async function loadFreelanceCores(account) {
    let list = JSON.parse(
        await Chain.readString(
            5,
            "0x8ef2BBCd7415aD627752ED66Bddf952d1f0b899D",
            bookAbi,
            "get",
            ["Contracts", "list"],
            120000
        )
    );

    // partition the list by network first
    let networks = {};
    for (let contract of list) {
        let net = getChain(contract.network);
        let network = networks[net.chainId];
        if (!network) {
            network = {
                net,
                contracts: [],
            };
            networks[net.networkId] = network;
        }
        network.contracts.push(contract);
    }

    // Process each network in its own async function
    let cores = (
        await Promise.all(
            Object.values(networks).map(async (network) => {
                const web3 = Dapp.getNetwork(network.net.networkId).web3;
                let batcher = new Batcher(web3);

                // First get the basic info for the Core contract
                for (let it of network.contracts) {
                    let batch = batcher.subBatcher(CoreContractData);
                    batch
                        .setContract(abi, it.address)
                        .add("name")
                        .addInt("decimals")
                        .addInt("periodLength")
                        .add("symbol")
                        .addBig("balanceOf", "balance", [account])
                        .add("extensions", "tasks", [0]);
                }

                let res = await batcher.execute();
                let datas = res.subs;
                datas = datas.filter((it) => it.balance.gt(0));

                if (datas.length === 0) {
                    return [];
                }

                batcher = new Batcher(web3);

                // Then get the data for the Freelance extensions
                for (let it of datas) {
                    let batch = batcher.subBatcher(FreelanceData);
                    batch
                        .setContract(freelanceAbi, it.tasks)
                        .addInt("getActiveTasks", "activeTasks")
                        .addBig("contestPercentage")
                        .addInt("contestPeriods")
                        .addInt("taskPeriodLimit")
                        .addInt("taskCount")
                        .addBig("approvalReward")
                        .addBig("approvalPenalty")
                        .addBig("minimumApproveBalance")
                        .addBig("minimumCommentRequestBalance")
                        .addBig("commentRequestReward")
                        .addBig("rejectTaskThreshold")
                        .addBig("minimumCreateEffortBalance")
                        .addBig("approvalThreshold");
                }

                res = await batcher.execute();
                let freelances = res.subs;

                // Combine the data into a list of CoreData objects
                let cores = [];
                for (let i = 0; i < datas.length; i++) {
                    cores.push(
                        new CoreData(
                            "CoreFreelanceExtension",
                            datas[i],
                            freelances[i],
                            network.contracts[i].address,
                            network.net.networkId,
                            network.contracts[i].contractId
                        )
                    );
                }
                return cores;
            })
        )
    ).flat();

    return cores;
}

/**
 *
 * @param {CoreData[]} cores
 * @param {string} account
 * @return {Promise<CoreDynamic[]>}
 */
export async function loadFreelanceTasks(cores, account) {
    let networks = {};
    for (let core of cores) {
        let net = getChain(core.network);
        let network = networks[net.networkId];
        if (!network) {
            network = {
                net,
                cores: [],
            };
            networks[net.networkId] = network;
        }
        network.cores.push(core);
    }

    let updates = (
        await Promise.all(
            Object.values(networks).map(async (network) => {
                const web3 = Dapp.getNetwork(network.net.networkId).web3;
                let batcher = new Batcher(web3);

                for (let core of network.cores) {
                    let batch = batcher.subBatcher(CoreContractData);
                    batchTasks(
                        batch,
                        core,
                        new web3.eth.Contract(abi, core.address),
                        new web3.eth.Contract(freelanceAbi, core.core.tasks),
                        account
                    );
                }

                let res = (await batcher.execute()).subs;

                batcher = new Batcher(web3);
                let diffs = [];
                for (let i = 0; i < res.length; i++) {
                    let tasks = res[i].tasklist;
                    let activeTasks = res[i].activeTasks;
                    let core = network.cores[i];

                    if (!equal(core.ext.activeTasks, activeTasks)) {
                        let batch = batcher.subBatcher(TaskData);
                        batch.setContract(freelanceAbi, core.core.tasks);
                        batchTasksDifference(batch, tasks, activeTasks, core, account);
                        diffs.push(true);
                    } else {
                        diffs.push(false);
                    }
                }

                if (anyTrue(...diffs)) {
                    let taskLists = (await batcher.execute()).subs.map((it) => it.tasklist);

                    for (let i = 0; i < diffs.length; i++) {
                        if (diffs[i]) {
                            let activeTasks = res[i].activeTasks;
                            let core = network.cores[i];

                            res[i].tasklist = res[i].tasklist.concat(taskLists.shift());

                            let remove = R.difference(core.ext.activeTasks, activeTasks);

                            if (remove.length > 0) {
                                res[i].tasklist = res[i].tasklist.filter(
                                    (it) => !remove.includes(it.id)
                                );
                            }
                        }
                    }
                }

                batcher = new Batcher(web3);
                for (let i = 0; i < res.length; i++) {
                    let core = network.cores[i];
                    let batch = batcher.subBatcher(EffortData);
                    batch.setContract(freelanceAbi, core.core.tasks);
                    batchEfforts(batch, res[i].tasklist);
                }

                let effortLists = (await batcher.execute()).subs.map((it) => it.efforts);

                let cores = [];
                for (let i = 0; i < res.length; i++) {
                    let tasks = res[i].tasklist;
                    let core = network.cores[i];

                    cores.push(processCoreResponses(core, res[i], tasks, effortLists[i], account));
                }

                return cores;
            })
        )
    ).flat();

    return updates;
}
