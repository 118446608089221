<template>
    <div class="core-effort">
        <div class="effort-title">
            <div>Created by</div>

            <Address :address="effort.creator" :network="network" />

            <span class="effort-status" :class="statusClass">
                {{ statusText }}
            </span>
        </div>

        <EffortParticipants
            :effort="effort"
            :network="network"
        />

        <EffortActions
            :core="core"
            :task="task"
            :effort="effort"
        />
        <div class="effort-notice">
            <div v-if="failDate">
                This effort will fail if not completed and approved by {{ failDate }}.
            </div>
        </div>

        <div class="effort-comments">
            <i class="material-icons section-icon">comment</i>
            <h6>Comments</h6>
            <div class="add-comment">
                <MarkdownEditor min-height="30" :content.sync="commentText"
                    :toolbar="['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                            'link', 'image', 'preview', 'guide']"
                />
                <small v-if="!core.encryptionKey" class="error">This comment will
                    <em>not</em> be encrypted because you don't have any {{ core.core.symbol }} tokens.
                </small>
                <div class="add-comment-button">
                    <DumbappPopover
                        label="Add Comment"
                        :instance="core.codes.comment"
                        :disabled="commentDisabled"
                        :values="commentQuery"
                        @submitted="commentSubmitted"
                    />
                </div>
            </div>
            <template v-if="pendingComments">
                <div v-for="comment in pendingComments" class="effort-comment pending-comment">
                    <div class="effort-comment-header">
                        Your comment is pending
                        <ProgressCircle tiny />
                    </div>
                    <div class="comment-text">
                        <Markdown :content="comment.text"/>
                    </div>
                </div>
            </template>
            <template v-if="commentList">
                <Comment v-for="comment in commentList"
                         :key="comment.id"
                         :comment="comment"
                         :unread="comment.id >= lastRead"
                         :network="network"
                         :core="core"/>
            </template>
            <div v-else>
                No comments yet.
            </div>
        </div>
    </div>
</template>

<script>
import Address from "@/components/Address";
import AddressBlockie from "@/components/AddressBlockie";
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import { resolver } from "@blockwell/eth-types";
import Comment from "./Comment";
import EffortActions from "./EffortActions";
import EffortParticipants from "./EffortParticipants";
import {effortStatuses, taskStatuses} from "./lib/corelib";
import WatchingMixin from "./WatchingMixin";
import moment from "moment";
import * as R from 'rambdax';
import {mapActions, mapGetters} from "vuex";

const crypt = new AesCrypt();

export default {
    name: "Effort",
    components: {
        ProgressCircle,
        EffortParticipants,
        Address,
        Comment,
        EffortActions, AddressBlockie, TokenAmount, DumbappPopover, MarkdownEditor, Markdown, Clipboard
    },
    mixins: [WatchingMixin],
    props: {
        task: Object,
        effort: Object,
        core: Object,
        icon: String,
        network: [Number, String],
        lastRead: Number
    },
    data() {
        return {
            commentText: ""
        }
    },
    computed: {
        ...mapGetters('dumbapp', ['tracking']),
        commentList() {
            if (this.effort.comments?.length > 0) {
                return R.reverse(this.effort.comments);
            }
            return null;
        },
        commentDisabled() {
            return !this.commentText;
        },
        commentQuery() {
            let text = {
                text: this.commentText
            }

            if (this.core.encryptionKey) {
                text = {
                    encryption: "aes",
                    text: crypt.encrypt(text.text, {password: this.core.encryptionKey})
                }
            }

            return {
                taskId: this.task?.id?.toString(),
                effortId: this.effort?.id?.toString(),
                text: JSON.stringify(text)
            }
        },
        statusText() {
            return effortStatuses[this.effort?.status];
        },
        statusClass() {
            let name;
            if (this.effort.status) {
                name = this.effort.status;
            } else {
                name = "unknown";
            }
            return `status-${name}`;
        },
        failDate() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (this.effort.status === 'open' || this.effort.status === 'completed') {
                let timestamp = core.core.periodStart + core.core.periodLength * (core.ext.taskPeriodLimit + 1 + this.effort.creationPeriod - core.core.currentPeriod);
                return moment.unix(timestamp).format('lll');
            }
            return null;
        },
        pendingComments() {
            let tracks = this.tracking[['core-comment', this.core.network, this.core.address, this.task.id, this.effort.id].join('-')];
            /**
             * @type {CommentData[]}
             */
            let comments = this.commentList || [];

            if (tracks && tracks.length > 0) {
                let filtered = tracks.filter(it => {
                    if (it.status === "pending") {
                        return true;
                    }

                    let event = it.submission.steps[0].events?.find(it => it.event === "Commented");
                    if (event) {
                        let commentId = parseInt(resolver(event.params).string("commentId"));
                        return !comments.find(it => it.id === commentId);
                    }
                });
                if (filtered.length > 0) {
                    return filtered;
                }
            }
            return null;
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        commentSubmitted(submission) {
            let text = this.commentText;
            this.commentText = '';
            this.trackSubmission({
                key: ['core-comment', this.core.network, this.core.address, this.task.id, this.effort.id].join('-'),
                data: {
                    id: submission.id,
                    text
                }
            });

            if (!this.isWatching) {
                this.toggleWatch();
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.effort-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .effort-id {
        font-weight: 600;
    }

    ::v-deep( .wallet-address ) {
        background-color: #fff;
        border-color: #fff;
    }
}
.effort-actions {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.effort-notice {
    margin: 10px 0 30px;
}


.add-comment {
    margin-bottom: 15px;


    ::v-deep( .editor-toolbar ) {

        background: #efefef;
        font-size: 0.8em;
        padding: 0;

        &::before, &::after {
            content: none;
        }
    }
}

.add-comment-button {
    display: flex;
    align-items: center;
}

.markdown-help {
    margin-left: 10px;
    font-size: 14px;
    color: $secondary;

    ::v-deep(a:hover) {
        text-decoration: none;
    }
}

.effort-status {
    margin-left: 5px;
    display: inline-block;
    background: #616161;
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
}

.status-open {
    background: #13315C;
}

.status-completed {
    background: #efae00;
}

.status-approved {
    background: $success;
}

.status-invalid {
    background: $danger;
}

.approvals {
    ::v-deep( .label ) {
        display: inline-block;
        width: 120px;
    }
}

.action-notice {
    color: $secondary;
}

.pending-comment {

    .effort-comment-header {
        display: flex;

        ::v-deep( .progress-circular ) {
            margin-left: 5px;
        }
    }
    .comment-text {
        background-color: rgba($dark, 0.05);
    }
}
</style>
