<template>
    <div class="comment-actions">
        <div class="comment-actions-header">Actions</div>
        <div v-if="effort.approvals.gt(0)" class="approvals">
            <div>
                <span class="label">Total Approved</span>
                <TokenAmount :value="effort.approvals" :decimals="core.core.decimals"/>
            </div>
            <div v-if="effort.contests.gt(0)">
                <span class="label">Total Contested</span>
                <TokenAmount :value="effort.contests" :decimals="core.core.decimals"/>
            </div>
        </div>
        <DumbappPopover
            v-if="showApprove"
            label="Approve"
            :instance="core.codes.approveEffort"
            :values="completeQuery"
            :show="openApprove"
            button-class="btn-link"
        />
        <DumbappPopover
            v-if="showContest"
            label="Contest Approval"
            :instance="core.codes.contestEffort"
            :values="completeQuery"
            button-class="btn-link"
        />
        <div class="action-notice">
            <div v-if="contestFailing" class="error">
                This Effort is being contested, but
                <TokenAmount :value="contestFailing" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                more
                tokens are needed to contest successfully.
            </div>
            <div v-else-if="contestSucceeding" class="error">
                This Effort is being contested successfully, it will be invalidated at the end of the period if
                approvals are not increased.
            </div>
            <div v-if="showApprove" class="approval-reward">
                <template v-if="core.freelance()">
                    You will earn
                    <TokenAmount :value="core.ext.approvalReward" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                    if you Approve.
                </template>
                <template v-else>
                    You can earn up to
                    <TokenAmount :value="approvalReward" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                    if you Approve.
                </template>
            </div>
            <div v-if="showApprove && notEnoughToApprove">
                <strong>Note!</strong> You do not have enough tokens to fully approve this effort.
                You have
                <TokenAmount :value="core.core.balance" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                and a total of
                <TokenAmount :value="tokensForApproval" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                is needed to fully approve.
            </div>
            <div v-if="showContest && notEnoughToContest">
                <strong>Note!</strong> You do not have enough tokens to fully contest this effort.
                You have
                <TokenAmount :value="core.core.balance" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                and a total of
                <TokenAmount :value="tokensForContesting" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                is needed to fully contest.
            </div>
            <div v-if="canBeContested && isContester">
                You contested this effort.
            </div>
            <div v-else-if="canBeApproved && isCollaborator">
                You cannot approve because you are a collaborator.
            </div>
            <div v-else-if="canBeApproved && isTaskCreator">
                You cannot approve because you created the task.
            </div>
            <div v-if="canBeApproved && isApprover">
                You approved this effort.
            </div>
        </div>
    </div>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import TokenAmount from "@/components/TokenAmount";
import {CommentData, CoreData, EffortData, TaskData} from "@/views/happs/core/lib/CoreData";

export default {
    name: "CommentActions",
    components: {TokenAmount, DumbappPopover},
    props: {
        task: TaskData,
        comment: CommentData,
        effort: EffortData,
        core: CoreData,
        openApprove: Boolean
    },
    data() {
        return {};
    },
    computed: {
        completeQuery() {
            return {
                taskId: this.task?.id?.toString(),
                effortId: this.effort?.id?.toString()
            }
        },
        isCollaborator() {
            if (this.core.account) {
                return !!this.effort?.collaborators?.find(it => it.toLowerCase() === this.core.account.toLowerCase());
            }
            return false;
        },
        isPrimaryCollaborator() {
            if (this.core.account) {
                return this.effort?.collaborators?.[0]?.toLowerCase() === this.core.account.toLowerCase();
            }
            return false;
        },
        isTaskCreator() {
            if (this.core.account) {
                return this.task.creator.toLowerCase() === this.core.account.toLowerCase();
            }
            return false;
        },
        isApprover() {
            if (this.core.account) {
                return !!this.effort?.approvers?.find(it => it.toLowerCase() === this.core.account.toLowerCase());
            }
            return false;
        },
        isContester() {
            if (this.core.account) {
                return !!this.effort?.contesters?.find(it => it.toLowerCase() === this.core.account.toLowerCase());
            }
            return false;
        },
        canBeApproved() {
            let core = this.core;
            if (this.effort.status === 'completed') {
                return true;
            }
            if (this.effort.status === 'approved') {
                return core.core.currentPeriod - core.ext.contestPeriods <= this.effort.approvalPeriod
                    && this.effort.contesters.length > 0;
            }
            return false;
        },
        showApprove() {
            if (this.isContester || this.isApprover || this.isCollaborator || this.isTaskCreator) {
                return false;
            }

            return this.canBeApproved;
        },
        canBeContested() {
            let core = this.core;
            return this.effort.status === 'approved'
                && core.core.currentPeriod - core.ext.contestPeriods <= this.effort.approvalPeriod
        },
        showContest() {
            if (this.isContester || this.isApprover) {
                return false;
            }
            return this.canBeContested;
        },
        tokensForApproval() {
            let core = this.core;

            if (core.freelance()) {
                return core.ext.approvalThreshold;
            }
            return this.task.reward.times(core.ext.approvalFactor).minus(this.effort.approvals);
        },
        notEnoughToApprove() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (!core.core.balance) {
                return false;
            }

            return core.core.balance.lt(this.tokensForApproval);
        },
        tokensForContesting() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;

            return this.effort.approvals.times(core.ext.contestPercentage).div('1e18').minus(this.effort.contests);
        },
        notEnoughToContest() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (!core.core.balance) {
                return false;
            }

            return core.core.balance.lt(this.tokensForContesting);
        },
        approvalReward() {
            if (this.core.freelance()) {
                return this.core.ext.approvalReward;
            }
            return this.core.ext.approvalReward.times(this.task.reward).div('1e18').div(this.effort.approvers.length + 1);
        },
        contestFailing() {
            if (this.effort.status === 'approved'
                && this.effort.contests.gt(0)) {
                let needed = this.tokensForContesting.minus(this.effort.contests);

                if (needed.gt(0)) {
                    return needed;
                }
            }
            return null;
        },
        contestSucceeding() {
            return this.effort.status === 'approved'
                && this.effort.contests.gte(this.tokensForContesting);
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";


.comment-actions {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.9em;
    background-color: #fff;
    padding: 10px;
}

.comment-actions-header {
    font-weight: 600;
    width: 100%;
}

.action-notice {
    color: $secondary;
    flex: 0 0 100%;

    > ::v-deep(div ){
        margin-bottom: 5px;
    }

    ::v-deep( strong ) {
        color: $danger;
    }
}

.approvals {
    width: 100%;

    ::v-deep( .label ) {
        display: inline-block;
        width: 120px;
    }
}

.approval-reward {
    ::v-deep( .token-amount ) {
        font-weight: 600;
    }
}
</style>
