<template>
    <div class="core-task-detail">
        <div class="task-section">
            <i class="material-icons section-icon">note</i>
            <template v-if="isEncrypted && !decrypted">
                <h5 class="task-title-encrypted">Encrypted</h5>
                <div v-if="account"
                     class="error">You must have {{ core.core.symbol }} tokens to view encrypted content.
                </div>
                <div v-else class="error">
                    Encrypted content requires signing in.
                    <button class="btn btn-primary btn-sm" @click="login">Sign In</button>
                </div>
            </template>
            <h5 v-else class="task-title">
                {{ decrypted || task.content.title }}
            </h5>
            <div class="task-id">
                Task #<span>{{ task.id }} </span> created by
                <Address :address="task.creator" :network="happ.network.networkId"/>
            </div>
            <div class="task-reward">
                Reward
                <TokenAmount
                    :value="task.reward"
                    :symbol="core.core.symbol"
                    :decimals="core.core.decimals"
                />
            </div>
            <div class="watch-task" :class="{watching: isWatching}">
                <button class="btn btn-sm" @click="toggleWatch"
                        :class="isWatching ? 'btn-primary' : 'btn-outline-primary'">
                    <i class="material-icons">visibility</i>
                    {{ watchingText }}
                </button>
            </div>
        </div>
        <div v-if="task.content.description" class="task-section">
            <i class="material-icons section-icon">notes</i>
            <h6>Description</h6>
            <template v-if="isEncrypted && !decrypted">
                <div class="task-title-encrypted">Encrypted</div>
            </template>
            <Markdown v-else :content="decryptedDescription || task.content.description"/>
        </div>

        <template v-if="task.perPeriod > 0">
            <div class="task-section">
                <i class="material-icons section-icon">people</i>
                <ProgressCircle v-if="!efforts && $asyncComputed.efforts.updating" small/>
                <template v-else>
                    <h6>Current Period</h6>
                    <DumbappModal
                        v-if="task.perPeriod > task.completionCount"
                        label="Add Scrum"
                        :instance="core.codes.scrumEffort"
                        :values="{taskId: task.id.toString()}"
                    />
                    <p v-else>
                        No more scrums can be completed during this period.
                    </p>
                    <template v-if="repeatingEfforts.length > 0">
                        <RepeatingEffort v-for="effort in repeatingEfforts"
                                         :key="effort.id"
                                         :core="core"
                                         :task="task"
                                         :effort="effort"
                                         :network="happ.network.networkId"/>
                    </template>
                    <div v-else>
                        No efforts added yet.
                    </div>
                </template>
            </div>
            <div class="task-section">
                <i class="material-icons section-icon">history_toggle_off</i>
                <ProgressCircle v-if="!efforts && $asyncComputed.efforts.updating" small/>
                <template v-else>
                    <h6>Previous Period</h6>
                    <template v-if="previousRepeatingEfforts.length > 0">
                        <RepeatingEffort v-for="effort in previousRepeatingEfforts"
                                         :key="effort.id"
                                         :core="core"
                                         :task="task"
                                         :effort="effort"
                                         :network="happ.network.networkId"/>
                    </template>
                    <div v-else>
                        No efforts in the previous period.
                    </div>
                </template>
            </div>
        </template>
        <div v-else class="task-section">
            <ProgressCircle v-if="!efforts && $asyncComputed.efforts.updating" small/>

            <template v-else>
                <template v-if="pendingEfforts">
                    <div v-for="effort in pendingEfforts" class="pending-effort">
                        <div class="pending-effort-heading">
                            Pending effort created by you
                            <ProgressCircle small/>
                        </div>
                        <div class="pending-effort-collaborators">
                            Collaborators
                            <Address :address="effort.assignee" :network="happ.network.networkId"/>
                        </div>
                    </div>
                </template>
                <div v-if="!efforts || efforts.length === 0">
                    No efforts to complete this task have been made. To start work on this task, create a new effort.
                </div>
                <DumbappPopover
                    v-if="showCreateEffort"
                    :instance="core.codes.createEffort"
                    :values="effortQuery"
                    :prefilled="effortPrefilled"
                    @submitted="effortSubmitted"
                    class="create-effort-button"
                    label="Create Effort"/>
                <div v-for="effort in efforts" class="effort-wrap">
                    <i class="material-icons section-icon">{{ effortsIcon }}</i>
                    <h6>Effort #{{effort.id}}</h6>
                    <Effort
                        :key="effort.id"
                        :task="task"
                        :effort="effort"
                        :core="core"
                        :last-read="lastRead[effort.id]"
                        :network="happ.network.networkId"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Address from "@/components/Address";
import DumbappModal from "@/components/DumbappModal";
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import { resolver } from "@blockwell/eth-types";
import Effort from "./Effort";
import {loadEfforts} from "./lib/corelib";
import CryptMixin from "./lib/CryptMixin";
import {FastAesCrypt} from "./lib/FastAesCrypt";
import RepeatingEffort from "./RepeatingEffort";
import WatchingMixin from "./WatchingMixin";
import {mapActions, mapGetters, mapState} from "vuex";
import * as R from 'rambdax';

export default {
    name: "TaskDetail",
    components: {Address, RepeatingEffort, DumbappModal, Effort, DumbappPopover, ProgressCircle, Markdown, TokenAmount},
    mixins: [WatchingMixin, CryptMixin],
    props: {
        task: Object,
        core: Object,
        happ: Object
    },
    data() {
        return {
            lastRead: {}
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('dumbapp', ['tracking']),
        watchingText() {
            if (this.isWatching) {
                return "Watching Task";
            }
            return "Watch This Task";
        },
        effortsIcon() {
            if (this.efforts?.length > 1) {
                return 'groups'
            }
            return 'person';
        },
        effortQuery() {
            return {
                taskId: this.task?.id?.toString()
            }
        },
        effortPrefilled() {
            if (this.account) {
                return {assignee: this.account};
            }
            return {};
        },
        repeatingEfforts() {
            /**
             * @type {CoreData}
             */
            let core = this.core;

            if (this.efforts && this.task.perPeriod > 0) {
                return this.efforts.filter(it => {
                    return it.creationPeriod === core.core.currentPeriod;
                });
            }
            return [];
        },
        previousRepeatingEfforts() {
            /**
             * @type {CoreData}
             */
            let core = this.core;

            if (this.efforts && this.task.perPeriod > 0) {
                return this.efforts.filter(it => {
                    return it.creationPeriod === core.core.currentPeriod - 1;
                });
            }
            return [];
        },
        isEncrypted() {
            return this.task?.content?.encryption;
        },
        effortTrackingKey() {
            return ['core-effort', this.happ.network.networkId, this.happ.address, this.task.id].join('-');
        },
        pendingEfforts() {
            let tracks = this.tracking[this.effortTrackingKey];
            /**
             *
             * @type {EffortData[]}
             */
            let efforts = this.efforts || [];

            if (tracks && tracks.length > 0) {
                let filtered = tracks.filter(it => {
                    if (it.status === "pending") {
                        return true;
                    }

                    let event = it.submission.steps[0].events?.find(it => it.event === "EffortCreated");
                    if (event) {
                        let effortId = parseInt(resolver(event.params).string("effortId"));
                        return !efforts.find(it => it.id === effortId);
                    }
                });
                if (filtered.length > 0) {
                    return filtered;
                }
            }
            return null;
        },
        effortsFailed() {
            if (this.efforts?.length > 0) {
                return R.allTrue(...this.efforts.map(it => it.status === 'failed'))
            }
            return false;
        },
        showCreateEffort() {
            return !this.pendingEfforts && (this.efforts?.length === 0 || this.effortsFailed);
        }
    },
    asyncComputed: {
        async efforts() {
            if (this.happ) {
                // Make this reactive to comment count
                let count = this.task?.efforts?.[0]?.commentCount;
                return await loadEfforts(this.happ, this.core, this.task);
            }
        },
        decrypted() {
            if (this.isEncrypted && this.core.encryptionKey) {
                return this.crypt.decrypt(this.task.content.title, {password: this.core.encryptionKey});
            }
            return null;
        },
        decryptedDescription() {
            if (this.isEncrypted && this.core.encryptionKey) {
                return this.crypt.decrypt(this.task.content.description, {password: this.core.encryptionKey});
            }
            return null;
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        login() {
            this.$bus.emit('login_request', {
                message: ""
            });
        },
        effortSubmitted(submission) {
            this.trackSubmission({
                key: this.effortTrackingKey,
                data: {
                    id: submission.id,
                    assignee: submission.data.getInput("assignee")
                }
            });

            if (!this.isWatching) {
                this.toggleWatch();
            }
        }
    },
    created() {
        let state = this.core.state;

        if (!state.comments) {
            this.$set(state, 'comments', {});
        }
        if (!state.comments[this.task.id]) {
            this.$set(state.comments, this.task.id, {});
        }

        let lastRead = {};
        if (this.task.efforts && this.task.efforts.length > 0) {
            for (let effort of this.task.efforts) {
                let last = state.comments[this.task.id][effort.id];
                if (last === undefined) {
                    if (state.comments[this.task.id][0] !== undefined) {
                        last = 0;
                    } else {
                        last = effort.commentCount;
                    }
                }
                lastRead[effort.id] = last;
                this.$set(state.comments[this.task.id], effort.id, effort.commentCount);
            }
        } else {
            this.$set(state.comments[this.task.id], 0, 0);
        }
        this.lastRead = lastRead;
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.core-task-detail {
    width: 640px;

    .task-title, .task-title-encrypted {
        margin: 0;
    }

    .task-title-encrypted {
        font-style: italic;
        color: $secondary;
    }

    .task-id {
        font-size: 14px;
        color: $secondary;
        display: flex;
        align-items: center;

        span {
            color: $dark;
            margin-right: 4px;
        }

        .wallet-address {
            background-color: #fff;
            border-color: #fff;
        }
    }

    .task-section {
        padding-left: 35px;
        position: relative;
        margin-bottom: 38px;

        h6 {
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 18px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .section-icon {
            color: rgba($dark, .7);
            font-size: 24px;
            line-height: 24px;
            width: 30px;
            text-align: center;
            display: block;
            position: absolute;
            left: -2px;
        }
    }

    .markdown-body {
        overflow-wrap: break-word;
    }

    @media screen and (max-width: 720px) {
        width: auto;
    }

    .pending-effort {
        margin-bottom: 25px;

        .pending-effort-heading {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .progress-circular {
                margin-left: 10px;
            }

            .wallet-address {
                background-color: #fff;
                border-color: #fff;
            }
        }

        .pending-effort-collaborators {
            display: flex;
            align-items: center;

            .wallet-address {
                margin-left: 5px;
            }
        }
    }

    .watch-task {
        margin-top: 10px;

        .btn-outline-primary:not(:disabled):not(.disabled):active {
            color: $primary;
            background-color: #fff;
        }

        .btn-outline-primary:hover {
            color: $primary;
            background-color: rgba($primary, .1);
        }

        .material-icons {
            margin-right: 5px;
            color: $primary;
        }

        &.watching {
            .material-icons {
                color: #fff;
            }
        }
    }

    .effort-wrap {
        margin-top: 20px;

        & > h6 {
            margin-bottom: 5px;
        }
    }
}

</style>
