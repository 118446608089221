import { render, staticRenderFns } from "./Effort.vue?vue&type=template&id=270fd52e&scoped=true&"
import script from "./Effort.vue?vue&type=script&lang=js&"
export * from "./Effort.vue?vue&type=script&lang=js&"
import style0 from "./Effort.vue?vue&type=style&index=0&id=270fd52e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270fd52e",
  null
  
)

export default component.exports