<template>
    <div class="help-popover">
        <div ref="popover" class="help-popover-wrapper">
            <transition name="fade"
                        v-on:before-enter="enter"
                        v-on:before-leave="beforeLeave"
                        v-on:after-enter="afterEnter"
                        v-on:after-leave="leave">
                <div v-if="active && showing" class="help-popover-inner">
                    <div class="help-content" ref="content">
                        <slot/>

                        <div v-if="dismissKey">
                            <a class="dismiss-link" href="javascript: void 0;"
                               @click="dismissClick">don't show this again</a>
                        </div>
                    </div>
                </div>
            </transition>
            <div ref="arrow" class="help-popover-arrow-wrapper">
                <transition name="fade">
                    <div v-if="active && showing" class="help-popover-arrow"></div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import {createPopper} from '@popperjs/core';
import ResizeObserver from "resize-observer-polyfill";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "Help",
    props: {
        show: Boolean,
        reference: [Element, String, null],
        dismissKey: String
    },
    data() {
        return {
            showing: false
        };
    },
    computed: {
        ...mapGetters('help', ['activeHelp']),
        active() {
            return !this.dismissKey || this.dismissKey === this.activeHelp;
        }
    },
    methods: {
        ...mapActions('help', ['showHelp', 'dismissHelp']),
        ...mapMutations('help', ['hide_help']),
        open() {
            let ref;
            if (this.reference) {
                if (this.reference instanceof Element) {
                    ref = this.reference;
                } else {
                    ref = $(this.reference)[0];
                }
            }
            this.popper = createPopper(ref || this.$el, this.$refs.popover, {
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8]
                        }
                    },
                    {
                        name: 'arrow',
                        options: {
                            element: this.$refs.arrow
                        }
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['top', 'right', 'bottom', 'left']
                        }
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            altAxis: true,
                            padding: 10
                        }
                    }
                ]
            });

            this.$nextTick(() => {
                this.showing = true;
            });
        },
        close() {
            this.showing = false;
            if (this.dismissKey) {
                this.hide_help(this.dismissKey);
            }
        },
        enter() {
            this.$nextTick(() => {
                this.$refs.popover.setAttribute('data-show', '');
            });
            window.addEventListener("click", this.globalClick, true);
        },
        leave() {
            this.$refs.popover?.removeAttribute('data-show');
        },
        beforeLeave() {
            window.removeEventListener("click", this.globalClick, true);
        },
        afterEnter() {
            this.popper?.update();
        },
        globalClick(event) {
            if (!this.$refs.content.contains(event.target)) {
                this.close();
            }
        },
        dismissClick() {
            this.dismissHelp(this.dismissKey);
            this.close();
        }
    },
    watch: {
        showing(val) {
            this.$emit('update:show', val);
        },
        show(val, old) {
            if (!val) {
                this.close()
            } else {
                this.open();
                if (this.dismissKey) {
                    this.showHelp(this.dismissKey);
                }
            }
        }
    },
    mounted() {
        this.observer = new ResizeObserver(entries => {
            this.popper?.update();
        });

        this.observer.observe(this.$refs.popover);

        if (this.show) {
            if (this.dismissKey) {
                this.showHelp(this.dismissKey);
            }
            // This allows references to update first
            setTimeout(() => {
                this.open();
            }, 300);
        }
    },
    beforeDestroy() {
        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
        window.removeEventListener("click", this.globalClick, true);
        if (this.dismissKey) {
            this.hide_help(this.dismissKey);
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom";

.help-popover-wrapper {
    display: none;
    z-index: 10;
    max-width: calc(100vw - 20px);
    margin: 0;

    &[data-show] {
        display: block;
    }

    $placements: ("bottom": "top", "top": "bottom", "left": "right", "right": "left");

    @each $pos, $side in $placements {
        &[data-popper-placement^=#{$pos}] .help-popover-arrow-wrapper {
            #{$side}: -4px;
        }
    }
}

.help-popover-arrow-wrapper, .help-popover-arrow, .help-popover-arrow::before {
    position: absolute;
    width: 8px;
    height: 9px;
}

.help-popover-arrow {
    z-index: -1;
}

.help-popover-inner {
    background: #333;
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.3));
    color: #fff;

    max-width: 400px;
    padding: 10px;
}

.help-popover-arrow::before {
    z-index: -1;
    content: '';
    transform: rotate(45deg);
    background: #333;
}

.dismiss-link {
    color: #7297ff;
}
</style>
