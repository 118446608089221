<template>
    <div class="core-effort repeating-effort">
        <div class="effort-title">
            Effort #<span class="effort-id">{{ effort.id }}</span> created by
            <Clipboard :address="effort.creator" :network="network" ellipsize/>

            <span class="effort-status" :class="statusClass">
                {{ statusText }}
            </span>
        </div>
        <EffortParticipants
            :effort="effort"
            :network="network" />
        <EffortActions
            :effort="effort"
            :task="task"
            :core="core"/>
    </div>
</template>

<script>
import AddressBlockie from "@/components/AddressBlockie";
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import TokenAmount from "@/components/TokenAmount";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import EffortActions from "./EffortActions";
import EffortParticipants from "./EffortParticipants";
import {effortStatuses, taskStatuses} from "./lib/corelib";
import moment from "moment";
import * as R from 'rambdax';

export default {
    name: "RepeatingEffort",
    components: {
        EffortParticipants,
        EffortActions, AddressBlockie, TokenAmount, DumbappPopover, MarkdownEditor, Markdown, Clipboard},
    props: {
        task: Object,
        effort: Object,
        core: Object,
        icon: String,
        network: [Number, String]
    },
    data() {
        return {
            commentText: ""
        }
    },
    computed: {
        commentList() {
            if (this.effort.comments?.length > 0) {
                return R.reverse(this.effort.comments);
            }
            return null;
        },
        commentDisabled() {
            return !this.commentText;
        },
        commentQuery() {
            return {
                taskId: this.task?.id?.toString(),
                effortId: this.effort?.id?.toString(),
                text: this.commentText
            }
        },
        statusText() {
            return effortStatuses[this.effort?.status];
        },
        statusClass() {
            let name;
            if (this.effort.status) {
                name = this.effort.status;
            } else {
                name = "unknown";
            }
            return `status-${name}`;
        },
        showApprovers() {
            return this.effort?.approvers?.length > 0;
        },
        failDate() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (this.effort.status === 'open' || this.effort.status === 'completed') {
                let timestamp = core.core.periodStart + core.core.periodLength * (core.ext.taskPeriodLimit + 1 + this.effort.creationPeriod - core.core.currentPeriod);
                return moment.unix(timestamp).format('lll');
            }
            return null;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.repeating-effort {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.repeating-effort:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.effort-title {
    ::v-deep( a ) {
        color: $secondary;
    }

    .effort-id {
        font-weight: 600;
    }
}

.effort-collaborators {
    margin-top: 7px;
    display: flex;
}

.effort-notice {
    margin: 10px 0 30px;
}

.collaborators-label {
    padding-top: 6px;
}

.collaborator {
    margin-left: 5px;
    margin-top: 2px;
    background: rgba($dark, 0.08);
    border-radius: 3px;
    padding: 4px 8px;

    ::v-deep( a ) {
        display: block;
        color: $secondary;
        font-size: 14px;
        line-height: 14px;
    }
}

.effort-approvers .collaborator {
    background: rgba($success, 0.15);
}

.effort-contesters .collaborator {
    background: rgba($danger, 0.1);
}

.effort-status {
    margin-left: 5px;
    display: inline-block;
    background: #616161;
    color: #fff;
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 20px;
}

.status-open {
    background: #13315C;
}

.status-completed {
    background: #efae00;
}

.status-approved {
    background: $success;
}

.status-invalid {
    background: $danger;
}

</style>
