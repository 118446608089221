<template>
    <div class="core-column" :class="{horizontal}">
        <a v-if="showNew" href="#" class="create-task" @click.prevent="$emit('create')">+ create task</a>
        <h3 class="core-column-title">
            {{ title }}
        </h3>
        <template v-if="pendingTasks">
            <div v-for="task in pendingTasks" class="core-task pending-task">
                <div class="task-id">
                    Pending Task
                    <ProgressCircle tiny/>
                </div>
                <div class="task-reward">
                    <TokenAmount
                        :value="task.reward"
                        :symbol="core.core.symbol"
                        :decimals="core.core.decimals"
                    />
                </div>
                <div class="task-title">
                    {{ task.title }}
                </div>
            </div>
        </template>
        <Task v-for="task in tasks" v-if="showTask(task)" :key="task.id" :core="core" :task="task"
              @click="taskClick(task)"
        />
        <button v-if="showNew" class="btn btn-sm btn-primary" @click="$emit('create')">Create Task</button>
    </div>
</template>

<script>
import DumbappModal from "@/components/DumbappModal";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import { resolver } from "@blockwell/eth-types";
import {CoreData} from "./lib/CoreData";
import Task from "./Task";
import {mapGetters, mapState} from "vuex";
import * as R from 'rambdax';

export default {
    name: "CoreColumn",
    components: {TokenAmount, ProgressCircle, DumbappModal, Task},
    props: {
        title: String,
        tasks: Array,
        core: CoreData,
        horizontal: Boolean,
        filter: String
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('dumbapp', ['tracking']),
        showNew() {
            return !!this.$listeners.create;
        },
        pendingTasks() {
            if (!this.showNew) {
                return null;
            }

            let tracks = this.tracking[['core-task', this.core.network, this.core.address].join('-')];

            let tasks = this.core.tasks;

            if (tasks && tasks.length > 0 && tracks && tracks.length > 0) {
                let filtered = tracks.filter(it => {
                    if (it.status === "pending") {
                        return true;
                    }

                    let event = it.submission.steps[0].events?.find(it => it.event === "TaskCreated");
                    if (event) {
                        let taskId = parseInt(resolver(event.params).string("taskId"));
                        return !tasks.find(it => it.id === taskId);
                    }
                });
                if (filtered.length > 0) {
                    return filtered;
                }
            }
            return null;
        }
    },
    methods: {
        taskClick(task) {
            this.$emit('task-click', task)
        },
        showTask(task) {
            switch (this.filter) {
                case 'collaborator':
                    return task.yourTask;
                case 'watching':
                    return this.core.state?.comments?.[task.id]?.watch;
                case 'creator':
                    return task.creator.toLowerCase() === this.account.toLowerCase()
                default:
                    return true;
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.core-column {
    flex: 0 0 300px;
    max-width: 300px;
    border-right: 1px solid rgba($primary, .2);
    padding: 16px 16px;
    min-height: 200px;

    &.horizontal {
        min-height: auto;
        display: flex;
        border-bottom: 1px solid rgba($primary, .2);
        border-right: none;
        padding: 10px;
        max-width: none;

        .core-column-title {
            padding-right: 10px;
        }

        ::v-deep( .core-task ) {
            max-width: 230px;
        }
    }
}

.core-column-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.create-task {
    float: right;
}

.pending-task {
    background: rgba($dark, 0.05);
    cursor: auto;

    &:hover {
        border-color: transparent;
        background: rgba($dark, 0.05);
    }

    .task-id {
        display: flex;

        ::v-deep( .progress-circular ) {
            margin-left: 5px;
            margin-top: -3px;
        }
    }
}
</style>
