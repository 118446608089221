<template>
    <div class="effort-actions">
        <div v-if="effort.approvals.gt(0)" class="approvals">
            <div>
                <span class="label">Total Approved</span>
                <TokenAmount :value="effort.approvals" :decimals="core.core.decimals"/>
            </div>
            <div v-if="effort.contests.gt(0)">
                <span class="label">Total Contested</span>
                <TokenAmount :value="effort.contests" :decimals="core.core.decimals"/>
            </div>
        </div>
        <DumbappPopover
            v-if="showComplete"
            label="Mark As Completed"
            :instance="core.codes.completeEffort"
            :values="completeQuery"
            button-class="btn-link"
        />
        <DumbappPopover
            v-if="canAddCollaborators"
            label="Add Collaborators"
            :instance="core.codes.multiAddCollaborators"
            :values="completeQuery"
            button-class="btn-link"
        />
        <DumbappPopover
            v-if="showApprove"
            label="Approve"
            :instance="core.codes.approveEffort"
            :values="completeQuery"
            button-class="btn-link"
        />
        <DumbappPopover
            v-if="showContest"
            label="Contest Approval"
            :instance="core.codes.contestEffort"
            :values="completeQuery"
            button-class="btn-link"
        />
        <div class="action-notice">
            <div v-if="showApprove && notEnoughToApprove">
                <strong>Note!</strong> You do not have enough tokens to fully approve this effort.
                You have <TokenAmount :value="core.core.balance" :decimals="core.core.decimals" :symbol="core.core.symbol" />
                and a total of
                <TokenAmount :value="tokensForApproval" :decimals="core.core.decimals" :symbol="core.core.symbol" />
                is needed to fully approve.
            </div>
            <div v-if="showContest && notEnoughToContest">
                <strong>Note!</strong> You do not have enough tokens to fully contest this effort.
                You have <TokenAmount :value="core.core.balance" :decimals="core.core.decimals" :symbol="core.core.symbol" />
                and a total of
                <TokenAmount :value="tokensForContesting" :decimals="core.core.decimals" :symbol="core.core.symbol" />
                is needed to fully contest.
            </div>
            <div v-if="canBeCompleted && !isCollaborator">
                One of the collaborators can mark the task completed.
            </div>
            <div v-if="canBeApproved && isCollaborator">
                You cannot approve because you are a collaborator.
            </div>
            <div v-if="canBeApproved && isTaskCreator">
                You cannot approve because you created the task.
            </div>
            <div v-if="canBeContested && isContester">
                You contested this effort.
            </div>
            <div v-if="canBeApproved && isApprover">
                You approved this effort.
            </div>
        </div>
    </div>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import TokenAmount from "@/components/TokenAmount";
export default {
    name: "EffortActions",
    components: {TokenAmount, DumbappPopover},
    props: {
        task: Object,
        effort: Object,
        core: Object
    },
    data() {
        return {};
    },
    computed: {
        completeQuery() {
            return {
                taskId: this.task?.id?.toString(),
                effortId: this.effort?.id?.toString()
            }
        },
        isCollaborator() {
            if (this.core.account) {
                return !!this.effort?.collaborators?.find(it => it.toLowerCase() === this.core.account.toLowerCase());
            }
            return false;
        },
        isPrimaryCollaborator() {
            if (this.core.account) {
                return this.effort?.collaborators?.[0]?.toLowerCase() === this.core.account.toLowerCase();
            }
            return false;
        },
        isTaskCreator() {
            if (this.core.account) {
                return this.task.creator.toLowerCase() === this.core.account.toLowerCase();
            }
            return false;
        },
        isApprover() {
            if (this.core.account) {
                return !!this.effort?.approvers?.find(it => it.toLowerCase() === this.core.account.toLowerCase());
            }
            return false;
        },
        isContester() {
            if (this.core.account) {
                return !!this.effort?.contesters?.find(it => it.toLowerCase() === this.core.account.toLowerCase());
            }
            return false;
        },
        canBeCompleted() {
            return this.effort.status === 'open';
        },
        showComplete() {
            return this.isCollaborator && this.canBeCompleted;
        },
        canAddCollaborators() {
            return this.effort.status === 'open' && this.isPrimaryCollaborator;
        },
        canBeApproved() {
            let core = this.core;
            if (this.effort.status === 'completed') {
                return true;
            }
            if (this.effort.status === 'approved') {
                return core.core.currentPeriod - core.ext.contestPeriods <= this.effort.approvalPeriod;
            }
            return false;
        },
        showApprove() {
            if (this.isContester || this.isApprover || this.isCollaborator || this.isTaskCreator) {
                return false;
            }

            return this.canBeApproved;
        },
        canBeContested() {
            let core = this.core;
            return this.effort.status === 'approved'
                && core.core.currentPeriod - core.ext.contestPeriods <= this.effort.approvalPeriod
        },
        showContest() {
            if (this.isContester || this.isApprover) {
                return false;
            }
            return this.canBeContested;
        },
        tokensForApproval() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;

            return this.task.reward.times(core.ext.approvalFactor).minus(this.effort.approvals);
        },
        notEnoughToApprove() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (!core.core.balance) {
                return false;
            }

            return core.core.balance.lt(this.tokensForApproval);
        },
        tokensForContesting() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;

            return this.effort.approvals.times(core.ext.contestPercentage).div('1e18').minus(this.effort.contests);
        },
        notEnoughToContest() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (!core.core.balance) {
                return false;
            }

            return core.core.balance.lt(this.tokensForContesting);
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.effort-actions {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.action-notice {
    color: $secondary;
    flex: 0 0 100%;
    margin-top: 5px;

    > ::v-deep(div) {
        margin-bottom: 5px;
    }

    ::v-deep( strong ) {
        color: $danger;
    }
}

.approvals {
    ::v-deep( .label ) {
        display: inline-block;
        width: 120px;
    }
}
</style>
