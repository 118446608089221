import {getTokenData} from "@/lib/eth/Token";

export default {
    data() {
        return {
            tokenData: {
                symbol: "",
                decimals: 18,
                name: ""
            }
        }
    },
    watch: {
        'task.rewardToken': {
            immediate: true,
            handler(val) {
                if (!val) {
                    this.tokenData.decimals = this.core.core.decimals;
                    this.tokenData.symbol = this.core.core.symbol;
                } else {
                    getTokenData(this.core.network, val)
                        .then(res => {
                            this.tokenData.decimals = res[0].decimals;
                            this.tokenData.symbol = res[0].symbol;
                            this.tokenData.name = res[0].name;
                        });
                }
            }
        }
    },
}
