<template>
    <div class="core-details-wrap">
        <h3>How Core Works</h3>
        <p>
            The Core token ({{ core.core.name }}) controls your access to tasks. Different token amounts
            are required for different kinds of access.
        </p>
        <p>Based on your balance of
            <strong>
                <TokenAmount
                    :value="core.core.balance"
                    :decimals="core.core.decimals"
                    :symbol="core.core.symbol"
                />
            </strong>:
        </p>
        <ul v-if="core.core.balance && core.ext">
            <li>
                <template v-if="core.core.balance.gt(0)">
                    You can view all tasks.
                </template>
                <template v-else-if="!core.freelance()">
                    You can only view public tasks. Viewing secure tasks requires having a token balance.
                </template>
                <template v-else>
                    You cannot view tasks, a token balance is required.
                </template>
            </li>
            <li>
                <template v-if="core.core.balance.gt(0)">
                    You can post secure comments.
                </template>
                <template v-else-if="!core.freelance()">
                    You can only post public comments. Secure comments require having a token balance.
                </template>
                <template v-else>
                    You cannot post comments, a token balance is required.
                </template>
            </li>
            <li>
                <template v-if="core.core.balance.gte(core.ext.minimumCreateBalance)">
                    You can create tasks (>
                    <TokenAmount
                        :value="core.ext.minimumCreateBalance"
                        :decimals="core.core.decimals"
                        :symbol="core.core.symbol"/>
                    ).
                </template>
                <template v-else>
                    You cannot create tasks, a balance of at least
                    <TokenAmount
                        :value="core.ext.minimumCreateBalance"
                        :decimals="core.core.decimals"
                        :symbol="core.core.symbol"/>
                    is required.
                </template>
            </li>
            <template v-if="core.freelance()">
                <li>
                    <template v-if="core.core.balance.gte(core.ext.minimumCreateEffortBalance)">
                        You can work on tasks (>
                        <TokenAmount
                            :value="core.ext.minimumCreateEffortBalance"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"/>
                        ).
                    </template>
                    <template v-else>
                        You cannot work on tasks, a balance of at least
                        <TokenAmount
                            :value="core.ext.minimumCreateEffortBalance"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"/>
                        is required.
                    </template>
                </li>
                <li>
                    <template v-if="core.core.balance.gte(core.ext.minimumApproveBalance)">
                        You can approve completed work (>
                        <TokenAmount
                            :value="core.ext.minimumApproveBalance"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"/>
                        ).
                    </template>
                    <template v-else>
                        You cannot approve work, a balance of at least
                        <TokenAmount
                            :value="core.ext.minimumApproveBalance"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"/>
                        is required.
                    </template>
                </li>
                <li>
                    <template v-if="core.core.balance.gte(core.ext.minimumCommentRequestBalance)">
                        You can create requests for comments (>
                        <TokenAmount
                            :value="core.ext.minimumCommentRequestBalance"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"/>
                        ).
                    </template>
                    <template v-else>
                        You cannot create requests for comments, a balance of at least
                        <TokenAmount
                            :value="core.ext.minimumCommentRequestBalance"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"/>
                        is required.
                    </template>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
import TokenAmount from "@/components/TokenAmount"
import {CoreData} from "@/views/happs/core/lib/CoreData";

export default {
    name: 'CoreDetails',
    components: {TokenAmount},
    props: {
        core: CoreData
    }
}
</script>
<style scoped lang="scss">
@import "~@/assets/css/custom.scss";


.core-details-wrap {
    max-width: 500px;
}

</style>
