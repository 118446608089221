import bookAbi from "@/assets/data/BaseBook.abi.json";
import abi from "@/assets/data/CoreToken.abi.json";
import tasksAbi from "@/assets/data/TasksExtension.abi.json";
import freelanceAbi from "@/assets/data/FreelanceExtension.abi.json";
import Dapp from "@/assets/lib/Dapp";
import { Batcher } from "@/lib/eth/Batcher";
import Contract from "@/lib/eth/Contract";
import { AesCrypt } from "@/views/happs/book/encryption/AesCrypt";
import { CommentData, CoreContractData, CoreData, CoreUser, EffortData, FreelanceData, TaskData, TaskDetailData, TasksExtensionData, } from "@/views/happs/core/lib/CoreData";
import { Chain, parseAddress } from "@blockwell/chain-client";
import BigNumber from "bignumber.js";
import equal from "fast-deep-equal";
import moment from "moment";
import * as R from "rambdax";
export async function loadCore(happ) {
    return loadStatic(happ);
}
export async function loadCoreForExtension(network, address) {
    let net = Dapp.getNetwork(network);
    let contract = new net.web3.eth.Contract(tasksAbi, address);
    return contract.methods.token().call();
}
async function loadStatic(happ) {
    let batcher = new Batcher(happ.web3);
    let cache = happ.cache;
    cache.core = new happ.web3.eth.Contract(abi, happ.address);
    batcher
        .setContract(cache.core)
        .add("name")
        .addBig("burnPercentage")
        .addBig("burnAmount")
        .addInt("decimals")
        .add("symbol")
        .addBig("nextPeriodReward")
        .add("paused")
        .addInt("periodLength")
        .addBig("periodMinimum")
        .add("extensions", "tasks", ["0"]);
    let core = (await batcher.execute(CoreContractData));
    let type = await Contract.getBwType(happ.network.networkId, core.tasks);
    if (type.name === "CoreFreelanceExtension") {
        cache.ext = new happ.web3.eth.Contract(freelanceAbi, core.tasks);
    }
    else {
        cache.ext = new happ.web3.eth.Contract(tasksAbi, core.tasks);
    }
    batcher = new Batcher(happ.web3);
    batcher
        .setContract(cache.ext)
        .addBig("contestPercentage")
        .addInt("contestPeriods")
        .addBig("minimumCreateBalance")
        .addInt("taskPeriodLimit")
        .addInt("taskCount")
        .addBig("taskCreatorReward")
        .addBig("approvalReward")
        .addBig("approvalPenalty");
    let objConstructor;
    if (type.name === "CoreFreelanceExtension") {
        objConstructor = FreelanceData;
        batcher
            .addBig("failurePenalty")
            .addBig("failedContestPenalty")
            .addBig("invalidPenalty")
            .addBig("minimumApproveBalance")
            .addBig("taskCoreReward")
            .addBig("minimumCommentRequestBalance")
            .addBig("commentRequestReward")
            .addBig("rejectTaskThreshold")
            .addBig("minimumCreateEffortBalance")
            .addBig("approvalThreshold");
    }
    else {
        objConstructor = TasksExtensionData;
        batcher
            .addInt("approvalFactor")
            .addInt("failureFactor")
            .addBig("invalidPenaltyPercentage")
            .addInt("taskRewardFactor");
    }
    let ext = (await batcher.execute(objConstructor));
    return new CoreData(type.name, core, ext, happ.address, happ.network.networkId, happ.contractId);
}
export function batchTasks(batcher, data, coreContract, taskContract, account) {
    let activeTasks = data.ext.activeTasks;
    batcher
        .setContract(coreContract)
        .addInt("currentPeriod")
        .addInt("periodStart")
        .addBig("totalSupply");
    if (account) {
        batcher
            .addBig("balanceOf", "balance", [account])
            .addBig("getRewards", "rewards", [account])
            .addBig("getPenalty", "penalty", [account])
            .addBoolean("isAdmin", "admin", [account])
            .addBoolean("isFrozen", "frozen", [account]);
        if (data.freelance()) {
            batcher.addBoolean("isManager", "manager", [account]);
        }
    }
    batcher.setContract(taskContract).addInt("getActiveTasks", "activeTasks").addInt("taskCount");
    let task = taskObject(data, account);
    batcher.addObjectList("tasklist", activeTasks.length, task, 0, TaskData);
}
export function batchTasksDifference(batcher, tasks, activeTasks, data, account) {
    let argsList = R.difference(activeTasks, data.ext.activeTasks).map((it) => [it]);
    let task = taskObject(data, account);
    task.text.argsList = argsList;
    task.ints.argsList = argsList;
    batcher.addObjectList("tasklist", argsList.length, task, 0, TaskData);
}
function taskObject(data, account) {
    let accountLower = account?.toLowerCase();
    let argsList = data.ext.activeTasks.map((it) => [it]);
    return {
        contractId: {
            value: data.contractId,
        },
        text: {
            method: "getTaskText",
            argsList,
            convert(value, it) {
                it.text = value;
                it.content = parseContent(value, "title");
            },
        },
        ints: {
            method: "getTaskInts",
            argsList,
            convert(ints, it, args) {
                it.id = args[0];
                it.creationPeriod = parseInt(ints[0]);
                it.reward = new BigNumber(ints[1]);
                it.status = getStatus(ints[4]);
                it.completionPeriod = parseInt(ints[5]);
                it.commentCount = parseInt(ints[6]);
                it.additionCount = parseInt(ints[7]);
                it.commentRequestReward = ints[8].toString();
                it.rejections = new BigNumber(ints[9]);
                it.creator = parseAddress(ints[10]);
                it.requester = parseAddress(ints[11]);
                it.effortCount = parseInt(ints[12]);
                if (data.freelance()) {
                    it.timestamp = moment.unix(parseInt(ints[2]));
                    it.rewardToken = parseAddress(ints[3]);
                }
                else {
                    it.perPeriod = parseInt(ints[2]);
                    it.completionCount = parseInt(ints[3]);
                }
                it.rejecters = [];
                it.commenters = [];
                it.responders = [];
                it.responseRewarded = [];
                let lists = [it.rejecters, it.commenters, it.responders, it.responseRewarded];
                let list = 0;
                for (let i = 13; i < ints.length; i++) {
                    if (ints[i].toString() === "0") {
                        ++list;
                    }
                    else {
                        lists[list].push(parseAddress(ints[i]));
                    }
                }
                it.isCreator = it.creator === accountLower;
                it.isRequester = it.requester === accountLower;
                it.hasCommented = !!it.commenters.find((it) => it === accountLower);
                it.hasResponded = !!it.responders.find((it) => it === accountLower);
                it.hasResponseRewarded = !!it.responseRewarded.find((it) => it === accountLower);
            },
        },
        efforts: {
            value() {
                return [];
            },
        },
    };
}
export function batchEfforts(batcher, tasks) {
    let args = [];
    for (let task of tasks) {
        for (let i = 0; i < task.effortCount; i++) {
            args.push([task.id, i]);
        }
    }
    batcher.addObjectList("efforts", args.length, {
        ints: {
            method: "getEffortInts",
            argsList: args,
            convert(ints, effort, args) {
                effort.taskId = args[0];
                effort.id = args[1];
                effort.creationPeriod = parseInt(ints[0]);
                effort.approvals = new BigNumber(ints[1]);
                effort.contests = new BigNumber(ints[2]);
                effort.approvalPeriod = parseInt(ints[3]);
                effort.status = getStatus(ints[4]);
                effort.creator = parseAddress(ints[5]);
                effort.collaborators = [];
                effort.approvers = [];
                effort.contesters = [];
                let lists = [effort.collaborators, effort.approvers, effort.contesters];
                let list = 0;
                for (let i = 6; i < ints.length; i++) {
                    if (ints[i].toString() === "0") {
                        ++list;
                    }
                    else {
                        lists[list].push(parseAddress(ints[i]));
                    }
                }
            },
        },
    }, 0, EffortData);
}
export function processCoreResponses(data, res, tasks, efforts, account) {
    for (let effort of efforts) {
        let task = tasks.find((it) => it.id === effort.taskId);
        task.efforts.push(effort);
        if (account) {
            if (effort.collaborators.find((it) => it.toLowerCase() === account.toLowerCase())) {
                task.isCollaborator = true;
                effort.isCollaborator = true;
            }
            if (effort.approvers.find((it) => it.toLowerCase() === account.toLowerCase())) {
                task.isApprover = true;
                effort.isApprover = true;
            }
            if (effort.contesters.find((it) => it.toLowerCase() === account.toLowerCase())) {
                task.isContester = true;
                effort.isContester = true;
            }
        }
    }
    for (let task of tasks) {
        task.core = data;
        task.completeStatus = taskStatus(task, data, res.currentPeriod);
    }
    let ext = {
        activeTasks: res.activeTasks,
        maxTaskId: res.activeTasks[res.activeTasks.length - 1],
    };
    // These are part of ext, not core
    delete res.activeTasks;
    delete res.taskCount;
    delete res.tasklist;
    return {
        core: res,
        tasks,
        ext,
    };
}
export async function loadDynamic(happ, data, account) {
    let activeTasks = data.ext.activeTasks;
    let cache = happ.cache;
    if (activeTasks === undefined || activeTasks === null) {
        activeTasks = (await cache.ext.methods.getActiveTasks().call()).map((it) => parseInt(it));
        activeTasks.sort((a, b) => a - b);
        data.ext.activeTasks = activeTasks;
    }
    let batcher = new Batcher(happ.web3);
    batchTasks(batcher, data, cache.core, cache.ext, account);
    let res = await batcher.execute();
    let tasks = res.tasklist;
    res.activeTasks.sort((a, b) => a - b);
    if (!equal(res.activeTasks, activeTasks)) {
        console.log("active tasks mismatch", res.activeTasks, activeTasks);
        let batcher = new Batcher(happ.web3);
        batcher.setContract(cache.ext);
        batchTasksDifference(batcher, tasks, res.activeTasks, data, account);
        let added = await batcher.execute();
        tasks = tasks.concat(added.tasklist);
        let remove = R.difference(activeTasks, res.activeTasks);
        if (remove.length > 0) {
            tasks = tasks.filter((it) => !remove.includes(it.id));
        }
    }
    batcher = new Batcher(happ.web3).setContract(cache.ext);
    batchEfforts(batcher, tasks);
    let efforts = (await batcher.execute()).efforts;
    return processCoreResponses(data, res, tasks, efforts, account);
}
export const taskStatuses = {
    open: "Open",
    repeating: "Repeating",
    progress: "In Progress",
    pending: "Pending Approval",
    completed: "Approved",
    invalid: "Invalid",
    archived: "Archived",
};
function getStatus(val) {
    let status;
    switch (parseInt(val)) {
        case 0:
            status = "open";
            break;
        case 1:
            status = "repeating";
            break;
        case 2:
            status = "completed";
            break;
        case 3:
            status = "approved";
            break;
        case 4:
            status = "failed";
            break;
        case 5:
            status = "invalid";
            break;
        case 6:
            status = "rejected";
            break;
    }
    return status;
}
function taskStatus(task, data, currentPeriod) {
    let effort;
    switch (task.status) {
        case "open":
            if (task.effortCount > 0) {
                let compl = task.efforts.find((it) => it.status === "completed");
                if (compl) {
                    return "pending";
                }
                let open = task.efforts.find((it) => it.status === "open");
                if (open) {
                    return "progress";
                }
            }
            return "open";
        case "completed":
            effort = task.efforts.find((it) => it.status === "approved");
            if (effort && currentPeriod - data.ext.contestPeriods > effort.approvalPeriod) {
                return "archived";
            }
            return task.status;
        case "invalid":
            effort = task.efforts.find((it) => it.status === "invalid");
            if (effort && currentPeriod - data.ext.contestPeriods > effort.approvalPeriod) {
                return "archived";
            }
            return task.status;
        default:
            return task.status;
    }
}
export const effortStatuses = {
    open: "In Progress",
    completed: "Pending Approval",
    approved: "Approved",
    failed: "Failed",
    invalid: "Invalid",
};
export async function loadTaskDetail(data, task) {
    const web3 = Dapp.getNetwork(data.network).web3;
    let contract;
    if (data.freelance()) {
        contract = new web3.eth.Contract(freelanceAbi, data.core.tasks);
    }
    else {
        contract = new web3.eth.Contract(tasksAbi, data.core.tasks);
    }
    let commentsPromise;
    let updates = new TaskDetailData();
    if (task.commentCount > 0) {
        commentsPromise = contract.getPastEvents("Commented", {
            fromBlock: 0,
            toBlock: "latest",
            filter: {
                taskId: task.id.toString(),
            },
        });
    }
    else {
        commentsPromise = Promise.resolve([]);
    }
    let batcher = new Batcher(web3).setContract(contract);
    if (task.commentRequestReward !== "0") {
        batcher.add("getCommentRequest", "commentRequest", [task.id]);
    }
    batcher.addListConvert("getAddition", (value) => {
        return parseContent(value);
    }, "additions", task.additionCount, {
        args: [task.id, true],
    });
    let res = await batcher.execute();
    if (res.commentRequest) {
        updates.commentRequest = parseContent(res.commentRequest);
    }
    if (res.additions) {
        updates.additions = res.additions;
    }
    let events = await commentsPromise;
    let comments = [];
    for (let event of events) {
        let vals = event.returnValues;
        let effortId = parseInt(vals.effortId);
        let type;
        let effort;
        switch (parseInt(vals.commentType)) {
            case 1:
                type = "completion";
                effort = task.efforts[effortId];
                break;
            case 2:
                type = "response";
                break;
            default:
                type = "task";
        }
        let comment = new CommentData();
        comment.id = vals.commentId;
        comment.taskId = task.id;
        comment.commenter = vals.commenter;
        comment.type = type;
        comment.comment = vals.comment;
        comment.content = parseContent(vals.comment);
        comment.timestamp = moment.unix(parseInt(vals.t));
        comment.effortId = effortId;
        let commenter = vals.commenter.toLowerCase();
        for (let effort of task.efforts) {
            if (effort.collaborators.includes(commenter)) {
                comment.collaborator = effort.id;
            }
            if (effort.approvers.includes(commenter)) {
                comment.approver = effort.id;
            }
            if (effort.contesters.includes(commenter)) {
                comment.contester = effort.id;
            }
            if (effort.creator === vals.commenter) {
                comment.effortCreator = effort.id;
            }
        }
        if (task.creator === commenter) {
            comment.taskCreator = true;
        }
        comments.push(comment);
    }
    updates.comments = comments;
    return updates;
}
export async function loadFundraising(data) {
    let json = await Chain.readString(5, "0xaEEeBd6ca46BEf69689F043681fEe5F9961D199A", bookAbi, "get", ["Core", "Target"], 120000);
    return JSON.parse(json);
}
function parseContent(text, defaultKey = "text") {
    let content;
    if (text.startsWith("{")) {
        try {
            content = JSON.parse(text);
        }
        catch (err) {
            console.log("Failed parsing task JSON", text.slice(0, 10), "...");
        }
    }
    if (!content) {
        content = {};
        content[defaultKey] = text;
    }
    return content;
}
export function exportTasks(data, tasks) {
    const crypt = new AesCrypt();
    let csv = "id,status,title,reward,effort_count,description,creator,collaborators";
    for (let task of tasks) {
        if (task.status === "repeating") {
            continue;
        }
        let collaborators = task.efforts.flatMap((it) => it.collaborators);
        let title = task.content.title;
        let description = task.content.description;
        if (task.content.encryption) {
            title = crypt.decrypt(title, { password: data.encryptionKey });
            if (description) {
                description = crypt.decrypt(description, { password: data.encryptionKey });
            }
        }
        csv +=
            "\n" +
                [
                    task.id.toString(),
                    taskStatuses[task.completeStatus],
                    '"' + title.replace(/"/g, '""') + '"',
                    task.reward.div(`1e${data.core.decimals}`).toString(10),
                    task.effortCount,
                    '"' + description?.replace(/"/g, '""') + '"',
                    task.creator,
                    collaborators.join(" "),
                ].join(",");
    }
    return csv;
}
export async function loadUsers(happ, data) {
    let cache = happ.cache;
    let contract = cache.core;
    let accounts = await contract.methods.getAllAccounts().call();
    console.log(accounts);
    let batcher = new Batcher(happ.web3);
    batcher.setContract(cache.core);
    let argsList = accounts.map((it) => [it]);
    batcher.addObjectList("users", accounts.length, {
        address: {
            valueList: accounts,
        },
        balance: {
            method: "balanceOf",
            argsList,
            type: "bignumber",
        },
        rewards: {
            method: "getRewards",
            argsList,
            type: "bignumber",
        },
        penalty: {
            method: "getPenalty",
            argsList,
            type: "bignumber",
        },
    }, 0, CoreUser);
    let res = await batcher.execute();
    let users = res.users;
    users.sort((a, b) => b.balance.minus(a.balance).toNumber());
    return users;
}
