<template>
    <div class="core-column" :class="{horizontal}">
        <a v-if="core" href="#" class="create-task btn btn-sm btn-primary" @click.prevent="createTask = true">+ Create Task</a>
        <h3 class="core-column-title">
            {{ title }}
        </h3>
        <template v-if="pendingTasks">
            <div v-for="task in pendingTasks" class="core-task pending-task">
                <div class="task-id">
                    Pending Task
                    <ProgressCircle tiny/>
                </div>
                <div class="task-reward">
                    <TokenAmount
                        :value="task.reward"
                        :symbol="task.symbol || core.core.symbol"
                        :decimals="core.core.decimals"
                    />
                </div>
                <div class="task-title">
                    {{ task.title }}
                </div>
            </div>
        </template>
        <Task v-for="task in tasks"
              v-if="showTask(task)"
              :key="task.core.address + '-' + task.id"
              :task="task"
              @click="taskClick(task)"
        />
        <button v-if="core" class="btn btn-sm btn-primary" @click="createTask = true">Create Task</button>

        <template v-if="core">
            <Modal :show.sync="createTask">
                <FreelanceCreateTask v-if="core.freelance()" :core="core" @submitted="createTask = false"/>
                <CreateTask v-else :core="core" @submitted="createTask = false"/>
            </Modal>
        </template>
    </div>
</template>

<script>
import DumbappModal from "@/components/DumbappModal";
import Modal from "@/components/Modal";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import CreateTask from "@/views/happs/core/CreateTask";
import FreelanceCreateTask from "@/views/happs/core/freelance/FreelanceCreateTask";
import {CoreData, TaskData} from "@/views/happs/core/lib/CoreData";
import Task from "@/views/happs/core/Task";
import BigNumber from "bignumber.js";
import moment from "moment";
import { clone } from "rambdax";
import {mapGetters, mapState} from "vuex";
import * as R from 'rambdax';

export default {
    name: "CoreColumn",
    components: {CreateTask, FreelanceCreateTask, Modal, TokenAmount, ProgressCircle, DumbappModal, Task},
    props: {
        title: String,
        tasks: Array,
        horizontal: Boolean,
        filter: String,
        /**
         * This should only be passed if the column is also for creating new tasks
         */
        core: CoreData
    },
    data() {
        return {
            createTask: false
        }
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('dumbapp', ['tracking']),
        pendingTasks() {
            if (!this.core) {
                return null;
            }
            let trackingId = ['core-task', this.core.network, this.core.address].join('-');
            console.log('trackingId', trackingId);

            let tracks = this.tracking[trackingId];

            console.log(tracks);
            /**
             *
             * @type {TaskData[]}
             */
            let tasks = this.tasks;

            if (tasks && tasks.length > 0 && tracks && tracks.length > 0) {
                console.log(clone(tasks));
                let filtered = tracks.filter(track => {
                    if (!track.rawTitle || track.status === "error") {
                        return false;
                    }
                    if (track.status === "completed" && track.submission.completedTime()) {
                        if (moment(track.submission.completedTime()).isBefore(moment().subtract(2, "minutes"))) {
                            return false;
                        }
                    }

                    return !tasks.find(it =>
                        it.id >= track.expectedTaskId
                        && it.content.title === track.rawTitle
                        && it.reward.eq(track.reward)
                    )
                });
                console.log('filtered', filtered);
                if (filtered.length > 0) {
                    return filtered;
                }
            }
            return null;
        }
    },
    methods: {
        taskClick(task) {
            this.$emit('task-click', task)
        },
        /**
         *
         * @param {TaskData} task
         * @return {boolean}
         */
        showTask(task) {
            switch (this.filter) {
                case 'collaborator':
                    return task.isCollaborator;
                case 'watching':
                    return task.core?.state?.comments?.[task.id]?.watch;
                case 'creator':
                    return task.creator.toLowerCase() === this.account.toLowerCase();
                case 'commented':
                    return task.hasCommented;
                default:
                    return true;
            }
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.core-column {
    flex: 0 0 300px;
    max-width: 300px;
    border-right: 1px solid rgba($primary, .2);
    padding: 16px 16px;
    min-height: 200px;

    &.horizontal {
        min-height: auto;
        display: flex;
        border-bottom: 1px solid rgba($primary, .2);
        border-right: none;
        padding: 10px;
        max-width: none;

        .core-column-title {
            padding-right: 10px;
        }

        .core-task {
            max-width: 230px;
        }
    }

    .core-column-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .create-task {
        float: right;
        padding: 0 3px;
    }
}

.core-task.pending-task {
    background: rgba($dark, 0.05);
    cursor: auto;

    &:hover {
        border-color: transparent;
        background: rgba($dark, 0.05);
    }

    .task-id {
        display: flex;

        ::v-deep( .progress-circular ) {
            margin-left: 5px;
            margin-top: -3px;
        }
    }
}
</style>
