<template>
    <div>

        <h4>Users for {{ core.core.name }}</h4>
        <div class="table-responsive-md">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th>Address</th>
                    <th>Balance</th>
                    <th>Period Rewards</th>
                    <th>Penalty</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in users">
                    <td>
                        <Clipboard :address="user.address" :network="core.network" ellipsize/>
                    </td>
                    <td>
                        <strong>
                            <TokenAmount
                                :value="user.balance"
                                :decimals="core.core.decimals"
                            />
                        </strong>
                    </td>
                    <td>
                        <TokenAmount
                            :value="user.rewards"
                            :decimals="core.core.decimals"
                        />
                    </td>
                    <td>
                        <TokenAmount
                            :value="user.penalty"
                            :decimals="core.core.decimals"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="user-actions">
            <DumbappPopover
                label="Mint"
                :instance="core.codes.mint"
            />
            <DumbappPopover
                label="Burn"
                :instance="core.codes.burn"
            />
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import TokenAmount from "@/components/TokenAmount";
import {CoreData} from "@/views/happs/core/lib/CoreData";
import {loadUsers} from "@/views/happs/core/lib/corelib";
import {Happ} from "@/views/happs/Happ";

export default {
    name: "CoreUsers",
    components: {DumbappPopover, TokenAmount, Clipboard},
    props: {
        core: CoreData,
        happ: Happ
    },
    data() {
        return {
            users: []
        };
    },
    created() {
        loadUsers(this.happ, this.core)
            .then(res => {
                this.users = res;
            });
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.user-actions {
    display: flex;

    ::v-deep( .dumbapp-popover ) {
        margin-right: 10px;
    }
}

</style>
