<template>
    <div class="freelance-contract">
        <div class="heading">
            <div>
                <h4 class="freelance-contract-name">
                    <router-link :to="'/core?contract=' + core.address">{{
                        core.core.name
                    }}</router-link>
                </h4>
            </div>
            <div class="balance-wrap">
                <span class="balance">
                    <TokenAmount :params="core.core" />
                </span>
                <span class="balance-label">YOUR BALANCE</span>
            </div>
        </div>
        <div class="my-tasks">
            <h5>My Tasks</h5>
            <div v-if="myTasks.length > 0" class="task-list">
                <Task
                    v-for="task in myTasks"
                    :key="task.id"
                    :task="task"
                    @click="taskClick(task)"
                />
            </div>
            <p v-else>No tasks started.</p>
        </div>
        <div class="open-tasks">
            <h5>Available Tasks</h5>
            <div v-if="openTasks.length > 0" class="task-list">
                <Task
                    v-for="task in openTasks"
                    :key="task.id"
                    :task="task"
                    @click="taskClick(task)"
                />
            </div>
            <p v-else>No tasks available.</p>
        </div>
    </div>
</template>

<script>
import TokenAmount from "@/components/TokenAmount";
import { CoreData, TaskData } from "@/views/happs/core/lib/CoreData";
import Task from "@/views/happs/core/Task";

export default {
    name: "FreelanceContract",
    components: { Task, TokenAmount },
    props: {
        core: CoreData,
        tasks: Array,
    },
    data() {
        return {
            myTasks: [],
            openTasks: [],
        };
    },
    watch: {
        tasks: {
            immediate: true,
            deep: true,
            /**
             * @param {TaskData[]} val
             */
            handler(val) {
                /**
                 *
                 * @type {TaskData[]}
                 */
                let my = [];
                /**
                 *
                 * @type {TaskData[]}
                 */
                let open = [];

                for (let task of val) {
                    if (task.isCollaborator && task.completeStatus !== "completed") {
                        my.push(task);
                    } else if (task.status === "open") {
                        open.push(task);
                    }
                }

                my.sort((a, b) => {
                    if (a.completeStatus !== b.completeStatus) {
                        if (a.completeStatus === "pending") {
                            return 1;
                        }
                        if (b.completeStatus === "pending") {
                            return -1;
                        }
                    }
                    return b.id - a.id;
                });
                open.reverse();

                this.myTasks = my;
                this.openTasks = open;
            },
        },
    },
    methods: {
        taskClick(task) {
            this.$emit("task-click", task);
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.freelance-contract {
    border: 1px solid #ddd;
    border-top-width: 4px;
    border-radius: 2px;
    @include stepColors;
}

.heading {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    .balance-wrap {
        display: flex;
        flex-direction: column;
        text-align: center;

        .balance {
            font-size: 24px;

            ::v-deep(.token-symbol) {
                font-size: 14px;
            }
        }

        .balance-label {
            color: $text-muted;
            font-size: 12px;
            line-height: 12px;
        }
    }
}

.my-tasks,
.open-tasks {
    padding: 0 15px;

    > ::v-deep(h5) {
        font-size: 14px;
        margin-top: 0;
    }
}

.my-tasks {
    ::v-deep(.core-task.task-pending) {
        border-left-color: #efae00;
    }
}

.open-tasks {
    :deep(.core-task.task-pending, .core-task.task-progress) {
        border-left-color: $text-muted;
        border-left-width: 3px;
    }
}

.task-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 10px;
}
</style>
