var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrap",staticClass:"core-task",class:[{
    'core-your-task': _vm.task.isCollaborator,
    'new-task': _vm.isNew,
    'has-request': _vm.commentRequest
}, 'task-' + _vm.task.completeStatus],on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.isNew)?_c('div',{staticClass:"new-task-badge"},[_vm._v("NEW")]):_vm._e(),_c('div',{staticClass:"task-id"},[_vm._v(" Task #"),_c('span',[_vm._v(_vm._s(_vm.task.id))]),(_vm.core.colorIndex)?_c('span',{staticClass:"contract-name",class:'core-color-' + _vm.core.colorIndex},[_vm._v(_vm._s(_vm.core.core.symbol))]):_vm._e(),(_vm.isEncrypted)?_c('i',{staticClass:"material-icons encrypted-task"},[_vm._v("lock")]):_vm._e(),(_vm.task.isCollaborator)?_c('span',{staticClass:"your-task"},[_vm._v("your task")]):_vm._e()]),_c('div',{staticClass:"task-reward"},[_c('TokenAmount',{attrs:{"value":_vm.task.reward,"symbol":_vm.tokenData.symbol,"decimals":_vm.tokenData.decimals}})],1),_c('div',{staticClass:"task-watch",class:{watching: _vm.isWatching}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.tooltipText,
                trigger: 'manual',
                show: _vm.showTooltip,
                container: _vm.$refs.wrap
             }),expression:"{\n                content: tooltipText,\n                trigger: 'manual',\n                show: showTooltip,\n                container: $refs.wrap\n             }"}],staticClass:"material-icons",attrs:{"href":"javascript: void 0;"},on:{"click":function($event){$event.stopPropagation();return _vm.watch.apply(null, arguments)}}},[_vm._v("visibility")])]),(_vm.isEncrypted && !_vm.decrypted)?_c('div',{staticClass:"task-title-encrypted"},[_vm._v(" Encrypted ")]):_c('div',{staticClass:"task-title"},[_vm._v(" "+_vm._s(_vm.decrypted || _vm.task.content.title)+" ")]),(_vm.task.perPeriod)?_c('div',{staticClass:"task-perperiod"},[_vm._v(" completed "+_vm._s(_vm.task.completionCount)+"/"+_vm._s(_vm.task.perPeriod)+" ")]):_vm._e(),(_vm.failing)?_c('div',{staticClass:"task-failing error"},[_vm._v(" Effort will fail in "+_vm._s(_vm.failing)+" ")]):_vm._e(),(_vm.commentRequest)?_c('div',{staticClass:"task-comment-request",class:{'can-respond': _vm.canRespond}},[_vm._v(" Comments Requested "),(!_vm.core.freelance())?_c('TokenAmount',{attrs:{"value":_vm.task.commentRequestReward,"decimals":_vm.core.core.decimals,"symbol":_vm.core.core.symbol}}):_vm._e()],1):_vm._e(),(_vm.task.commentCount > 0)?_c('div',{staticClass:"task-comment-count",class:{'new-comments': _vm.newComments}},[_vm._v(_vm._s(_vm.task.commentCount)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }