<template>
    <div class="core-task" @click="$emit('click')" :class="{'core-your-task': task.yourTask, 'new-task': isNew}"
         ref="wrap">
        <div v-if="isNew" class="new-task-badge">NEW</div>
        <div v-if="task.commentCount > 0" class="task-comment-count"
             :class="{'new-comments': newComments}">{{ task.commentCount }}
        </div>
        <div class="task-id">
            Task #<span>{{ task.id }}</span>
            <i v-if="isEncrypted" class="material-icons encrypted-task">lock</i>
            <span v-if="task.yourTask" class="your-task">your task</span>
        </div>
        <div class="task-reward">
            <TokenAmount
                :value="task.reward"
                :symbol="core.core.symbol"
                :decimals="core.core.decimals"
            />
        </div>
        <div class="task-watch" :class="{watching: isWatching}">
            <a
                class="material-icons"
                href="javascript: void 0;"
                @click.stop="watch"
                v-tooltip="{
                    content: tooltipText,
                    trigger: 'manual',
                    show: showTooltip,
                    container: $refs.wrap
                 }"
            >visibility</a>
        </div>
        <div v-if="isEncrypted && !decrypted" class="task-title-encrypted">
            Encrypted
        </div>
        <div v-else class="task-title">
            {{ decrypted || task.content.title }}
        </div>
        <div v-if="task.perPeriod" class="task-perperiod">
            completed {{ task.completionCount }}/{{ task.perPeriod }}
        </div>
        <div v-if="failing" class="task-failing error">
            Effort will fail in {{ failing }}
        </div>
    </div>
</template>

<script>
import TokenAmount from "@/components/TokenAmount";
import {CoreData} from "./lib/CoreData";
import CryptMixin from "./lib/CryptMixin";
import {FastAesCrypt} from "./lib/FastAesCrypt";
import WatchingMixin from "./WatchingMixin";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
    name: "Task",
    components: {TokenAmount},
    mixins: [WatchingMixin, CryptMixin],
    props: {
        task: Object,
        core: CoreData
    },
    data() {
        return {
            showTooltip: false
        }
    },
    computed: {
        ...mapGetters('user', ['loggedIn']),
        tooltipText() {
            if (this.isWatching) {
                return "Watching task";
            }
            return "No longer watching task";
        },
        isEncrypted() {
            return this.task?.content?.encryption;
        },
        isNew() {
            return this.loggedIn && this.task?.id > this.core.latestTask && (!this.core.viewedTasks || !this.core.viewedTasks.includes(this.task?.id));
        },
        newComments() {
            if (!this.isWatching) {
                return false;
            }
            /**
             * @type {TaskData}
             */
            let task = this.task;
            if (!task.efforts || task.commentCount === 0) {
                return false;
            }
            let taskComments = this.core.state?.comments?.[this.task.id];

            if (taskComments) {
                for (let effort of task.efforts) {
                    if (taskComments[effort.id] !== undefined && effort.commentCount > taskComments[effort.id]) {
                        return true;
                    }
                }
            }
            return false;
        },
        failing() {
            if (this.task?.efforts?.length > 0 && this.task.status !== 'completed') {
                let failingPeriod = this.core.core.currentPeriod - this.core.ext.taskPeriodLimit;

                if (this.core.endingSoon) {
                    for (
                        /**
                         * @type {EffortData}
                         */
                        let effort of this.task.efforts) {

                        if (effort.creationPeriod <= failingPeriod && (effort.status === 'open' || effort.status === 'completed')) {
                            if (this.core.endsInHours < 2) {
                                return this.core.periodEnds.fromNow(true);
                            } else {
                                return this.core.endsInHours + " hours";
                            }
                        }
                    }
                }
            }
            return null;
        }
    },
    asyncComputed: {
        decrypted() {
            if (this.isEncrypted && this.core.encryptionKey) {
                return this.crypt.decrypt(this.task.content.title, {password: this.core.encryptionKey});
            }
            return null;
        }
    },
    methods: {
        watch() {
            this.toggleWatch();

            this.showTooltip = true;
            setTimeout(() => {
                this.showTooltip = false;
            }, 3000);
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.core-task {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: rgba($primary, 0.05);
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 18px 12px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .2s;
    position: relative;

    &:hover {
        border-color: rgba($primary, 0.15);
        background: rgba($primary, 0.08);
    }

    &.core-your-task {
        border-left-width: 3px;
        border-left-color: $primary;
    }

    &.new-task {
        border-left-width: 3px;
        border-left-color: #b84444;
    }

    .task-id {
        font-size: 13px;
        color: $text-muted;

        span {
            color: $secondary;
        }
    }

    i.encrypted-task {
        margin-left: 5px;
        color: #999;
    }

    span.your-task {
        margin-left: 5px;
        color: rgba($primary, .7);
    }

    .task-reward {
        font-size: 14px;
        flex: 1 0 auto;
        text-align: right;
        color: rgb(177, 104, 12);

        .token-symbol {
            color: $secondary;
        }
    }

    .task-title, .task-title-encrypted {
        width: 100%;
        overflow-wrap: break-word;
    }

    .task-title-encrypted {
        font-style: italic;
        color: $text-muted;
    }

    .task-perperiod {
        flex: 1 0 100%;
        font-size: 14px;
        color: $secondary;
        text-align: right;
    }

    .new-task-badge {
        position: absolute;
        left: 1px;
        top: 0;

        font-weight: 600;
        font-size: 10px;
        padding: 0 3px;
        border-top-left-radius: 2px;
        color: #b84444;
        transition: opacity .3s;
    }

    &:hover .new-task-badge {
        opacity: 1;
    }

    .task-comment-count {
        position: absolute;
        height: 17px;
        right: -2px;
        bottom: -2px;
        background: #999;
        font-weight: bold;
        font-size: 11px;
        padding: 2px 6px 2px;
        border-radius: 10px;
        color: #fff;

        &.new-comments {
            background: $danger;
        }
    }

    .task-watch {
        font-size: 1.1em;
        position: absolute;
        right: 5px;
        top: 0;

        a {
            color: #ccc;
        }

        a:hover {
            text-decoration: none;
            color: #666;
        }

        &.watching {
            a {
                color: rgba($primary, .7);
            }

            a:hover {
                color: $primary;
            }
        }
    }

    .task-failing {
        font-size: 0.9em;
    }
}
</style>
