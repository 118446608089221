<template>
    <div class="core-task-detail create-task">
        <div class="task-section">
            <i class="material-icons section-icon">note</i>


            <div class="error" style="border: 1px solid red; margin: 10px auto; padding: 5px; font-size: 20px;">
                This version of Core has been archived and is only here for viewing. <strong>New tasks should be created
                here</strong>: <router-link to="/core/test">Latest Core</router-link>.
            </div>
            <div class="task-title">
                <FormulateInput
                    label="Title"
                    type="text"
                    name="title"
                    validation="required"
                    v-model="title"
                />
            </div>
            <div class="task-reward">
                <FormulateInput
                    label="Reward"
                    step="any"
                    type="number"
                    validation="required|number"
                    error-behavior="blur"
                    help="Amount of tokens to reward for completing this task."
                    name="reward"
                    v-model="reward"
                />
            </div>
        </div>

        <div class="task-section">
            <i class="material-icons section-icon">notes</i>
            <h6>Description</h6>
            <MarkdownEditor :content.sync="description" min-height="100"
                            :toolbar="['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                            'link', 'image', 'preview', 'guide']"
            />
        </div>

        <div class="task-section">
            <div v-if="notEnoughTokens" class="error">
                You must have at least
                <TokenAmount :value="core.ext.minimumCreateBalance" :decimals="core.core.decimals"
                             :symbol="core.core.symbol"/>
                to create a task, you have
                <TokenAmount :value="core.core.balance" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                .
            </div>
            <DumbappPopover
                label="Create Task"
                :instance="core.codes.createTask"
                :disabled="disable"
                :values="query"
                @submitted="taskSubmitted"
            />
        </div>
    </div>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import Effort from "./Effort";
import {loadEfforts} from "./lib/corelib";
import * as R from "rambdax";
import {mapActions, mapState} from "vuex";

const crypt = new AesCrypt();

export default {
    name: "CreateTask",
    components: {MarkdownEditor, Effort, DumbappPopover, ProgressCircle, Markdown, TokenAmount},
    props: {
        core: Object
    },
    data() {
        return {
            title: "",
            reward: "",
            description: ""
        };
    },
    computed: {
        query() {
            let query = {title: this.title, description: this.description};

            if (this.core.encryptionKey) {
                query.encryption = "aes";
                query.title = crypt.encrypt(this.title, {password: this.core.encryptionKey});
                if (this.description) {
                    query.description = crypt.encrypt(this.description, {password: this.core.encryptionKey});
                }
            }

            return {
                text: JSON.stringify(query),
                reward: this.reward
            }
        },
        disable() {
            return !this.title || !this.reward
        },
        notEnoughTokens() {
            /**
             *
             * @type {CoreData}
             */
            let core = this.core;
            if (!core.core.balance) {
                return false;
            }

            return core.core.balance.lt(core.ext.minimumCreateBalance);
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        taskSubmitted(submission) {
            this.$emit('submitted', submission);

            let reward = submission.data.args[0].args[1];

            this.trackSubmission({
                key: ['core-task', this.core.network, this.core.address].join('-'),
                data: {
                    id: submission.id,
                    reward,
                    title: this.title
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.create-task {
    ::v-deep( .editor-toolbar ) {

        background: #efefef;
        font-size: 0.8em;
        padding: 0;

        &::before, &::after {
            content: none;
        }
    }
}

.markdown-help {
    font-size: 14px;
    color: $secondary;
    margin-top: 3px;
}

.task-reward {
    ::v-deep( .formulate-input-element ) {
        max-width: 300px;
    }
}

</style>
