<template>
    <div class="task-comments task-section">
        <i class="material-icons section-icon">comment</i>
        <h6>Comments</h6>
        <CommentRequest v-if="task.commentRequest" :core="core" :task="task"/>
        <div class="add-comment">
            <div class="comment-types">
                <div class="comment-type" :class="{active: type === 'comment'}" @click="type = 'comment'">Comment</div>
                <div v-if="canCompleteEfforts"
                     class="comment-type"
                     :class="{active: type === 'complete'}"
                     @click="type = 'complete'">Complete Effort
                </div>
                <div v-if="canRequestComments"
                     class="comment-type"
                     :class="{active: type === 'request'}"
                     @click="type = 'request'">Request Comments
                </div>
                <div v-if="task.perPeriod > task.completionCount"
                     class="comment-type"
                     :class="{active: type === 'scrum'}"
                     @click="type = 'scrum'">Add Scrum
                </div>
            </div>
            <MarkdownEditor min-height="30" :content.sync="commentText" ref="comment"
                            :toolbar="['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                            'link', 'image', 'preview', 'guide']"
            />
            <Help :show.sync="showCompleteHelp">
                <div>Completing an effort requires leaving a comment. Describe to others how you completed the task, so they can approve your effort.</div>
            </Help>
            <Help :show.sync="showScrumHelp">
                <div>To add a scrum you must enter a comment, and add all attendees in the list underneath.</div>
            </Help>
            <small v-if="!encryptionKey" class="error">
                <template v-if="core.core.frozen">
                    This comment will <em>not</em> be encrypted because you {{ core.core.symbol }} tokens are frozen.
                </template>
                <template v-else>
                    This comment will
                    <em>not</em> be encrypted because you don't have any {{ core.core.symbol }} tokens.
                </template>
            </small>
            <template v-if="type === 'complete'">
                <div class="mark-completed">
                    <FormulateInput
                        v-if="completeOptions"
                        type="checkbox"
                        :options="completeOptions"
                        help="Optionally complete an effort."
                        v-model="mark"
                    />
                    <div v-else class="comment-notice">
                        Completing Effort #{{ canCompleteEfforts[0].id }}
                    </div>
                </div>
                <div class="add-comment-button">
                    <DumbappPopover
                        label="Complete Effort"
                        :instance="core.codes.completeEffort"
                        :disabled="commentDisabled"
                        :values="completeQuery"
                        @submitted="commentSubmitted"
                    />
                </div>
            </template>
            <template v-else-if="type === 'request'">
                <div class="comment-notice">
                    <p>You can offer a reward for comments to help with this task. Enter a reward below, and the
                        amount will be burned from your tokens.</p>
                    <div>
                        Collaborators on this task can then choose which responses to reward, and each commenter
                        rewarded will receive the full amount.
                    </div>
                </div>
                <FormulateInput
                    type="number"
                    label="Reward"
                    v-model="requestReward"
                    :validation="[
                        ['required'],
                        ['between', 0, requestRewardMax]
                    ]"
                    @validation="requestRewardValidation = $event"
                />
                <div class="add-comment-button">
                    <DumbappPopover
                        label="Request Comments"
                        :instance="core.codes.requestComments"
                        :disabled="commentDisabled || requestRewardValidation.hasErrors"
                        :values="requestQuery"
                        @submitted="commentSubmitted"
                    />
                </div>
            </template>
            <template v-else-if="type === 'scrum'">
                <div class="comment-notice">
                    Add the addresses of the attendees. There must be at least two attendees.
                </div>
                <ArrayInput
                    @validation="attendeesValidation = $event"
                    @updated="attendees = $event.val"
                    :value="[core.account, '']"
                    :field="{
                    name: 'accounts',
                    label: 'Attendees',
                    type: 'address'
                    }">
                    <template #label>
                        <label>Attendees</label>
                    </template>
                </ArrayInput>
                <div class="add-comment-button">
                    <DumbappPopover
                        label="Add Scrum"
                        :instance="core.codes.scrumEffort"
                        :disabled="commentDisabled || attendeesValidation.hasErrors"
                        :values="scrumQuery"
                        @submitted="commentSubmitted"
                    />
                </div>
            </template>
            <template v-else>
                <div class="add-comment-button">
                    <DumbappPopover
                        label="Add Comment"
                        :instance="core.codes.comment"
                        :disabled="commentDisabled"
                        :values="commentQuery"
                        @submitted="commentSubmitted"
                    />
                </div>
            </template>
        </div>
        <template v-if="pendingComments">
            <div v-for="comment in pendingComments" class="effort-comment pending-comment">
                <div class="effort-comment-header">
                    Your comment is pending
                    <ProgressCircle tiny/>
                </div>
                <div class="comment-text">
                    <Markdown :content="comment.text"/>
                </div>
            </div>
        </template>
        <template v-if="commentList">
            <Comment v-for="comment in commentList"
                     :key="comment.id"
                     :comment="comment"
                     :task="task"
                     :unread="lastRead !== null && lastRead <= comment.id"
                     :can-reward="canReward"
                     :show-approve="showComment === comment.id && showApprove"
                     :network="network"
                     :collaborators="collaborators"
                     :encryption-key="encryptionKey"
                     :core="core"/>
        </template>
        <div v-else>
            No comments yet.
        </div>
        <Help
            :show.sync="helpShow"
            :reference="helpReference">
            <template v-if="helpText === 'approve'">
                <div>
                    Approving an Effort will close out both the Effort and the Task as successful, and award the
                    Effort collaborators with the task reward. Approvers will also receive a small portion of the
                    reward.
                </div>
            </template>
            <template v-else>
                <p>
                    You can contest an Effort if you think it wasn't completed properly. When an Effort is contested
                    successfully, its collaborators and approvers will be penalized.
                </p>
                <p>
                    Contesting is successful if at the end of the period the contesters hold at least twice as many
                    tokens as the approvers.
                </p>
                <div>
                    If contesting fails, you will be penalized instead.
                </div>
            </template>
        </Help>
    </div>
</template>
<script>
import ArrayInput from "@/components/dumbapp/input/ArrayInput";
import Comment from "@/views/happs/core/Comment"
import DumbappPopover from "@/components/DumbappPopover"
import Help from "@/components/Help"
import Markdown from "@/components/Markdown"
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor"
import ProgressCircle from "@/components/ProgressCircle"
import CommentRequest from "@/views/happs/core/CommentRequest";
import {CoreData, TaskData, TaskDetailData} from "@/views/happs/core/lib/CoreData";
import CryptMixin from "@/views/happs/core/lib/CryptMixin";
import WatchingMixin from "@/views/happs/core/WatchingMixin";
import { resolver } from "@blockwell/eth-types";
import {mapActions, mapGetters} from "vuex";
import * as R from 'rambdax';
import {debounce} from "@/lib/vutil";

export default {
    name: 'TaskComments',
    components: {ArrayInput, CommentRequest, Comment, DumbappPopover, Help, Markdown, MarkdownEditor, ProgressCircle},
    mixins: [CryptMixin, WatchingMixin],
    props: {
        core: CoreData,
        network: Number,
        lastRead: [Number, null],
        task: TaskData,
        taskDetail: TaskDetailData,
        showComment: Number,
        showApprove: Boolean,
        encryptionKey: String
    },
    data() {
        return {
            commentText: "",
            mark: [],
            showCompleteHelp: false,
            showScrumHelp: false,
            helpReference: null,
            helpText: null,
            helpShow: false,
            type: "comment",
            requestReward: '',
            requestRewardValidation: {},
            attendeesValidation: {},
            attendees: null,
            jumped: false
        }
    },
    computed: {
        ...mapGetters('dumbapp', ['tracking']),
        commentList() {
            if (this.taskDetail.comments?.length > 0) {
                return R.reverse(this.taskDetail.comments);
            }
            return null;
        },
        commentDisabled() {
            return !this.commentText;
        },
        pendingComments() {
            let tracks = this.tracking[['core-comment', this.core.network, this.core.address, this.task.id].join('-')];
            /**
             * @type {CommentData[]}
             */
            let comments = this.commentList || [];

            if (tracks && tracks.length > 0) {
                let filtered = tracks.filter(it => {
                    if (it.status === "pending") {
                        return true;
                    }

                    let event = it.submission.steps[0].events?.find(it => it?.event === "Commented");
                    if (event) {
                        let commentId = parseInt(resolver(event.params).string("commentId"));
                        return !comments.find(it => it.id === commentId);
                    }
                });
                if (filtered.length > 0) {
                    return filtered;
                }
            }
            return null;
        },
        canCompleteEfforts() {
            let efforts = this.task.efforts.filter(it => it.status === 'open' && it.isCollaborator);
            if (efforts.length > 0) {
                return efforts;
            }
            return null;
        },
        completeOptions() {
            if (this.canCompleteEfforts && this.canCompleteEfforts.length > 1) {
                let efforts = {};
                for (let it of this.canCompleteEfforts) {
                    efforts[it.id.toString()] = `Mark Effort #${it.id} Completed`;
                }
                return efforts;
            }
            return null;
        },
        canRequestComments() {
            return !this.taskDetail.commentRequest && this.task.status !== "repeating" && (this.task.isCreator || this.task.isCollaborator);
        },
        requestRewardMax() {
            return this.task.reward.div(`1e${this.core.core.decimals}`).div(5).toNumber();
        },
        completeQuery() {
            let effortId;
            if (this.completeOptions) {
                effortId = this.mark[0];
            } else {
                effortId = this.canCompleteEfforts[0].id.toString();
            }
            return {
                ...this.commentQuery,
                effortId
            }
        },
        requestQuery() {
            return {
                ...this.commentQuery,
                reward: this.requestReward
            }
        },
        scrumQuery() {
            return {
                ...this.commentQuery,
                commentText: this.commentQuery?.text,
                collaborators: this.attendees?.map(it => it.accounts)
            }
        },
        canReward() {
            return this.task.isCollaborator || this.task.isCreator;
        },
        collaborators() {
            return this.task.efforts.flatMap(it => it.collaborators);
        }
    },
    asyncComputed: {
        async commentQuery() {
            let text = {
                text: this.commentText
            }

            if (this.encryptionKey) {
                text = {
                    encryption: "aes-2",
                    text: await Promise.resolve(this.crypt.encrypt(text.text, {password: this.encryptionKey}))
                }
            }

            return {
                taskId: this.task?.id?.toString(),
                text: JSON.stringify(text)
            };
        }
    },
    watch: {
        mark(val, old) {
            if (val.length > 1) {
                this.mark = [val.filter(it => it !== old[0])[0]];
            }
        },
        commentText(val) {
            if (val && this.showCompleteHelp) {
                this.showCompleteHelp = false;
            }
            if (val && this.showScrumHelp) {
                this.showScrumHelp = false;
            }
            this.saveDraft();
        },
        commentList(val) {
            // If showComment is set, as soon as we have a loaded list of comments we want to jump to the right one.
            if (!this.jumped && val && val.length > 0) {
                this.jumped = true;
                this.$nextTick(() => {
                    if (this.showComment !== undefined) {
                        document.getElementById(`comment-${this.task.id}-${this.showComment}`).scrollIntoView({
                            block: 'start',
                            behavior: 'smooth'
                        });
                    }
                });
            }
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        ...mapActions('drafts', ['saveCommentDraft', 'clearCommentDraft']),
        commentSubmitted(submission) {
            let text = this.commentText;
            this.commentText = '';
            this.type = 'comment';
            this.requestReward = '';
            this.trackSubmission({
                key: ['core-comment', this.core.network, this.core.address, this.task.id].join('-'),
                data: {
                    id: submission.id,
                    text
                }
            });

            this.mark = [];

            if (!this.isWatching) {
                this.toggleWatch();
            }

            this.clearCommentDraft({
                contractId: this.core.contractId,
                taskId: this.task.id
            })
        },
        complete(effort) {
            this.mark = [effort.id];
            this.$refs.comment.focus();
            this.$refs.comment.$el.scrollIntoView({
                block: 'start'
            });
            this.type = 'complete';
            this.showCompleteHelp = true;
        },
        addScrum() {
            this.$refs.comment.focus();
            this.$refs.comment.$el.scrollIntoView({
                block: 'start'
            });
            this.type = 'scrum';
            this.showScrumHelp = true;
        },
        approve(effort) {
            let comment = this.taskDetail.comments.find(it => it.type === 'completion' && it.effortId === effort.id);
            this.helpText = 'approve';
            this.helpReference = $(`#comment-${this.task.id}-${comment.id}`)[0];
            this.helpShow = true;
            this.jumpToComment(effort);
        },
        contest(effort) {
            let comment = this.taskDetail.comments.find(it => it.type === 'completion' && it.effortId === effort.id);
            this.helpText = 'contest';
            this.helpReference = $(`#comment-${this.task.id}-${comment.id}`)[0];
            this.helpShow = true;
            this.jumpToComment(effort);
        },
        jumpToComment(effort) {
            let comment = this.taskDetail.comments.find(it => it.type === 'completion' && it.effortId === effort.id);

            if (comment) {
                document.getElementById(`comment-${this.task.id}-${comment.id}`).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            }
        },
        saveDraft: debounce(function() {
            this.saveCommentDraft({
                contractId: this.core.contractId,
                taskId: this.task.id,
                text: this.commentText,
                type: this.type
            })
        }, 200)
    },
    created() {
        if (this.task) {
            let draft = this.$store.state.drafts.drafts[`${this.core.contractId}-comment-${this.task.id}`];

            if (draft) {
                this.commentText = draft.text;
                this.type = draft.type || "comment";
            }
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.task-comments {
    .add-comment {
        margin-bottom: 15px;


        .editor-toolbar {

            background: #efefef;
            font-size: 0.8em;
            padding: 0;

            &::before, &::after {
                content: none;
            }
        }
    }

    .add-comment-button {
        display: flex;
        align-items: center;
    }

    .pending-comment {

        .effort-comment-header {
            display: flex;

            .progress-circular {
                margin-left: 5px;
            }
        }

        .comment-text {
            background-color: rgba($dark, 0.05);
        }
    }

    .mark-completed {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .comment-types {
        display: flex;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;

        .comment-type {
            min-width: 80px;
            padding: 12px 20px;
            font-weight: 600;
            color: $secondary;
            font-size: 14px;
            cursor: pointer;

            &.active {
                border-bottom: 2px solid $primary;
            }
        }
    }

    .comment-notice {
        margin: 5px 0;
        color: $secondary;
    }
}
</style>
