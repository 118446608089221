<template>
    <div class="effort-comment">
        <div class="effort-comment-header">
            <AddressBlockie
                :address="comment.commenter"/>
            <i v-if="isEncrypted" class="material-icons encrypted-icon">lock</i>
            <Clipboard :address="comment.commenter" :network="network" ellipsize/>
            <span v-if="comment.collaborator" class="commenter-status is-collaborator">collaborator</span>
            <span v-else-if="comment.taskCreator" class="commenter-status is-task-creator">task creator</span>
            <span v-else-if="comment.effortCreator" class="commenter-status is-effort-creator">effort creator</span>
            <span v-if="unread" class="new-comment">NEW</span>
        </div>
        <div class="comment-text">
            <div v-if="isEncrypted && !decrypted" class="encrypted-comment">
                Encrypted
            </div>
            <Markdown v-else :content="decrypted || comment.content.text"/>
        </div>
    </div>
</template>

<script>
import Address from "@/components/Address";
import AddressBlockie from "@/components/AddressBlockie";
import Clipboard from "@/components/Clipboard";
import Markdown from "@/components/Markdown";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import CryptMixin from "./lib/CryptMixin";

export default {
    name: "Comment",
    components: {Address, Markdown, Clipboard, AddressBlockie},
    mixins: [CryptMixin],
    props: {
        comment: Object,
        network: [String, Number],
        core: Object,
        unread: Boolean
    },
    computed: {
        isEncrypted() {
            return this.comment?.content?.encryption;
        },
    },
    asyncComputed: {
        decrypted() {
            if (this.isEncrypted && this.core.encryptionKey) {
                return this.crypt.decrypt(this.comment.content.text, {password: this.core.encryptionKey});
            }
            return null;
        },
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.effort-comment {
    margin-bottom: 20px;

    .effort-comment-header {
        font-size: 14px;
        opacity: 0.8;

        .clipboard-text {
            color: $dark;
        }
        .address-blockie {
            position: absolute;
            left: 0;
            margin-top: 5px;
        }
    }

    .comment-text {
        background: rgba($primary, 0.05);
        border-radius: 2px;
        margin-top: 2px;
        padding: 12px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .commenter-status {
        font-size: 12px;
        border: 1px solid rgba($primary, .5);
        color: $primary;
        display: inline-block;
        padding: 1px 8px 2px;
        border-radius: 10px;
        margin-left: 5px;
        margin-bottom: 2px;
    }

    .is-task-creator {
        border-color: rgba($purple, .6);
        color: $purple;
    }

    .encrypted-comment {
        font-style: italic;
        color: $secondary;
    }

    .encrypted-icon {
        position: absolute;
        left: 20px;
        margin-top: 38px;
        font-size: 14px;
        color: #666;
    }

    .new-comment {
        font-size: 12px;
        background: $danger;
        color: #fff;
        display: inline-block;
        padding: 1px 8px 2px;
        border-radius: 10px;
        margin-left: 5px;
        margin-bottom: 2px;
    }
}
</style>
