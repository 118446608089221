<template>
    <layout-heading :size="core.core ? 'scroll' : 'full'" no-messages>

        <portal to="title">
            <router-link to="/core">
                <img src="../../../assets/images/icon-QR.svg" alt="">
                <span>Core</span>
            </router-link>
        </portal>
        <template #heading-cta>
            <ContractDropdown v-if="core.core"
                              @change-contract="reset"
                              :name="core.core.name"
                              :contracts="contracts"
                              :network="network"
            />
        </template>

        <div v-if="coreLoading">
            <ProgressCircle/>
        </div>
        <ContractLoader v-else-if="!core.core"
                        name="Core"
                        path="core"
                        :common="commonContracts"
                        features="core"
                        class="core-contract-loader"
        />

        <template v-if="!coreLoading && core.core">
            <CoreInfo :core="core" @users="showCoreUsers = true"/>

            <div v-if="noAccess" class="no-access">
                <h3>You don't have access to view this board.</h3>
                <p v-if="account">You must have {{ core.core.name }} ({{ core.core.symbol }}) to gain access.</p>
                <p v-else>You must be logged in to gain access.</p>
            </div>
            <template v-else>
                <div v-if="planningMode" class="exit-planning-mode">
                    <button class="btn btn-sm btn-outline-primary" @click="exitPlanningMode">Exit Planning Mode</button>
                </div>
                <CoreBoxes v-else :account="account" :core="core" :enter-planning-mode="enterPlanningMode"
                           :export-csv="exportCsv" :filter.sync="filter" ref="boxes"/>

                <FormulateInput
                    class="quick-add"
                    type="text"
                    label="Quick Add Tasks"
                    help="Paste in task titles separated by commas, or type one in and hit enter."
                    @keydown="quickTaskKeydown"
                    v-model="quickTaskValue"
                />
                <Help dismiss-key="core-QuickTasks" :show="true" reference=".quick-add input">
                    <p>
                        Quickly add drafts of tasks by typing here and pressing enter.
                    </p>
                </Help>
            </template>
        </template>

        <div class="core-wrap">
            <template v-if="!noAccess">
                <PlanningMode v-if="planningMode"
                              v-model="core.state.planning"
                              :path="path"
                              :core="core"/>

                <CoreTasks v-else-if="!coreLoading && core.core"
                           :tasks="tasks"
                           :filter="filter"
                           :core="core"
                           @task-click="taskClick"/>
            </template>
        </div>

        <Modal :show="!!openTask" @closed="taskClosed">
            <TaskDetail
                :task="openTask"
                :show-comment="showComment"
                :show-approve="showApprove"
                :core="core"/>
        </Modal>

        <Modal :show.sync="showCoreUsers">
            <CoreUsers :core="core" :happ="happ"/>
        </Modal>
    </layout-heading>
</template>

<script>
import {getNetwork} from "@/assets/lib/networks";
import Help from "@/components/Help";
import Modal from "@/components/Modal";
import ProgressCircle from "@/components/ProgressCircle";
import Contract from "@/lib/eth/Contract";
import { every } from "@/lib/interval";
import ContractDropdown from "@/views/happs/components/ContractDropdown";
import ContractLoader from "@/views/happs/components/ContractLoader";
import CoreBoxes from "@/views/happs/core/CoreBoxes";
import CoreColumn from "@/views/happs/core/CoreColumn";
import CoreInfo from "@/views/happs/core/CoreInfo";
import CoreTasks from "@/views/happs/core/CoreTasks";
import CoreUsers from "@/views/happs/core/CoreUsers";
import CreateTask from "@/views/happs/core/CreateTask";
import FreelanceCreateTask from "@/views/happs/core/freelance/FreelanceCreateTask";
import LatestTasksMixin from "@/views/happs/core/LatestTasksMixin";
import {CoreContractData, CoreData, TasksExtensionData, TaskData, FreelanceData} from "@/views/happs/core/lib/CoreData";
import {
    exportTasks,
    loadCore,
    loadCoreForExtension,
    loadDynamic
} from "@/views/happs/core/lib/corelib";
import {PlanningTaskData} from "@/views/happs/core/lib/PlanningData";
import PlanningMode from "@/views/happs/core/planning/PlanningMode";
import TaskDetail from "@/views/happs/core/TaskDetail";
import HappMixin from "@/views/happs/Happ";
import { resolver } from "@blockwell/eth-types";
import moment from "moment";
import * as R from 'rambdax';
import {mapGetters, mapState} from "vuex"
import equal from 'fast-deep-equal';
import {saveAs} from 'file-saver';

export default {
    components: {
        CoreTasks,
        CoreUsers,
        CoreBoxes,
        FreelanceCreateTask,
        Help,
        PlanningMode,
        CoreInfo,
        CreateTask,
        TaskDetail,
        Modal,
        CoreColumn, ProgressCircle, ContractLoader, ContractDropdown
    },
    mixins: [HappMixin, LatestTasksMixin],
    props: {
        hash: String
    },
    data() {
        return {
            type: "core",
            commonContracts: [
                {
                    name: "Firechain Tasks",
                    bwns: 'fire'
                },
            ],
            deployerAddress: null,
            tokenAddress: null,
            tokenError: null,
            core: new CoreData(),
            tasks: [],
            interval: null,
            coreLoading: false,
            createTask: false,
            previousState: null,
            filter: null,
            planning: {},
            path: [],
            quickTaskValue: "",
            showCoreUsers: false
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('user', ['api']),
        ...mapGetters('dumbapp', ['tracking']),
        contracts() {
            if (this.core.address && this.core.core.tasks) {
                return [
                    {
                        address: this.core.address,
                        name: this.core.core.name
                    },
                    {
                        address: this.core.core.tasks,
                        name: this.core.core.name + " Tasks"
                    }
                ]
            }
        },
        showComment() {
            if (this.path[0] === 'task') {
                let id = parseInt(this.path[2]);
                if (!isNaN(id)) {
                    return id;
                }
            }
            return undefined;
        },
        showApprove() {
            return this.path[0] === 'task'
                && this.path[3] === 'approve';
        },
        openTask() {
            if (this.path[0] === 'task') {
                let id = parseInt(this.path[1]);

                if (!isNaN(id)) {
                    return this.tasks.find(it => it.id === id);
                }
            }
            return null;
        },
        planningMode() {
            return this.path[0] === 'plan';
        },
        noAccess() {
            return this.core.freelance() && !this.core.core.balance?.gt(0);
        }
    },
    asyncComputed: {
        async encryptionKey() {
            if (this.account && this.happ) {
                return this.api.getEncryptionKey(this.happ.contractId);
            }
            return null;
        }
    },
    watch: {
        'core.state': {
            deep: true,
            handler(val) {
                if (this.happ && !equal(this.previousState, val) && !equal(val, {
                    planning: {
                        columns: [],
                        tasks: []
                    }
                })) {
                    this.api.saveContractState(this.happ.contractId, val);
                }
                this.previousState = Object.freeze(R.clone(val));
            }
        },
        encryptionKey(val) {
            this.$set(this.core, 'encryptionKey', val);
        },
        happ: {
            immediate: true,
            handler(val) {
                this.clear();
                this.core = new CoreData();
                this.previousState = null;
                if (val) {
                    this.coreLoading = true;
                    loadCore(val)
                        .then(async data => {
                            this.core.type = data.type;
                            this.core.core = data.core;
                            this.core.ext = data.ext;
                            this.core.account = this.account;
                            this.core.address = data.address;
                            this.core.network = data.network;
                            this.core.contractId = data.contractId;

                            this.interval?.clear();
                            this.interval = every(() => {
                                this.updateData().catch(console.error);
                            }, 15000);

                            if (this.loggedIn) {
                                await this.api.addToContractHistory(
                                    this.$route.fullPath,
                                    "Core happ",
                                    val.address,
                                    val.network.networkId
                                );
                            }
                        })
                        .catch(console.error)
                        .finally(() => this.coreLoading = false);

                    this.api.getCoreDumbapps(val.contractId)
                        .then(res => {
                            this.core.codes = res;
                        })
                        .catch(console.error);

                    if (this.account) {
                        this.api.getContractState(val.contractId)
                            .then(res => {
                                // Set up these so planning data will be reactive
                                if (!res.planning) {
                                    res.planning = {
                                        columns: [],
                                        tasks: []
                                    };
                                } else {
                                    res.planning.tasks = res.planning.tasks.map(taskObject => {
                                        return Object.assign(new PlanningTaskData(), taskObject);
                                    });
                                }
                                if (!res.dismiss) {
                                    res.dismiss = {};
                                }
                                this.previousState = Object.freeze(R.clone(res));
                                this.$set(this.core, 'state', res);
                                this.core.latestTask = res.latestTask;
                            })
                            .catch(console.error);
                    }
                } else {
                    this.core = new CoreData();
                }
            }
        },
        hash: {
            immediate: true,
            handler(val) {
                this.handleHash(val);
            }
        },
        account: {
            immediate: true,
            handler(val) {
                this.core.account = val;

                if (this.happ && this.core.address) {
                    this.updateData();
                }
            }
        }
    },
    methods: {
        async beforeLoadHapp(addr, net, id) {
            let address = addr;
            let network = net;
            let contractId = id;
            if (!network || !contractId) {
                try {
                    let result = await this.api.getContractId(address);
                    network = result.network;
                    contractId = result.id;
                } catch (err) {
                    // No network, can't proceed
                    if (!network) {
                        throw err;
                    }
                    console.error('Failed to get contract ID', err);
                }
            }

            let bwtype = await Contract.getBwType(network, address);

            if (!bwtype) {
                throw new Error("Can't load the contract because it is not a Core contract.");
            }
            if (bwtype.name === 'CoreTasksExtension' || bwtype.name === 'CoreFreelanceExtension') {
                address = await loadCoreForExtension(network, address);
                contractId = null;
            } else if (bwtype.name !== 'CoreToken') {
                throw new Error("Can't load the contract because it is not a Core contract.");
            }

            if (address.toLowerCase() === '0x89a24b828786b65835d29684c062fe5123abab97') {
                await this.$router.push('/core-v1?contract=0x89a24b828786b65835d29684c062fe5123abab97');
            }

            return {
                address, network, contractId
            }
        },
        async updateData() {
            let {core, tasks, ext} = await loadDynamic(this.happ, this.core, this.account);
            let newCore = Object.assign(new CoreContractData(), this.core.core, core);
            let newExt;
            if (this.core.freelance()) {
                newExt = new FreelanceData();
            } else {
                newExt = new TasksExtensionData();
            }
            newExt = Object.assign(newExt, this.core.ext, ext);

            if (!equal(newCore, this.core.core)) {
                this.core.core = newCore;
            }
            if (!equal(newExt, this.core.ext)) {
                this.core.ext = newExt;
            }

            this.tasks = tasks;

            let periodEnds = moment.unix(core.periodStart + this.core.core.periodLength);
            this.core.periodEnds = periodEnds;
            this.core.endingSoon = periodEnds.clone().subtract(48, 'hours').isBefore();

            this.core.endsInHours = periodEnds.diff(moment(), 'hours');
        },
        reset() {
            this.$router.push('/core');
        },
        shareLink(dumbapp) {
            return `/${dumbapp.shortcode}/sharing`;
        },
        tokenLoaded(data) {
            this.tokenAddress = data;
        },
        handleHash(val) {
            let path = val.slice(1).split('/');

            if (path[0].includes('-')) {
                let parts = path[0].split('-');
                path = parts.concat(path.slice(1));
            }

            this.path = path;
        },
        /**
         * @param {TaskData} task
         */
        taskClick(task) {
            this.$router.push({
                query: this.$route.query,
                hash: `#task/${task.id}`
            });
            if (task.id > this.core.latestTask && !this.core.viewedTasks.includes(task.id)) {
                this.core.viewedTasks.push(task.id);
            }
            this.$refs.boxes.clicked();
        },
        taskClosed() {
            if (this.$route.hash) {
                this.$router.push({
                    query: this.$route.query,
                    hash: ''
                });
            }
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        },
        /**
         *
         * @param {DumbappSubmission} submission
         */
        dumbappCompleted(submission) {
            let i = 0;
            let reload = false;
            for (let step of submission.data.steps) {
                let dumbappStep = submission.dumbapp.steps[i];

                if (getNetwork(dumbappStep.network).networkId === this.happ.network.networkId
                    && dumbappStep.address === this.core.core.tasks) {
                    reload = true;
                    for (let event of step.events) {
                        if (event && event.event === "TaskCreated") {
                            let state = this.core.state;
                            let taskId = parseInt(resolver(event.params).string("taskId"));

                            if (!state.comments) {
                                this.$set(state, 'comments', {});
                            }
                            if (!state.comments[taskId]) {
                                this.$set(state.comments, taskId, {});
                            }

                            this.$set(state.comments[taskId], 'watch', true);
                        }
                    }
                }
                ++i;
            }
            if (reload) {
                this.interval?.runNow();
            }
        },
        enterPlanningMode() {
            this.$router.push({
                hash: '#plan',
                query: this.$route.query
            })
        },
        exitPlanningMode() {
            this.$router.push({
                hash: '',
                query: this.$route.query
            })
        },
        exportCsv() {
            let csv = exportTasks(this.core, this.tasks.filter(task => {
                switch (this.filter) {
                    case 'collaborator':
                        return task.isCollaborator;
                    case 'watching':
                        return this.core.state?.comments?.[task.id]?.watch;
                    case 'creator':
                        return task.creator.toLowerCase() === this.account.toLowerCase();
                    case 'commented':
                        return task.hasCommented;
                    default:
                        return true;
                }
            }));

            let blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
            let date = new Date();
            saveAs(blob, `core-export-${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.csv`);
        },
        /**
         * @param {KeyboardEvent} event
         */
        quickTaskKeydown(event) {
            if (event.key === 'Enter' && event.target.value) {
                let titles = event.target.value.split(",");
                titles.reverse();

                let id = Date.now();
                for (let title of titles) {
                    let trimmed = title.trim();
                    if (trimmed) {
                        this.core.state.planning.tasks.push(new PlanningTaskData(id++, {
                            title: trimmed
                        }));
                    }
                }

                this.quickTaskValue = "";
                event.target.value = "";

                if (!this.planningMode) {
                    this.enterPlanningMode();
                }
            }
        }
    },
    mounted() {
        this.$bus.on('dumbapp-completed', this.dumbappCompleted);
    },
    beforeDestroy() {
        this.clear();
        this.$bus.off('dumbapp-completed', this.dumbappCompleted);
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.core-contract-loader {
    max-width: 940px;
    padding: 0 15px;
    margin: 0 auto;
}

.exit-planning-mode {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
}

.quick-add {
    padding: 0 15px;
}

.no-access {
    text-align: center;
    margin-top: 40px;
}

</style>
