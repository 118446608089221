<template>
    <div>
        <div class="core-info" :class="{'with-earning': earning}">
            <div class="core-info-left">
                <div class="core-info-period">
                    <h5 class="period">Period <span>#{{ core.core.currentPeriod }}</span></h5>
                    <div v-if="ending">
                        Period is ending
                        <DumbappPopover
                            label="Next Period"
                            :instance="core.codes.nextPeriod"/>
                    </div>
                    <div v-else>
                        <v-popover>
                            Ends in {{ endsIn }} <a href="javascript: void 0;" class="material-icons">info</a>
                            <template #popover>
                                <div class="period-dates">
                                    {{ periodFrom }} &ndash; {{ periodTo }}
                                </div>
                            </template>
                        </v-popover>
                    </div>
                </div>
            </div>
            <div class="core-info-balance" :class="{'sign-in': !account}">
                <template v-if="account">
                    <div class="your-balance">Your Balance</div>
                    <a href="javascript: void 0;" class="user-balance-link" @click="showCoreDetails = true">
                        <TokenAmount
                            class="user-balance"
                            :value="core.core.balance"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"
                        />
                    </a>
                    <div>
                        <Help dismiss-key="core-CoreDetails" :show="core.core.balance && core.core.balance.gt(0)">
                            Your balance of tokens gives you access to this board. Click your balance to find out more.
                        </Help>
                    </div>
                    <div v-if="hasPenalty" class="core-penalty">
                        Penalty
                        <TokenAmount
                            :value="penalty"
                            :decimals="core.core.decimals"
                        />
                        <Info>
                            You've received a penalty because a task you were involved in was contested to be invalid.
                            When you earn rewards, it will be taken out of your penalty first.
                        </Info>
                    </div>
                    <div v-if="manager" class="text-center">
                        <button class="btn btn-sm btn-link" @click="$emit('users')">
                            Manage Users
                        </button>
                    </div>
                </template>
                <button v-else class="btn btn-primary" @click="login">Sign In</button>
            </div>
            <div v-if="earning" class="core-info-earnings">
                <div class="earnings">You Could Earn</div>
                <span class="dollar-symbol">$</span>
                <TokenAmount
                    class="user-balance"
                    :decimals="0"
                    :value="earning"
                    :places="0"
                />
                <div class="holding">
                    <span class="percentage">{{ percentage.times(100).toFormat(1) }}%</span>
                    <br>
                    of Core Pool
                    <Info>
                        <p>
                            In {{ earningIn }} you could earn ${{ earning.toFormat(0) }} from the Core Pool if the target funding of
                            ${{ target.toFormat() }} is reached. The Core Pool is {{ poolPercentage.times(100).toFormat(0) }}% of
                            the total raised.
                        </p>
                        <div>
                            Your earning is based on the percentage of {{ core.core.symbol }} you're holding, so creating,
                            completing and approving tasks will increase your earnings.
                        </div>
                    </Info>
                </div>
            </div>
            <div class="core-info-right">
                <div class="core-info-burn">
                    <h5 class="burn">Rewards</h5>
                    <div class="period-rewards">
                        <TokenAmount
                            :value="core.core.rewards"
                            :decimals="core.core.decimals"
                            :symbol="core.core.symbol"
                        />
                    </div>
                    <div v-if="burnAmount" class="burn-amount" :class="{'will-burn': moreNeeded}">
                        <template v-if="moreNeeded">
                            <TokenAmount
                                :value="burnAmount"
                                :decimals="core.core.decimals"
                                :symbol="core.core.symbol"/>
                            will burn in {{ endsIn }}
                        </template>
                        <template v-else-if="moreNeeded === false">
                            No tokens will burn.
                        </template>
                        <template v-else>
                            Nothing to burn.
                        </template>
                        <Info>
                            Anyone who does not receive at least
                            <TokenAmount
                                :value="core.core.periodMinimum"
                                :decimals="core.core.decimals"
                                :symbol="core.core.symbol"/>
                            in rewards in a period will lose
                            <template v-if="core.core.burnAmount.gt(0)">
                                <TokenAmount
                                    :value="core.core.burnAmount"
                                    :decimals="core.core.decimals"
                                    :symbol="core.core.symbol"
                                />
                                .
                            </template>
                            <template v-else>
                                <TokenAmount
                                    :value="core.core.burnPercentage"
                                    :decimals="16"/>
                                %
                                of their tokens.
                            </template>
                        </Info>
                    </div>
                </div>
            </div>
        </div>

        <Modal :show.sync="showCoreDetails">
            <CoreDetails :core="core"/>
        </Modal>
    </div>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import Help from "@/components/Help";
import Info from "@/components/Info";
import Modal from "@/components/Modal";
import TokenAmount from "@/components/TokenAmount";
import * as interval from "@/lib/interval";
import CoreDetails from "@/views/happs/core/CoreDetails";
import CoreUsers from "@/views/happs/core/CoreUsers";
import {CoreData} from "@/views/happs/core/lib/CoreData";
import {loadFundraising} from "@/views/happs/core/lib/corelib";
import BigNumber from "bignumber.js";
import moment from "moment";
import {mapState} from "vuex";

export default {
    name: "CoreInfo",
    components: {CoreUsers, CoreDetails, Help, Modal, Info, TokenAmount, DumbappPopover},
    props: {
        core: CoreData
    },
    data() {
        return {
            interval: null,
            ending: false,
            endsIn: "",
            earningIn: "",
            showCoreDetails: false
        };
    },
    computed: {
        ...mapState('user', ['account']),
        periodFrom() {
            let start = this.core.core?.periodStart;
            if (start) {
                return moment.unix(start).format('ll');
            }
            return "";
        },
        periodTo() {
            let start = this.core.core?.periodStart;
            let length = this.core.core?.periodLength;
            if (start && length) {
                return moment.unix(start + length).format('ll');
            }
            return "";
        },
        penalty() {
            return this.core?.core?.penalty;
        },
        hasPenalty() {
            return this.penalty && this.penalty.gt(0);
        },
        moreNeeded() {
            let data = this.core.core;
            if (data.balance?.gt(0)) {
                if (data.rewards.gte(data.periodMinimum)) {
                    return false;
                } else {
                    return data.periodMinimum.minus(data.rewards);
                }
            }

            return null;
        },
        burnAmount() {
            if (this.core.core.burnAmount.gt(0)) {
                return this.core.core.burnAmount;
            }
            return this.core.core.burnPercentage.times(this.core.core.balance).div('1e18');
        },
        target() {
            return new BigNumber(this.fundraising?.target)
        },
        poolPercentage() {
            return new BigNumber(this.fundraising?.pool);
        },
        corePool() {
            if (this.fundraising) {
                return new BigNumber(this.fundraising.target).times(this.fundraising.pool);
            }
            return null;
        },
        percentage() {
            if (this.core.core.balance) {
                return new BigNumber(this.core.core.balance).div(this.core.core.totalSupply);
            }
            return null;
        },
        earning() {
            if (!this.core.freelance() && this.percentage && this.corePool) {
                let earning = this.corePool.times(this.percentage);
                if (earning.gt(0)) {
                    return earning;
                }
            }
            return null;
        },
        manager() {
            return this.core.freelance() && (this.core.core.admin || this.core.core.manager);
        }
    },
    asyncComputed: {
        async fundraising() {
            return loadFundraising(this.core);
        }
    },
    watch: {
        'core.core.periodStart': {
            immediate: true,
            handler() {
                this.updatePeriod();
            }
        }
    },
    methods: {
        login() {
            this.$bus.emit('login_request', {
                message: ""
            });
        },
        updatePeriod() {
            let start = this.core.core?.periodStart;
            let length = this.core.core?.periodLength;
            if (start && length) {
                let timestamp = moment.unix(start + length);
                if (timestamp.isAfter(moment().subtract(1, 'minute'))) {
                    this.endsIn = timestamp.fromNow(true);
                } else {
                    this.ending = true;
                }

                if (this.core.core.currentPeriod % 2 === 1) {
                    // Earnings are at the end of every other periods
                    timestamp.add(this.core.core.periodLength, 'seconds');
                }

                this.earningIn = timestamp.fromNow(true);
            } else {
                this.endsIn = "";
            }
        }
    },
    created() {
        this.interval = interval.every(() => {
            this.updatePeriod();
        }, 30000);

        this.updatePeriod();
    },
    destroyed() {
        if (this.interval !== null) {
            this.interval.clear();
            this.interval = null;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.core-info {
    display: grid;
    grid-template-columns: 1fr 12em 1fr;
    align-items: start;

    &.with-earning {
        grid-template-columns: 1fr 12em 12em 1fr;

        @media screen and (max-width: 700px) {
            grid-template-columns: 1fr 12em 1fr;
            grid-template-areas:
            "left top right"
            "left bottom right";

            .core-info-earnings {
                grid-area: bottom;
            }

            .core-info-left {
                grid-area: left;
            }
            .core-info-right {
                grid-area: right;
            }
        }
    }

    .core-info-left, .core-info-right {
        display: flex;
    }

    .core-info-left {
        justify-self: end;
        padding: 0 25px 0 5px;
    }

    .core-info-right {
        padding: 0 5px 0 25px;
    }

    @media screen and (max-width: 500px) {
        font-size: 14px;

        .core-info-left {
            padding: 0 5px 0 5px;
        }

        .core-info-right {
            padding: 0 5px 0 5px;
        }
    }
    @media screen and (max-width: 400px) {
        font-size: 12px;
    }
}

.your-balance {
    margin-bottom: 2px;
}


.core-info-earnings {
    width: 12em;
    text-align: center;
    padding-top: 0.6em;
    min-height: 7em;
    color: $text-muted;
    border: 2px solid rgba($purple, .5);
    border-left-width: 1px;
    border-top: none;
    border-bottom-right-radius: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

    .dollar-symbol {
        font-size: 1.7em;
        color: rgba($dark, .8);
    }

    ::v-deep() {
        .token-amount {
            font-size: 1.7em;
            color: $dark;
        }
    }

    .holding {
        font-size: 14px;
    }

    .percentage {
        color: rgba($dark, .8);
    }
}

.core-info-balance {
    width: 12em;
    text-align: center;
    padding-top: 0.6em;
    min-height: 7em;
    color: $text-muted;
    border: 2px solid rgba($primary, .5);
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

    ::v-deep() {
        .token-amount {
            font-size: 1.7em;
            color: $primary-muted;
        }

        .token-symbol {
            font-size: 0.5em;
            color: $dark;
        }
    }

    &.sign-in {
        border-color: transparent;
        box-shadow: none;
    }
}

.with-earning {
    .core-info-balance {
        border-right-width: 1px;
        border-bottom-right-radius: 0;
        @media screen and (max-width: 700px) {
            border-bottom-left-radius: 0;
            border-right-width: 2px;
        }
    }

    .core-info-earnings {
        @media screen and (max-width: 700px) {
            border-left-width: 2px;
            border-bottom-left-radius: 15px;
        }
    }
}

.core-penalty {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;

    ::v-deep() {
        .token-amount {
            font-size: 14px;
            color: rgba($danger, .7);
        }
    }
}

.core-info-period {
    ::v-deep( .trigger ) {
        cursor: pointer;

        &:hover .material-icons {
            color: $primary;
        }
    }
}

.period, .burn {
    font-size: 1em;
    font-weight: normal;
    margin: 16px 0 8px;
    color: $text-muted;
    text-align: center;
}

.period-dates {
    padding: 15px;
}

.period-rewards {
    font-size: 1.15em;
}

.will-burn {
    color: rgba($danger, .7);
}

.user-balance-link {
    text-decoration: none;
}

</style>
