<template>
    <div class="core-effort" :class="{expanded}">
        <div class="effort-header" @click="toggleExpanded">
            <div class="effort-id">
                #<span>{{ effort.id }}</span>
            </div>
            <div class="effort-primary">
                <Address :address="effort.collaborators[0]" :network="network"/>
            </div>
            <div class="collaborator-count">
                <template v-if="effort.collaborators.length > 1">
                    <i class="material-icons">person</i>
                    {{ effort.collaborators.length }}
                </template>
            </div>
            <div v-if="effort.contests.gt(0)" class="contested">
                contested
            </div>
            <div class="effort-status" :class="statusClass">
                {{ statusText }}
            </div>
            <div class="expand" :class="{expanded}">
                <i class="material-icons">expand_more</i>
            </div>
        </div>
        <slide>
            <div v-if="expanded" class="effort-detail">
                <EffortParticipants
                    :effort="effort"
                    :network="network"
                />
                <div class="effort-notice">
                    <div v-if="failDate">
                        This effort will fail if not completed and approved by {{ failDate }}.
                    </div>
                </div>
            </div>
        </slide>

        <EffortActions
            :core="core"
            :task="task"
            :effort="effort"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import Address from "@/components/Address";
import AddressBlockie from "@/components/AddressBlockie";
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import ProgressCircle from "@/components/ProgressCircle";
import Slide from "@/components/Slide";
import TokenAmount from "@/components/TokenAmount";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import Comment from "@/views/happs/core/Comment";
import EffortActions from "@/views/happs/core/EffortActions";
import EffortParticipants from "@/views/happs/core/EffortParticipants";
import {CoreData, EffortData, TaskData} from "@/views/happs/core/lib/CoreData";
import {effortStatuses, taskStatuses} from "@/views/happs/core/lib/corelib";
import WatchingMixin from "@/views/happs/core/WatchingMixin";
import moment from "moment";
import * as R from 'rambdax';
import {mapActions, mapGetters} from "vuex";

const crypt = new AesCrypt();

export default {
    name: "Effort",
    components: {
        Slide,
        ProgressCircle,
        EffortParticipants,
        Address,
        Comment,
        EffortActions, AddressBlockie, TokenAmount, DumbappPopover, MarkdownEditor, Markdown, Clipboard
    },
    mixins: [WatchingMixin],
    props: {
        task: TaskData,
        effort: EffortData,
        core: CoreData,
        icon: String,
        network: [Number, String]
    },
    data() {
        return {
            commentText: "",
            expanded: false
        }
    },
    computed: {
        ...mapGetters('dumbapp', ['tracking']),
        statusText() {
            return effortStatuses[this.effort?.status];
        },
        statusClass() {
            let name;
            if (this.effort.status) {
                name = this.effort.status;
            } else {
                name = "unknown";
            }
            return `status-${name}`;
        },
        failDate() {
            let core = this.core;
            if (this.effort.status === 'open' || this.effort.status === 'completed') {
                let timestamp = core.core.periodStart + core.core.periodLength * (core.ext.taskPeriodLimit + 1 + this.effort.creationPeriod - core.core.currentPeriod);
                return moment.unix(timestamp).format('lll');
            }
            return null;
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        toggleExpanded() {
            this.expanded = !this.expanded;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.core-effort {
    border: 1px solid #ddd;

    @media screen and (max-width: 720px) {
        margin-left: -30px;
        font-size: 0.9em;
    }

    ::v-deep( .dumbapp-popover-trigger ) {
        margin: 0;
    }
}

.effort-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2px 15px 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    justify-content: flex-end;

    ::v-deep( .wallet-address ) {
        background-color: #fff;
        border-color: #fff;
    }

    & > ::v-deep(div ){
        margin-top: 8px;
    }
}

.expand {
    font-size: 1.5em;
    margin-left: 5px;
    transition: transform .4s;

    &.expanded {
        transform: rotate(180deg);
    }

    .material-icons {
        color: $dark;
    }
}

.effort-primary {
    margin: 0 10px 0 5px;
}

.collaborator-count {
    font-size: 1.1em;
    flex: 1 0 auto;
}

.contested {
    flex: 1 0 auto;
}

.effort-detail {
    padding: 10px 15px;
}

.effort-id {
    font-size: 1.3em;

    ::v-deep( span ) {
        font-weight: 600;
    }
}

.effort-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.effort-notice {
    margin: 10px 0 0;
}

.effort-status {
    margin-left: 5px;
    display: inline-block;
    background: #616161;
    color: #fff;
    padding: 4px 12px 5px;
    border-radius: 20px;
}

.status-open {
    background: #13315C;
}

.status-completed {
    background: #efae00;
}

.status-approved {
    background: $success;
}

.status-invalid {
    background: $danger;
}

.approvals {
    ::v-deep( .label ) {
        display: inline-block;
        width: 120px;
    }
}

.action-notice {
    color: $secondary;
}

.contested {
    font-size: 18px;
    color: $danger;
}

</style>
