import Vue from 'vue';

export default {
    computed: {
        isWatching() {
            return this.core.state?.comments?.[this.task.id]?.watch;
        },
    },
    methods: {
        toggleWatch() {
            let state = this.core.state;

            if (this.isWatching) {
                this.$set(state.comments[this.task.id], 'watch', false);
            } else {
                if (!state.comments) {
                    this.$set(state, 'comments', {});
                }
                if (!state.comments[this.task.id]) {
                    this.$set(state.comments, this.task.id, {});
                }
                this.$set(state.comments[this.task.id], 'watch', true);
            }
        }
    }
}
