var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"core-task-detail create-task"},[_c('div',{staticClass:"task-section"},[_c('i',{staticClass:"material-icons section-icon"},[_vm._v("note")]),_c('FormulateForm',{on:{"validation":function($event){_vm.valid = $event}}},[_c('div',{staticClass:"task-title"},[_c('FormulateInput',{attrs:{"label":"Title","type":"text","name":"title","validation":"required"},on:{"blur":_vm.blur},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('label',[_vm._v("Payment Token")]),(_vm.tokenData)?[_c('div',[_c('a',{attrs:{"href":"javascript: void 0;"},on:{"click":function($event){_vm.token = null}}},[_vm._v("change token")])]),(_vm.tokenData)?_c('Asset',{staticClass:"token-asset",attrs:{"asset":_vm.tokenData}}):_vm._e()]:[_c('p',[_vm._v("Select a token to pay the task reward with.")]),_c('ContractLoader',{attrs:{"load-click":_vm.tokenLoaded,"features":"erc20","network":_vm.core.network,"hide-recents":"","no-label":""}})],_c('div',{staticClass:"task-reward"},[_c('FormulateInput',{attrs:{"label":"Reward","step":"any","type":"number","error-behavior":"blur","help":`Amount of tokens to reward for completing this task. Max reward: ${_vm.maxReward || '0'}`,"name":"reward","validation":[
                        ['required'],
                        ['not', '0'],
                        ['min', 0],
                        ['max', _vm.maxReward]
                    ]},on:{"blur":_vm.blur},model:{value:(_vm.reward),callback:function ($$v) {_vm.reward=$$v},expression:"reward"}})],1)],2)],1),_c('div',{staticClass:"task-section"},[_c('i',{staticClass:"material-icons section-icon"},[_vm._v("notes")]),_c('h6',[_vm._v("Description")]),_c('MarkdownEditor',{attrs:{"content":_vm.description,"min-height":"100","toolbar":['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                        'link', 'image', 'preview', 'guide']},on:{"update:content":function($event){_vm.description=$event},"blur":_vm.blur}})],1),_c('div',{ref:"buttons",staticClass:"task-section"},[(_vm.notEnoughTokens)?_c('div',{staticClass:"error"},[_vm._v(" You must have at least "),_c('TokenAmount',{attrs:{"value":_vm.core.ext.minimumCreateBalance,"decimals":_vm.core.core.decimals,"symbol":_vm.core.core.symbol}}),_vm._v(" to create a task, you have "),_c('TokenAmount',{attrs:{"value":_vm.core.core.balance,"decimals":_vm.core.core.decimals,"symbol":_vm.core.core.symbol}}),_vm._v(" . ")],1):_vm._e(),_c('div',{staticClass:"create-buttons"},[_c('DumbappPopover',{attrs:{"label":"Create Task","instance":_vm.dumbapp,"disabled":_vm.disable,"values":_vm.query},on:{"submitted":_vm.taskSubmitted}}),(_vm.task)?_c('v-popover',{attrs:{"container":_vm.$refs.buttons},on:{"show":_vm.showDelete},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('div',{staticClass:"popover-body"},[_c('p',[_vm._v("The draft will be deleted permanently.")]),_c('button',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.$emit('delete', _vm.task.id)}}},[_vm._v("Delete ")])])]},proxy:true}],null,false,2255257915)},[_c('button',{staticClass:"btn btn-sm btn-link"},[_vm._v("Delete Draft")])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }