<template>
    <div class="core-task-detail create-task">
        <div class="task-section">
            <i class="material-icons section-icon">note</i>
            <FormulateForm
                @validation="valid = $event"
            >
                <div class="task-title">
                    <FormulateInput
                        label="Title"
                        type="text"
                        name="title"
                        validation="required"
                        v-model="title"
                        @blur="blur"
                    />
                </div>
                <div class="task-reward">
                    <FormulateInput
                        label="Reward"
                        step="any"
                        type="number"
                        @blur="blur"
                        :validation="[
                        ['required'],
                        ['min', 1],
                        ['max', maxReward]
                    ]
                    "
                        :validation-messages="{
                        max: `Reward must not be more than ${maxReward}, which is 1/${core.ext.taskRewardFactor}th of your token balance.`
                    }"
                        error-behavior="blur"
                        :help="`Amount of tokens to reward for completing this task. Limit: ${maxReward}`"
                        name="reward"
                        v-model="reward"
                    />
                </div>
            </FormulateForm>
        </div>

        <div class="task-section">
            <i class="material-icons section-icon">notes</i>
            <h6>Description</h6>
            <MarkdownEditor :content.sync="description" min-height="100"
                            @blur="blur"
                            :toolbar="['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                            'link', 'image', 'preview', 'guide']"
            />
        </div>

        <div class="task-section" ref="buttons">
            <div v-if="notEnoughTokens" class="error">
                You must have at least
                <TokenAmount :value="core.ext.minimumCreateBalance" :decimals="core.core.decimals"
                             :symbol="core.core.symbol"/>
                to create a task, you have
                <TokenAmount :value="core.core.balance" :decimals="core.core.decimals" :symbol="core.core.symbol"/>
                .
            </div>
            <div class="create-buttons">
                <DumbappPopover
                    label="Create Task"
                    :instance="core.codes.createTask"
                    :disabled="disable"
                    :values="query"
                    @submitted="taskSubmitted"
                />

                <v-popover v-if="task" :container="$refs.buttons" @show="showDelete">
                    <button class="btn btn-sm btn-link">Delete Draft</button>
                    <template #popover>
                        <div class="popover-body">
                            <p>The draft will be deleted permanently.</p>
                            <button class="btn btn-sm btn-danger" v-close-popover
                                    @click="$emit('delete', task.id)">Delete
                            </button>
                        </div>
                    </template>
                </v-popover>
            </div>
        </div>
    </div>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import Markdown from "@/components/Markdown";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import Effort from "@/views/happs/core/Effort";
import CreateMixin from "@/views/happs/core/freelance/CreateMixin";
import { mapActions, mapGetters, mapState } from "vuex";

const crypt = new AesCrypt();

export default {
    name: "CreateTask",
    components: {MarkdownEditor, Effort, DumbappPopover, ProgressCircle, Markdown, TokenAmount},
    mixins: [CreateMixin],
    computed: {
        ...mapGetters('user', ['api']),
        query() {
            let query = {title: this.title, description: this.description};

            if (this.core.encryptionKey && this.encryptionKey) {
                query.encryption = "aes-2";
                query.title = crypt.encrypt(this.title, {password: this.core.encryptionKey});
                query.timestamp = this.timestamp;
                if (this.description) {
                    query.description = crypt.encrypt(this.description, {password: this.encryptionKey});
                }
            }

            return {
                text: JSON.stringify(query),
                reward: this.reward
            }
        },
        maxReward() {
            if (!this.core.core?.balance || !this.core.ext) {
                return null;
            }

            return Math.floor(this.core.core.balance
                .div(this.core.ext.taskRewardFactor)
                .div(`1e${this.core.core.decimals}`)
                .toNumber());
        }
    },
    asyncComputed: {
        encryptionKey() {
            return this.api.getEncryptionKey(this.core.contractId, {
                version: "2",
                address: this.account,
                timestamp: this.timestamp
            });
        }
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        ...mapActions('drafts', ['saveTaskDraft', 'clearTaskDraft']),
        taskSubmitted(submission) {
            this.$emit('submitted', submission, this.task);

            let reward = submission.data.args[0].args[1];
            let content = JSON.parse(submission.data.args[0].args[0]);

            this.trackSubmission({
                key: ['core-task', this.core.network, this.core.address].join('-'),
                data: {
                    id: submission.id,
                    reward,
                    title: this.title,
                    planningId: this.task?.id,
                    expectedTaskId: this.core.ext.maxTaskId + 1,
                    rawTitle: content.title,
                    timestamp: content.t
                }
            });

            this.clearTaskDraft(this.core.contractId);
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.create-task {
    ::v-deep( .editor-toolbar ) {

        background: #efefef;
        font-size: 0.8em;
        padding: 0;

        &::before, &::after {
            content: none;
        }
    }
}

.markdown-help {
    font-size: 14px;
    color: $secondary;
    margin-top: 3px;
}

.task-reward {
    ::v-deep( .formulate-input-element ) {
        max-width: 300px;
    }
}

.create-buttons {
    display: flex;
    align-items: flex-end;

    .delete-draft {
        margin-left: 5px;
    }
}

</style>
