import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import {FastAesCrypt} from "./FastAesCrypt";

let crypt;

if (window.crypto?.subtle) {
    crypt = new FastAesCrypt();
} else {
    crypt = new AesCrypt();
}

export default {
    data() {
        return {
            crypt
        }
    }
}
