<template>
    <div class="core-tasks-wrapper" ref="wrapper">
        <div class="core-tasks">
            <CoreColumn :tasks="sortedTasks.repeating" title="Repeatable Tasks"
                        class="repeating-column"
                        @task-click="taskClick"
                        :filter="filter"
                        horizontal/>

            <div class="core-columns">
                <CoreColumn :tasks="sortedTasks.open" title="Open Tasks" @create="createTask = true"
                            :core="core"
                            @task-click="taskClick" :filter="filter"/>
                <CoreColumn :tasks="sortedTasks.progress" title="In Progress"
                            @task-click="taskClick" :filter="filter"/>
                <CoreColumn :tasks="sortedTasks.pending" title="Needs Approval"
                            @task-click="taskClick" :filter="filter"/>
                <CoreColumn :tasks="sortedTasks.completed" title="Approved" @task-click="taskClick"
                            :filter="filter"/>
                <CoreColumn :tasks="sortedTasks.invalid" title="Invalid" @task-click="taskClick"
                            :filter="filter"/>
            </div>
        </div>
    </div>
</template>

<script>
import CoreColumn from "@/views/happs/core/CoreColumn";
import {CoreData, TaskData} from "@/views/happs/core/lib/CoreData";
import {taskStatuses} from "@/views/happs/core/lib/corelib";
import {reverse} from "rambdax";
import handyScroll from 'handy-scroll';
import "handy-scroll/dist/handy-scroll.css";

export default {
    name: "CoreTasks",
    components: {CoreColumn},
    props: {
        tasks: Array,
        filter: String,
        /**
         * This should only be passed if the first column should have a Create Task button,
         * and this is the Core the create will be for
         */
        core: CoreData
    },
    data() {
        /**
         *
         * @type {{ [key: string]: TaskData[] }}
         */
        let sortedTasks = {};

        for (let status of Object.keys(taskStatuses)) {
            sortedTasks[status] = [];
        }

        return {
            sortedTasks
        };
    },
    watch: {
        tasks: {
            immediate: true,
            deep: true,
            handler(val) {
                let tasks = {};

                for (let status of Object.keys(taskStatuses)) {
                    tasks[status] = [];
                }

                if (val) {
                    for (let task of reverse(val)) {
                        tasks[task.completeStatus].push(task);
                    }

                    let latest = val[val.length - 1];
                    if (this.account && this.happ && latest && (!this.core.latestTask || latest.id > this.core.latestTask)) {
                        this.$set(this.core.state, 'latestTask', latest.id);
                    }
                }

                this.sortedTasks = tasks;

                this.$nextTick(() => handyScroll.update(this.$refs.wrapper));
            }
        }
    },
    methods: {
        taskClick(task) {
            this.$emit('task-click', task);
        }
    },
    mounted() {
        handyScroll.mount(this.$refs.wrapper);
    },
    beforeDestroy() {
        handyScroll.destroy(this.$refs.wrapper);
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.repeating-column {
    width: 1500px;
}

.core-columns {
    width: 1500px;
    display: inline-flex;
    align-items: stretch;
}

.core-tasks-wrapper {
    max-width: 100%;
    overflow-x: auto;
}

.core-tasks {
    width: 1500px;
}

</style>
