<template>
    <layout-heading size="scroll" no-messages>
        <template #all-heading>
            <div class="col-md-6">
                <h2>
                    <router-link to="/core">
                        <img src="../../../assets/images/icon-QR.svg" height="36" alt="">
                        <span>Core</span>
                    </router-link>
                </h2>
            </div>
            <div class="col-md-5">
                <ContractDropdown v-if="core.core"
                                  @change-contract="reset"
                                  :name="core.core.name"
                                  :contracts="contracts"
                                  :network="network"
                />
            </div>
        </template>
        <div v-if="loading">
            <ProgressCircle/>
        </div>
        <ContractLoader v-else-if="!core.core"
                        name="Core"
                        path="core"
                        :common="commonContracts"
                        class="core-contract-loader"
        />

        <template v-if="!loading && core.core">
            <CoreInfo :core="core"/>

            <CoreNotifications v-if="account" :core="core" ref="notifications"/>

            <div v-if="account" class="core-filters">
                <h3>Filters</h3>
                <div class="btn-group btn-group-toggle btn-group-sm">
                    <button class="btn btn-sm" :class="!filter ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="filter = null">
                        Show All
                    </button>
                    <button class="btn btn-sm"
                            :class="filter === 'collaborator' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="filter = 'collaborator'">
                        My Tasks
                    </button>
                    <button class="btn btn-sm"
                            :class="filter === 'watching' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="filter = 'watching'">
                        Watched Tasks
                    </button>
                    <button class="btn btn-sm"
                            :class="filter === 'creator' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="filter = 'creator'">
                        Tasks I Created
                    </button>
                </div>
            </div>
        </template>

        <div class="core-wrap" ref="inner">
            <div v-if="!loading && core.core" class="core-inner">
                <CoreColumn :tasks="tasks.repeating" :core="core" title="Repeatable Tasks"
                            class="repeating-column"
                            @task-click="taskClick"
                            :filter="filter"
                            horizontal/>
                <div class="core-columns">
                    <CoreColumn :tasks="tasks.open" :core="core" title="Open Tasks" @create="createTask = true"
                                @task-click="taskClick" :filter="filter"/>
                    <CoreColumn :tasks="tasks.progress" :core="core" title="In Progress"
                                @task-click="taskClick" :filter="filter"/>
                    <CoreColumn :tasks="tasks.pending" :core="core" title="Needs Approval"
                                @task-click="taskClick" :filter="filter"/>
                    <CoreColumn :tasks="tasks.completed" :core="core" title="Approved" @task-click="taskClick"
                                :filter="filter"/>
                    <CoreColumn :tasks="tasks.invalid" :core="core" title="Invalid" @task-click="taskClick"
                                :filter="filter"/>
                </div>
            </div>
        </div>

        <Modal :show="openTask !== null" @closed="taskClosed">
            <TaskDetail
                :task="openTask"
                :core="core"
                :happ="happ"/>
        </Modal>

        <Modal :show.sync="createTask" keep-state>
            <CreateTask :core="core" @submitted="createTask = false"/>
        </Modal>
    </layout-heading>
</template>

<script>
import {getNetwork} from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard";
import DumbappPopover from "@/components/DumbappPopover";
import Modal from "@/components/Modal";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import Contract from "@/lib/eth/Contract";
import * as interval from "@/lib/interval";
import * as service from "@/service/service";
import ContractDropdown from "@/views/happs/components/ContractDropdown";
import ContractLoader from "@/views/happs/components/ContractLoader";
import { resolver } from "@blockwell/eth-types";
import CoreColumn from "./CoreColumn";
import CoreInfo from "./CoreInfo";
import CoreNotifications from "./CoreNotifications";
import CreateTask from "./CreateTask";
import {CoreData} from "./lib/CoreData";
import {
    loadCore,
    loadCoreForExtension,
    loadDynamic,
    loadFundraising,
    taskStatuses
} from "./lib/corelib";
import TaskDetail from "./TaskDetail";
import HappMixin from "@/views/happs/Happ";
import moment from "moment";
import * as R from 'rambdax';
import {mapGetters, mapState} from "vuex"
import handyScroll from 'handy-scroll';
import "handy-scroll/dist/handy-scroll.css";
import equal from 'fast-deep-equal';

export default {
    components: {
        CoreNotifications,
        CoreInfo,
        CreateTask,
        TaskDetail,
        Modal,
        CoreColumn, ProgressCircle, DumbappPopover, Clipboard, TokenAmount, ContractLoader, ContractDropdown
    },
    mixins: [HappMixin],
    props: {
        hash: String
    },
    data() {
        let tasks = {};

        for (let status of Object.keys(taskStatuses)) {
            tasks[status] = [];
        }

        return {
            type: "core",
            commonContracts: [
                {
                    name: "Test Core",
                    bwns: 'test'
                }
            ],
            deployerAddress: null,
            tokenAddress: null,
            tokenError: null,
            core: new CoreData(),
            interval: null,
            loading: false,
            tasks,
            openTask: null,
            createTask: false,
            previousState: null,
            filter: null
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('user', ['api']),
        ...mapGetters('dumbapp', ['tracking']),
        contracts() {
            if (this.core.address && this.core.core.tasks) {
                return [
                    {
                        address: this.core.address,
                        name: this.core.core.name
                    },
                    {
                        address: this.core.core.tasks,
                        name: this.core.core.name + " Tasks"
                    }
                ]
            }
        }
    },
    asyncComputed: {
        async encryptionKey() {
            if (this.account && this.happ) {
                return this.api.getEncryptionKey(this.happ.contractId);
            }
            return null;
        }
    },
    watch: {
        'core.state': {
            deep: true,
            handler(val) {
                if (this.happ && !equal(this.previousState, val)) {
                    this.api.saveContractState(this.happ.contractId, val);
                }
                this.previousState = Object.freeze(R.clone(val));
            }
        },
        encryptionKey(val) {
            this.$set(this.core, 'encryptionKey', val);
        },
        'core.tasks': {
            immediate: true,
            deep: true,
            /**
             *
             * @param {TaskData[]} val
             */
            handler(val) {
                let tasks = {};

                for (let status of Object.keys(taskStatuses)) {
                    tasks[status] = [];
                }

                if (val) {
                    for (let task of R.reverse(val)) {
                        tasks[task.completeStatus].push(task);
                    }

                    let latest = val[val.length - 1];
                    if (this.account && this.happ && latest && (!this.core.latestTask || latest.id > this.core.latestTask)) {
                        this.$set(this.core.state, 'latestTask', latest.id);
                    }
                }

                this.tasks = tasks;

                this.handleHash(this.hash);

                this.$nextTick(() => handyScroll.update(this.$refs.inner));
            }
        },
        happ: {
            immediate: true,
            handler(val) {
                this.clear();
                if (val) {
                    this.loading = true;
                    loadCore(val)
                        .then(async data => {
                            this.core.core = data.core;
                            this.core.ext = data.ext;
                            this.core.address = data.address;
                            this.core.network = data.network;
                            this.core.contractId = data.contractId;

                            this.interval = interval.every(() => {
                                this.updateData().catch(console.error);
                            }, 15000);

                            if (this.loggedIn) {
                                await this.api.addToContractHistory(
                                    this.$route.fullPath,
                                    "Old Core happ",
                                    val.address,
                                    val.network.networkId
                                );
                            }
                        })
                        .catch(console.error)
                        .finally(() => this.loading = false);

                    this.api.getCoreDumbapps(val.contractId)
                        .then(res => {
                            this.core.codes = res;
                        })
                        .catch(console.error);

                    if (this.account) {
                        this.api.getContractState(val.contractId)
                            .then(res => {
                                this.$set(this.core, 'state', res);
                                this.core.latestTask = res.latestTask;
                            })
                            .catch(console.error);
                    }
                } else {
                    this.core = new CoreData();
                }
            }
        },
        hash: {
            immediate: true,
            handler(val) {
                this.handleHash(val);
            }
        },
        account: {
            immediate: true,
            handler(val) {
                this.core.account = val;

                if (this.happ && this.core.address) {
                    this.updateData();
                }
            }
        }
    },
    methods: {
        async beforeLoadHapp(addr, net, id) {
            let address = addr;
            let network = net;
            let contractId = id;
            if (!network || !contractId) {
                try {
                    let result = await this.api.getContractId(address);
                    network = result.network;
                    contractId = result.id;
                } catch (err) {
                    // No network, can't proceed
                    if (!network) {
                        throw err;
                    }
                    console.error('Failed to get contract ID', err);
                }
            }

            let bwtype = await Contract.getBwType(network, address);

            if (!bwtype) {
                throw new Error("Can't load the contract because it is not a Core contract.");
            }
            if (bwtype.name === 'CoreTasksExtension') {
                address = await loadCoreForExtension(network, address);
                contractId = null;
            } else if (bwtype.name !== 'CoreToken') {
                throw new Error("Can't load the contract because it is not a Core contract.");
            }

            return {
                address, network, contractId
            }
        },
        async updateData() {
            let {core, tasks} = await loadDynamic(this.happ, this.core, this.account);
            let newCore = Object.assign({}, this.core.core, core);

            if (!equal(newCore, this.core.core)) {
                this.core.core = newCore;
            }

            let i = 0;
            for (let task of tasks) {
                if (i < this.core.tasks.length) {
                    /**
                     * @type {TaskData}
                     */
                    let oldTask = this.core.tasks[i];

                    for (let [key, val] of Object.entries(task)) {
                        if (!equal(oldTask[key], val)) {
                            this.$set(oldTask, key, val);
                        }
                    }
                } else {
                    this.core.tasks.push(task);
                }
                ++i;
            }

            let periodEnds = moment.unix(core.periodStart + this.core.core.periodLength);
            this.core.periodEnds = periodEnds;
            this.core.endingSoon = periodEnds.clone().subtract(48, 'hours').isBefore();

            this.core.endsInHours = periodEnds.diff(moment(), 'hours');
        },
        reset() {
            this.$router.push('/core');
        },
        shareLink(dumbapp) {
            return `/${dumbapp.shortcode}/sharing`;
        },
        tokenLoaded(data) {
            this.tokenAddress = data;
        },
        handleHash(val) {
            if (val && this.core.tasks.length > 0) {
                let parts = val.slice(1).split('-');

                if (parts[0] === 'task') {
                    let id = parseInt(parts[1]);

                    if (isNaN(id)) {
                        this.openTask = null;
                        console.error('Could not parse task number from', val);
                    } else if (id !== this.openTask?.id) {
                        this.openTask = this.core.tasks[id];
                    }
                } else {
                    this.openTask = null;
                }
            } else {
                this.openTask = null;
            }
        },
        /**
         *
         * @param {TaskData} task
         */
        taskClick(task) {
            this.$router.push({
                query: this.$route.query,
                hash: `#task-${task.id}`
            });
            if (task.id > this.core.latestTask && !this.core.viewedTasks.includes(task.id)) {
                this.core.viewedTasks.push(task.id);
            }
            this.$refs.notifications.clicked();
        },
        taskClosed() {
            this.$router.push({
                query: this.$route.query,
                hash: ''
            });
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        },
        /**
         *
         * @param {DumbappSubmission} submission
         */
        dumbappCompleted(submission) {
            let i = 0;
            for (let step of submission.data.steps) {
                let dumbappStep = submission.dumbapp.steps[i];

                if (getNetwork(dumbappStep.network).networkId === this.happ.network.networkId
                    && dumbappStep.address === this.core.core.tasks) {
                    for (let event of step.events) {
                        if (event.event === "TaskCreated") {
                            let state = this.core.state;
                            let taskId = parseInt(resolver(event.params).string("taskId"));

                            if (!state.comments) {
                                this.$set(state, 'comments', {});
                            }
                            if (!state.comments[taskId]) {
                                this.$set(state.comments, taskId, {});
                            }

                            this.$set(state.comments[taskId], 'watch', true);
                        }
                    }
                }
                ++i;
            }
        }
    },
    mounted() {
        handyScroll.mount(this.$refs.inner);
        this.$bus.on('dumbapp-completed', this.dumbappCompleted);
    },
    beforeDestroy() {
        handyScroll.destroy(this.$refs.inner);
        this.clear();
        this.$bus.off('dumbapp-completed', this.dumbappCompleted);
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.core-wrap {
    max-width: 100%;
    overflow-x: auto;
}

.core-inner {
    width: 1500px;
}

.repeating-column {
    width: 1500px;
}

.core-columns {
    width: 1500px;
    display: inline-flex;
    align-items: stretch;
}

.core-contract-loader {
    max-width: 940px;
    padding: 0 15px;
    margin: 0 auto;
}

.core-filters {
    text-align: center;
    margin-top: 20px;
    padding: 0 10px;
    font-size: 0.8em;

    ::v-deep( h3 ) {
        font-size: 16px;
    }

    button.btn {
        font-size: 0.8rem;
        padding: .15rem .25rem;
        min-width: 100px;
        margin-bottom: 5px;
        flex: 0 1 auto;
    }

    .btn-group {
        flex-wrap: wrap;
        justify-content: center;
    }
}

</style>
