<template>
    <div v-else class="core-boxes">
        <div v-if="account" class="core-box planning-mode">
            <h3>Planning Mode ({{ core.state.planning.tasks.length }})</h3>
            <p>
                <small>Draft new tasks and submit later.</small>
            </p>
            <button class="btn btn-sm btn-outline-primary" @click="enterPlanningMode">Enter Planning Mode
            </button>

            <Help dismiss-key="core-PlanningMode" :show="true" :reference="$refs.enterPlanning">
                <p>
                    Use planning mode to quickly create drafts of tasks that you can then save later.
                </p>
            </Help>
        </div>

        <div class="core-box notifications">
            <CoreNotifications v-if="account" :core="core" ref="notifications"/>
        </div>

        <div class="core-box filters">
            <div v-if="account" class="core-filters">
                <h3>Filters</h3>
                <div class="btn-group btn-group-toggle btn-group-sm">
                    <button class="btn btn-sm" :class="!filterValue ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="setFilter(null)">
                        Show All
                    </button>
                    <button class="btn btn-sm"
                            :class="filterValue === 'collaborator' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="setFilter('collaborator')">
                        My Tasks
                    </button>
                    <button class="btn btn-sm"
                            :class="filterValue === 'watching' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="setFilter('watching')">
                        Watched Tasks
                    </button>
                    <button class="btn btn-sm"
                            :class="filterValue === 'creator' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="setFilter('creator')">
                        Tasks I Created
                    </button>
                    <button class="btn btn-sm"
                            :class="filterValue === 'commented' ? 'btn-primary active' : 'btn-outline-primary'"
                            @click="setFilter('commented')">
                        Commented Tasks
                    </button>
                </div>
            </div>

            <a href="javascript: void 0;" @click="exportCsv" class="export-csv">
                <i class="material-icons">get_app</i>
                Export Filtered Tasks
            </a>
        </div>
    </div>
</template>
<script>
import CoreNotifications from "@/views/happs/core/CoreNotifications"
import Help from "@/components/Help"

export default {
    name: 'CoreBoxes',
    components: {CoreNotifications, Help},
    props: {
        account: {},
        core: {},
        enterPlanningMode: {},
        exportCsv: {},
        filter: String
    },
    data() {
        return {
            filterValue: this.filter
        }
    },
    watch: {
        filter(val) {
            this.filterValue = val;
        }
    },
    methods: {
        setFilter(val) {
            this.filterValue = val;
            this.$emit('update:filter', val);
        },
        clicked() {
            this.$refs.notifications.clicked();
        }
    }
}
</script>
<style scoped lang="scss">
@import "~@/assets/css/custom.scss";
.core-filters {
    font-size: 0.8em;
    max-width: 350px;

    button.btn {
        font-size: 0.8rem;
        padding: .15rem .25rem;
        min-width: 100px;
        margin-bottom: 5px;
        flex: 0 1 auto;
    }

    .btn-group {
        flex-wrap: wrap;
        justify-content: center;
    }

    @media screen and (max-width: 691px) {
        max-width: none;
    }
}

.core-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px 8px;

    @media screen and (max-width: 691px) {
        flex-direction: column;
    }
}

.core-box {
    border-right: 1px solid rgba($primary, .2);
    margin-right: 10px;
    padding: 20px 20px 10px 10px;

    ::v-deep( h3 ) {
        font-size: 16px;
        margin: 0 0 10px;
    }

    &:last-child {
        border-right: none;
        margin-right: 0;
        padding-right: 10px;
    }

    &.notifications {
        @media screen and (max-width: 1076px) {
            border-right: none;
        }
    }

    &.planning-mode {
        @media screen and (max-width: 691px) {
            border-right: none;
        }
    }
}

.planning-mode {
    max-width: 210px;
    @media screen and (max-width: 691px) {
        max-width: none;
    }
}
.export-csv {
    font-size: 0.9em;

    .material-icons {
        color: $primary;
    }
}


</style>
